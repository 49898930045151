<template>
  <div>
    <section
      v-if="produkMaster.length > 0"
      class="mt-3 sec-relate-product bg6 mb-4"
    >
      <div class="container p-tb-15 p-lr-15 p-lr-5-sm">
        <h3
          class="fs mtext-105 cl0 mb-2 text-left"
          style="color: #000000; font-size: 20px; font-weight: 600"
        >
          Produk Diskon
        </h3>
      </div>
      <div
        class="container p-tb-15 p-lr-15 p-lr-5-sm"
        style="background-color: #000000; border-radius: 10px; padding: 15px"
      >
        <div>
          <!-- <a
            class="title-semua a.stext-101 cl0 pull-right"
            style="
              color: #32599f;
              font-size: 14px;
              line-height: 32px;
              font-weight: 400;
            "
            href="https://www.eurekabookhouse.co.id/cari/result/?category=novel"
            >Lihat semua</a
          > -->

          <div class="mt-3 position-relative">
            <!-- Navigation Buttons -->
            <div
              class="d-none d-lg-block flickity-prev-next-button flickity-prev-button"
              style="
                position: absolute;
                top: 50%;
                left: 5px;
                transform: translateY(-50%);
                z-index: 1;
              "
            >
              <button
                @click="prevSlide"
                style="
                  background-color: rgba(255, 255, 255, 0.85);
                  border-radius: 50%;
                  width: 50px;
                  font-size: 32px;
                  color: black;
                "
              >
                <i class="fa fa-angle-left" aria-hidden="true"></i>
              </button>
            </div>
            <flickity
              v-if="produkMaster.length > 0"
              ref="flickity"
              class="flickity"
              :options="flickityOptions"
            >
              <div
                class="carousel-cell wd-15 block2 bg0"
                v-for="(item, index) in produkMaster"
                :key="index"
              >
                <div
                  class="block2-pic hov-img0 text-center"
                  :class="{ 'label-new': item.product?.diskon > 0 }"
                  :data-label="item.product?.diskon + '%'"
                >
                  <img
                    class="img-official"
                    src="https://stagingapi.eurekabookhouse.com/assets/image/mall_badge.png"
                  />
                  <div>
                    <img
                      class="img-responsive"
                      :src="
                        'https://cdn.eurekabookhouse.co.id/ebh/product/all/' +
                        item.product?.image
                      "
                      :style="{
                        'max-width': '100%',
                        height: 'auto',
                        cursor: 'pointer',
                        opacity: item.quantity === 0 ? 0.2 : 1,
                      }"
                      alt=""
                      @error="handleImageError"
                      @click="item.quantity > 0 && handleProductClick(item)"
                    />

                    <div
                      v-if="item.quantity === 0"
                      class="sold-out-label"
                      style="
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        padding: 8px;
                        font-size: 20px;
                        color: rgb(119, 119, 119);
                        border-radius: 5px;
                      "
                    >
                      Habis
                    </div>
                  </div>
                </div>
                <div class="block2-txt flex-w flex-t p-all-8">
                  <div class="block2-txt-child1 flex-col-l">
                    <div
                      @click="handleProductClick(item)"
                      class="stext-105 cl2 hov-cl1 trans-04 js-name-b2 p-b-6"
                      style="
                        color: black;
                        text-align: left;
                        font-weight: 500;
                        font-size: 14px;
                        cursor: pointer;
                      "
                    >
                      {{ item.product?.product_desc?.name }}
                    </div>

                    <div class="stext-105">
                      <strong
                        class="cl13"
                        style="
                          color: var(--neutral-04, #989898);
                          font-size: 12px;
                          font-style: normal;
                          font-weight: 400;
                          line-height: 13.2px; /* 110% */
                          letter-spacing: -0.12px;
                          text-decoration: line-through;
                          cursor: pointer;
                        "
                      >
                        Rp
                        {{
                          item.product?.price
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                        }}
                      </strong>
                    </div>
                    <div class="stext-105">
                      <strong
                        class="cl13"
                        style="
                          color: var(--primary-05, #244786);
                          font-size: 16px;
                          font-style: normal;
                          font-weight: 600;
                          line-height: 18px; /* 112.5% */
                          letter-spacing: -0.16px;
                          cursor: pointer;
                        "
                      >
                        Rp
                        {{
                          (
                            item.product?.price -
                            item.product?.price * (item.product?.diskon / 100)
                          )
                            .toFixed(0)
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                        }}
                      </strong>
                    </div>
                  </div>
                </div>
              </div>
            </flickity>
            <div
              class="d-none d-lg-block flickity-prev-next-button flickity-next-button"
              style="
                position: absolute;
                top: 50%;
                right: 10px;
                transform: translateY(-50%);
              "
            >
              <button
                @click="nextSlide"
                style="
                  background-color: rgba(255, 255, 255, 0.85);
                  border-radius: 50%;
                  width: 50px;
                  font-size: 32px;
                  color: black;
                "
              >
                <i class="fa fa-angle-right" aria-hidden="true"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import Vue from "vue";
import numeral from "numeral";
import Flickity from "vue-flickity";
import Swal from "sweetalert2";

export default {
  name: "cardProductPilihanPage",
  components: {
    Flickity,
  },
  data() {
    return {
      flickityOptions: {
        initialIndex: 0,
        prevNextButtons: false,
        pageDots: false,
        cellAlign: "left",
        wrapAround: false,
        freeScroll: false,
      },
      produkMaster: [], // Variable to store product data
    };
  },
  mounted() {
    this.getProduk();
  },

  methods: {
    prevSlide() {
      this.$refs.flickity.previous();
    },

    nextSlide() {
      this.$refs.flickity.next();
    },
    handleProductClick(item) {
      // Dispatch an action to set the product_id in the Vuex store
      this.$store.dispatch("setProductId", item.product_id);

      // Navigate to the detail page
      this.$router.push({
        name: "detailprodukPage",
        params: { slug: item.product?.product_desc?.seo },
      });
    },
    handleImageError(event) {
      event.target.src =
        "https://sandbox.eurekabookhouse.co.id/img/ebh.812ea2ce.png";
    },
    getProduk() {
      const apiUrl =
        "https://stagingapi.eurekabookhouse.com/nimda/product-special/?limit=10";

      const requestOptions = {
        method: "GET",
        redirect: "follow",
      };

      fetch(apiUrl, requestOptions)
        .then((response) => response.json()) // Parse the response as JSON
        .then((data) => {
          if (data.status.code === 200) {
            this.produkMaster = data.data;

            // Jika produk kosong, jangan tampilkan alert error
            if (this.produkMaster.length === 0) {
              return;
            }
          } else {
            console.error("Error fetching product data:", data.status.message);
          }
        })

        .catch((error) => {
          console.error("Error fetching data:", error);
          Swal.fire({
            confirmButtonColor: "#3860A8",
            icon: "error",
            title: "Failed to Fetch Product Data",
            text: "An error occurred while fetching product data. Please try again.",
          });
        });
    },
  },
};
</script>

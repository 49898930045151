<template>
  <div>
    <section class="mt-3 sec-relate-product bg6 mb-5">
      <div
        class="container p-tb-15 p-lr-15 p-lr-5-sm"
        style="background-color: #fff; border-radius: 10px; padding: 15px"
      >
        <h3
          class="fs mtext-105 cl0 mb-2 text-left"
          style="color: #000000; font-size: 20px; font-weight: 600"
        >
          Penerbit
        </h3>
        <flickity
          ref="flickity"
          class="flickity-manufacturers mt-3"
          :options="flickityOptions"
        >
          <div
            class="manufacturer-card"
            v-for="manufacturer in manufactureResult"
            :key="manufacturer.slug"
            @click="handleManufacturerClick(manufacturer)"
            style="
              cursor: pointer;
              display: flex;
              flex-direction: column;
              align-items: center;
              background-color: #f9f9f9;
              border-radius: 8px;
              padding: 10px;
              margin-right: 10px;
              box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
              transition: transform 0.2s, box-shadow 0.2s;
              width: 150px;
            "
          >
            <img
              :src="getManufacturerImageUrl(manufacturer.image)"
              alt=""
              style="
                height: 100px;
                object-fit: cover;

                margin-bottom: 10px;
              "
              @error="handleImageError"
            />
            <h5
              style="
                color: #244786;
                font-size: 14px;
                font-weight: 600;
                text-align: center;
                margin: 0;
              "
            >
              {{ manufacturer.name }}
            </h5>
          </div>
        </flickity>
        <div
          v-if="manufactureResult.length === 0"
          style="text-align: center; margin-top: 20px"
        >
          <p style="color: #666">No manufacturers available.</p>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import Flickity from "vue-flickity";
import axios from "axios";
import Swal from "sweetalert2";

export default {
  name: "cardManufacturPage",
  components: {
    Flickity,
  },
  data() {
    return {
      manufactureResult: [], // Data manufacturer
      flickityOptions: {
        cellAlign: "left", // Posisi awal item ke kiri
        contain: true, // Item tetap berada di dalam slider
        pageDots: false, // Tidak menampilkan titik halaman
        prevNextButtons: true, // Tombol navigasi kiri/kanan
        freeScroll: true, // Scroll bebas
        wrapAround: true, // Slider dapat berputar
      },
    };
  },
  mounted() {
    this.getManufacturers(); // Ambil data manufacturer saat komponen dipasang
  },
  methods: {
    getManufacturers() {
      const apiUrl =
        "https://stagingapi.eurekabookhouse.com/manufactur?limit=10";
      axios
        .get(apiUrl)
        .then((response) => {
          if (response.status === 200) {
            this.manufactureResult = response.data.data.order;
            this.$nextTick(() => {
              if (this.$refs.flickity) {
                this.$refs.flickity.rerender(); // Pastikan Flickity dirender ulang setelah data diubah
              }
            });
          } else {
            Swal.fire({
              confirmButtonColor: "#3860A8",
              icon: "error",
              title: "Failed to Fetch Manufacturer Data",
              text: "An error occurred while fetching manufacturer data. Please try again.",
            });
          }
        })
        .catch((error) => {
          console.error(error);
          Swal.fire({
            confirmButtonColor: "#3860A8",
            icon: "error",
            title: "Failed to Fetch Manufacturer Data",
            text: "An error occurred while fetching manufacturer data. Please try again.",
          });
        });
    },
    handleManufacturerClick(manufacturer) {
      const slug = manufacturer.slug;
      this.$router.push({
        name: "kategoriPenerbitPage",
        params: { slug: slug },
      });
    },
    getManufacturerImageUrl(image) {
      return `https://stagingapi.eurekabookhouse.com/assets/uplod/merk/${image}`;
    },
    handleImageError(event) {
      event.target.src = "/img/no-img.png";
      event.target.style.borderRadius = "0%";
    },
  },
};
</script>

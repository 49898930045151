<template>
  <div class="bg-gray-100 min-h-screen">
    <!-- Navbar Component -->
    <Navbar />

    <section class="bg0 p-b-20 text-left">
      <div class="container p-lr-80-lg">
        <div class="row">
          <div class="col-md-1"></div>
          <div class="col-md-10">
            <h1 class="ltext-102 cl2 p-t-28">
              KENANGAN TENTANG AYAH - JUARA FAVORIT 6 LOMBA CERPEN EUREKA
              BOOKHOUSE
            </h1>
            <div class="flex-m flex-sb m-tb-15">
              <div class="flex-i flex-w flex-m stext-111 cl2">
                <span>
                  <span class="cl4">Oleh</span> Eureka
                  <span class="cl12 m-l-4 m-r-6">|</span>
                </span>
                <span>
                  11 Nov, 2022 <span class="cl12 m-l-4 m-r-6">|</span>
                </span>
                <div id="share" class="m-l-50-lg">
                  <a
                    class="fs-14 badge p-all-5 hov-cl0 facebook customer share"
                    href="https://www.facebook.com/sharer.php?u=https://www.eurekabookhouse.co.id/blog/read/kenangan-tentang-ayah---juara-favorit-6-lomba-cerpen-eureka-bookhouse"
                    target="_blank"
                    ><i class="fa fa-facebook"></i
                  ></a>
                  <a
                    class="fs-14 badge p-all-5 hov-cl0 twitter customer share"
                    href="http://twitter.com/share?url=https://www.eurekabookhouse.co.id/blog/read/kenangan-tentang-ayah---juara-favorit-6-lomba-cerpen-eureka-bookhouse&amp;text=Blog: KENANGAN TENTANG AYAH - JUARA FAVORIT 6 LOMBA CERPEN EUREKA BOOKHOUSE &amp;hashtags=eurekabookhouse"
                    title="Twitter share"
                    target="_blank"
                    ><i class="fa fa-twitter"></i
                  ></a>
                  <a
                    class="fs-14 badge p-all-5 hov-cl0 googleplus customer share"
                    href="https://plus.google.com/share?url=https://www.eurekabookhouse.co.id/blog/read/kenangan-tentang-ayah---juara-favorit-6-lomba-cerpen-eureka-bookhouse"
                    title="Google Plus Share"
                    target="_blank"
                    ><i class="fa fa-google-plus"></i
                  ></a>
                </div>
              </div>
              <div class="flex-r-m">
                <span class="stext-107 text-center"
                  ><i class="fa fa-eye"></i> 2057</span
                >
              </div>
            </div>
            <div class="wrap-pic-w how-pos5-parent">
              <img
                src="https://cdn.eurekabookhouse.co.id/ebh/blog/9-min.jpg"
                alt="IMG-BLOG"
                class="blog-read-pic"
              />
              <div class="flex-col-c-m size-123 bg9 how-pos5">
                <span class="ltext-107 cl2 txt-center"> 11 </span>

                <span class="stext-109 cl3 txt-center"> 11 2022 </span>
              </div>
            </div>
          </div>
          <div class="col-md-1"></div>
        </div>
        <div class="row p-t-35 p-b-80">
          <div class="col-lg-2"></div>
          <div class="col-md-8 col-lg-8">
            <div class="p-r-0-lg">
              <div>
                <div class="isi_blog ctext-101 fs-18 cl2 p-b-26">
                  <p>
                    Aku berjalan di lorong rumah sakit. Beberapa perawat dan
                    dokter menyapaku. Hingga aku tiba di sebuah ruangan
                    bertuliskan
                    <strong>&ldquo;Ruangan Dr. Anatasya&rdquo;</strong>. Itu
                    ruanganku. Aku bekerja menjadi dokter di salah satu rumah
                    sakit yang ada di Jakarta. Sudah menjadi cita citaku sejak
                    kecil untuk mencapai posisi ini.
                  </p>

                  <p>
                    Aku mengambil sesuatu dari dalam laciku. Sebuah buku lusuh
                    yang mungkin lebih cocok disebut album. Kubuka satu persatu
                    lembar album itu. Sampai, sebuah kenangan terlintas di
                    benakku.
                  </p>

                  <p>&bull;&bull;&bull;</p>

                  <p>
                    &ldquo;Huuu.. Ana jelek, Ana anaknya tukang becak,&rdquo;
                  </p>

                  <p>
                    &ldquo;TEMAN-TEMAN! KITA NGGAK USAH TEMENAN SAMA ANA, DIA
                    KAN CUMA ANAKNYA TUKANG BECAK,&rdquo; Teriak seorang anak
                    laki-laki bertubuh gemuk.
                  </p>

                  <p>
                    Di samping itu, seorang gadis kecil menangis tersedu-sedu.
                  </p>

                  <p>
                    &ldquo;Memangnya kenapa kalau Ana anaknya tukang
                    becak?&rdquo; Tanya Ana sambil menangis.
                  </p>

                  <p>
                    &ldquo;Kamu itu tidak cocok berteman dengan kita. Kita ini
                    anak pengusaha sedangkan kamu cuma anak tukang becak. Kamu
                    tidak akan sukses!&rdquo; Ucap seorang anak laki-laki
                    berambut ikal.
                  </p>

                  <p>
                    &ldquo;Ya sudah lihat saja, nanti akan kubuktikan kalau aku
                    juga bisa sukses!&rdquo; Seru Ana sambil mengusap air
                    matanya.
                  </p>

                  <p><em>Kringg</em></p>

                  <p>
                    Bel sudah berbunyi, tandanya pelajaran telah selesai dan
                    murid-murid SD Permata diperbolehkan pulang ke rumahnya.
                  </p>

                  <p>
                    &ldquo;AYAAHHH!&rdquo; Seru Ana berlari ke arah seorang pria
                    yang sedang duduk di atas becaknya.
                  </p>

                  <p>
                    &ldquo;Eh, anak ayah sudah pulang,&rdquo; Sambut pria itu.
                    Terlihat dari wajahnya yang penuh keringat, namun dengan
                    segera pria itu mengusap keringat di wajahnya berganti
                    dengan senyum ceria.
                  </p>

                  <p>
                    &ldquo;Bagaimana sekolahnya Ana?&rdquo; Tanya ayah Ana
                    sambil mendorong becaknya.
                  </p>

                  <p>
                    &ldquo;... &ldquo; Tidak ada jawaban yang keluar dari mulut
                    Ana. Gadis itu mengingat bagaimana perlakuan teman temannya
                    disekolah. Setelah hening beberapa saat, Ana baru mau
                    berbicara. Tetapi yang keluar bukan jawaban, melainkan
                    sebuah pertanyaan.
                  </p>

                  <p>
                    &ldquo;Memangnya Ana tidak boleh sukses ya, ayah?&rdquo;
                  </p>

                  <p>
                    &ldquo;Ayah kenapa jadi tukang becak sih? Karena ayah tukang
                    becak, setiap hari Ana diejek sama teman-teman Ana,&rdquo;
                    Ucap Ana pelan.
                  </p>

                  <p>
                    &ldquo;Ana, meskipun ayah cuma tukang becak, tapi pekerjaan
                    ayah ini halal. Ayah juga tetap bisa mencukupi kebutuhan
                    kita sehari hari,&rdquo; Jawab ayah Ana dengan sabar.
                  </p>

                  <p>
                    &ldquo;TAPI AKU MALU PUNYA AYAH SEPERTI AYAH!&rdquo; Bentak
                    Ana tanpa sadar. Ia turun dari becak ayahnya dan berlari
                    menjauh. Bahkan teriakan ayahnya pun tak disahuti oleh Ana.
                  </p>

                  <p>
                    Pak Reno, ayah Ana hanya bisa menghela napas. Saat ini,
                    hanya Ana yang dimilikinya, istrinya sudah meninggal sejak
                    melahirkan Ana.
                  </p>

                  <p>
                    <em
                      >&ldquo;Ya Allah, hamba mohon, lindungilah putri hamba ya
                      Allah,&rdquo; </em
                    >Batinnya berdoa.
                  </p>

                  <p>Disisi lain..</p>

                  <p>Ana berlari menjauh dari becak ayahnya.</p>

                  <p>
                    <em
                      >&ldquo;Ya Allah, Ana minta maaf, Ana sudah bentak ayah
                      tadi, maaf in Ana ya Allah,&rdquo; </em
                    >Batin Ana.
                  </p>

                  <p>
                    Tak lama kemudian, Ana sampai di rumahnya. Meskipun Ana
                    masih SD, dia sudah tahu arah jalan rumahnya. Ana segera
                    masuk ke dalam rumah. Tanpa berganti baju, Ana langsung naik
                    ke tempat tidurnya, ia menangis hingga akhirnya tertidur
                    pulas.
                  </p>

                  <p>
                    Tanpa Ana sadari, sejak ia berlari, ayahnya sudah mengikuti
                    menggunakan becaknya. Bahkan ayahnya pun melihat Ana yang
                    menangis di tempat tidur.
                  </p>

                  <p>
                    Setelah melihat anaknya tertidur, pak Reno langsung pergi
                    dari rumah menggunakan becaknya.
                  </p>

                  <p>
                    <em
                      >&ldquo;Ayah akan cari uang yang banyak, nak. Supaya Ana
                      nggak diejek lagi sama teman teman Ana dan Ana bisa jadi
                      orang yang sukses,&rdquo;</em
                    >
                    Batin pak Reno.
                  </p>

                  <p>
                    Dengan penuh semangat, pak Reno berkeliling untuk mencari
                    penumpang becaknya. Bahkan dia juga menjadi kuli bangunan,
                    agar bisa mendapat penghasilan tambahan.
                  </p>

                  <p>
                    Waktu sudah menunjukkan pukul 8 malam. Pak Reno berniat
                    kembali kerumahnya. Hari ini, beliau mendapat uang yang
                    banyak. Bahkan tadi ada orang yang menaiki becaknya untuk
                    berkeliling dan memberinya upah yang cukup banyak
                  </p>

                  <p>
                    Pak Reno sangat senang, akhirnya ia bisa mendapat uang untuk
                    putri kecilnya. Karena terlalu senang pak Reno tak sadar,
                    ada sebuah mobil yang melaju sangat kencang dari arah
                    belakangnya.
                  </p>

                  <p><em>Brakkk</em></p>

                  <p>Kecelakaan tak bisa dihindari.</p>

                  <p>
                    Sedangkan dirumah ya, Ana baru saja bangun tidur karena
                    mendengar dering telpon yang tak kunjung berhenti. Ia pun
                    memutuskan untuk mengangkat ya.
                  </p>

                  <p>&ldquo;Halo,&rdquo;</p>

                  <p>
                    <em
                      >&ldquo;Halo, selamat malam. Apa benar ini keluarga dari
                      pak Reno?&rdquo;</em
                    >
                  </p>

                  <p>&ldquo;Benar om. Ana anaknya pak Reno. Om siapa?&rdquo;</p>

                  <p>
                    <em
                      >&ldquo;Begini nak, ayahmu mendapat musibah diperjalanan,
                      beliau kecelakaan. Apakah kamu bisa ke jalan
                      Garuda?&rdquo;</em
                    >
                  </p>

                  <p>Sontak saja, Ana yang mendengarnya terkejut.</p>

                  <p>
                    &ldquo;Bisa om, Ana segera kesana,&rdquo; Tanpa menunggu
                    jawaban dari penelpon, Ana langsung mematikan sambungannya.
                    Ia berlari keluar rumah menuju tempat yang tadi disebutkan.
                  </p>

                  <p>
                    <em
                      >&ldquo;Ayah, ini pasti bohong kan? Ana cuma lagi mimpi
                      kan? Ayah pasti baik baik aja kan? Ya Allah, Ana minta
                      maaf ya Allah... Jangan bawa pergi ayah Ana ya
                      Allah,&rdquo;
                    </em>
                  </p>

                  <p>
                    Sesampainya ditempat tujuan, Ana melihat banyak orang
                    berkerumun. Ia langsung menerobos kerumunan itu dan
                    mendapati seorang pria tua dengan darah melumuri seluruh
                    tubuhnya.
                  </p>

                  <p>&ldquo;AYAAHH!&rdquo;</p>

                  <p>&ldquo;A-Ana? Ana ke-kenapa disini?&rdquo;</p>

                  <p>
                    &ldquo;Ayah, ayah, Ana minta maaf, yah. Ana janji Ana akan
                    jadi anak yang baik buat ayah,&rdquo;
                  </p>

                  <p>
                    &ldquo;Ka-kamu sudah jadi anak yang paling baik buat ayah,
                    Ana janji ya kalau sudah besar Ana harus jadi orang yang
                    sukses. Bikin bangga ayah sama ibu disana,&rdquo;
                  </p>

                  <p>
                    Melihat ayahnya dengan penuh darah namun masih berusaha
                    berbicara padanya, membuatnya begitu menyesal telah
                    membentak ayahnya tadi.
                  </p>

                  <p>
                    &ldquo;I-iya ayah, A-Ana janji Ana akan jadi orang sukses.
                    Tapi ayah harus bertahan ya, ayah jangan tinggalin Ana
                    sendirian,&rdquo; Ana menangis, menggenggam erat tangan
                    ayahnya seolah tak mau ditinggalkan. Tentu saja kejadian ini
                    banyak dilihat oleh orang-orang.
                  </p>

                  <p>
                    &ldquo;Ana harus rajin ibadah ya, doain ayah dan ibu disana.
                    Se-selamat tinggal Ana,&rdquo;
                  </p>

                  <p>Ana melihat mata ayahnya yang sudah mulai tertutup.</p>

                  <p>&ldquo;A- AYAAHHH! JANGAN TINGGALIN ANA,&rdquo;</p>

                  <p>
                    Malam ini hujan turun, seolah langit ikut menangis mengantar
                    kepergian Pak Reno. Pak Reno adalah orang baik dan sudah
                    menjadi ayah terbaik buat Ana. Bohong jika Ana malu memiliki
                    ayah seperti Pak Reno, dan sekarang ia hanya bisa menyesali
                    perbuatannya.
                  </p>

                  <p>
                    <em
                      >&ldquo;Ayah, Ana minta maaf, Ana tahu Ana salah, maafin
                      Ana ya, yah. Ayah baik-baik disana, sekarang pasti ayah
                      senang karena ayah bisa bertemu ibu lagi. Disini Ana akan
                      rajin berdoa buat ayah dan ibu. Ana janji Ana akan jadi
                      orang yang sukses. Selamat tinggal ayah,&rdquo;</em
                    >
                  </p>

                  <p>
                    Saat itu juga ambulans datang. Pak Reno dibawa kerumah
                    sakit. Namun, tetap saja. Pak Reno tidak bisa diselamatkan
                    dan dinyatakan telah meninggal. Ana duduk dikursi rumah
                    sakit sampai seorang pria berjas menghampirinya.
                  </p>

                  <p>&ldquo;Kamu Ana ya?&rdquo; Tanya pria itu.</p>

                  <p>
                    &ldquo;iya om. Om siapa?&rdquo; Ana menjawab dengan lesu. Ia
                    masih memikirkan keadaannya sekarang. Uang yang diberikan
                    ayahnya hanya cukup untuk 2 minggu. Harus bagaimana supaya
                    dia tetap bertahan hidup?
                  </p>

                  <p>
                    &ldquo;Om yang tadi menelepon kamu,&rdquo; Pria itu menjawab
                    sambil tersenyum.
                  </p>

                  <p>&ldquo;Iya om, ada apa ya?&rdquo;Tanya Ana lagi.</p>

                  <p>&ldquo;Sekarang Ana tinggal sama siapa?&rdquo;</p>

                  <p>
                    &ldquo;Ana tidak tahu mau tinggal dengan siapa, om. Ana
                    sudah tidak punya siapa siapa lagi, om&rdquo; Ana kembali
                    menangis, mengingat dirinya yang tidak memiliki sanak
                    saudara sedangkan kedua orangtua nya sudah meninggal.
                  </p>

                  <p>
                    &ldquo;Bagaimana kalau kamu tinggal bersama om? Om punya
                    anak sebaya kamu, nanti kalian bisa main bersama,&rdquo;
                    Ajak pria itu. Sontak, Ana langsung tersenyum, menghapus air
                    mata di pipinya.
                  </p>

                  <p>&ldquo;Ana mau, om!&rdquo;</p>

                  <p>&bull;&bull;&bull;</p>

                  <p>
                    Mengingat kenangan itu membuat air mataku turun. Sebuah
                    kenangan yang sangat menyakitkan untuk diingat. Namun, karna
                    kenangan itulah yang membuat aku menjadi seperti ini.
                  </p>

                  <p>
                    &ldquo;ANAAAA, PAPA DATANGG! Lho putri papa kenapa
                    nangis?&rdquo; Aku melihat seorang pria masuk ke ruanganku
                    tanpa mengetuk pintu terlebih dahulu.
                  </p>

                  <p>
                    &ldquo;Nggak apa apa kok, pa,&rdquo; Ucapku sambil mengusap
                    air mataku.
                  </p>

                  <p>
                    &ldquo;Nih, papa bawain ayam goreng kesukaan Ana. Papa tahu
                    Ana pasti sedih lagi. Sekarang kita makan aja ya, nanti kita
                    kemakam ayah Ana,&rdquo; Aku tersenyum mendengar ucapan pria
                    di hadapanmu ini.
                  </p>

                  <p>
                    &ldquo;Aku sayang papa,&rdquo; Tanpa menyahuti ucapannya,
                    aku memeluk pria dihadapanku.
                  </p>

                  <p>&ldquo;Papa juga sayang Ana, selalu,&rdquo;</p>

                  <p>&bull;&bull;&bull;</p>

                  <p>
                    <em
                      >Ayah, ayah apa kabar disana? Disini Ana baik yah. Tapi,
                      setiap hari Ana rindu ayah. Ana yakin pasti disana ayah
                      senang. Kan ada ibu dan Allah yang selalu menyayangi ayah.
                      Setiap hari Ana berdoa untuk ayah kok.
                    </em>
                  </p>

                  <p>
                    <em
                      >Ayah, disini Ana sudah sukses. Sekarang Ana sudah bisa
                      mewujudkan mimpi Ana jadi dokter. Itu semua juga berkat
                      ayah dan ibu yang selalu mendoakan Ana. Sekarang Ana
                      tinggal dengan papa. Orang tua yang Ana punya setelah
                      kepergian ayah. Papa itu sangat baik dan sayang pada Ana.
                    </em>
                  </p>

                  <p>
                    <em
                      >Ayah, sekarang Ana bahagia. Ana minta maaf ya yah. Dulu,
                      Ana masih belum bisa bahagiain ayah. Tapi, Ana selalu
                      berdoa, semoga ayah selalu bahagia bersama ibu disana. Ana
                      sayang ayah, selalu.
                    </em>
                  </p>

                  <p><em>~Dari Anatasya, putri kecil ayah</em></p>

                  <p>Penulis: <strong>Diza Aisyah Khansa</strong></p>

                  <p>&nbsp;</p>
                </div>
              </div>
              <div class="media ava-bloger">
                <img
                  class="mr-3 rounded-circle img-thumbnail"
                  src="https://eurekabookhouse.co.id/assets/uplod/profile/04112020_1586576240.png"
                  alt="Generic placeholder image"
                />
                <div class="media-body">
                  <p class="stext-101 m-b-8">DITULIS OLEH</p>
                  <h5 class="mt-0 mtext-111">Eureka Writer</h5>
                  <p class="badge badge-primary">Content Writer</p>
                  <p class="stext-107">Content Writter eurekabookhouse.co.id</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="sec-relate-product bg6 p-t-20 text-left">
      <div class="container p-tb-10">
        <div class="p-b-15">
          <h3 class="session-title mtext-106 cl5">New Entry</h3>
        </div>
        <div class="row">
          <div class="col-sm-6 col-md-3 p-b-20">
            <div class="blog-item bg0 bor8">
              <div class="blog-pic hov-img0">
                <router-link :to="'/Blog/kisahkasih'">
                  <img
                    class="blog-read-pic"
                    src="https://cdn.eurekabookhouse.co.id/ebh/blog/13-min.jpg"
                  />
                </router-link>
              </div>
              <div class="p-all-15">
                <div class="stext-112 flex-w p-b-14 cl1">
                  <router-link :to="'/Blog/kisahkasih'"
                    >Sahabat Eureka</router-link
                  >
                </div>
                <h4 class="p-b-32">
                  <router-link
                    :to="'/Blog/kisahkasih'"
                    class="mtext-101 cl2 hov-cl1 trans-04"
                  >
                    KISAH KASIH - JUARA FAVORIT 10 LOMBA CERPEN EUREKA BOOKHOUSE
                  </router-link>
                </h4>
                <span class="stext-102 m-t-20">05 September 2022</span>
              </div>
            </div>
          </div>
          <div class="col-sm-6 col-md-3 p-b-20">
            <div class="blog-item bg0 bor8">
              <div class="blog-pic hov-img0">
                <router-link :to="'/Blog/rahasiadelia'">
                  <img
                    class="blog-read-pic"
                    src="https://cdn.eurekabookhouse.co.id/ebh/blog/12-min.jpg"
                  />
                </router-link>
              </div>
              <div class="p-all-15">
                <div class="stext-112 flex-w p-b-14 cl1">
                  <router-link :to="'/Blog/rahasiadelia'"
                    >Sahabat Eureka</router-link
                  >
                </div>
                <h4 class="p-b-32">
                  <router-link
                    :to="'/Blog/rahasiadelia'"
                    class="mtext-101 cl2 hov-cl1 trans-04"
                  >
                    RAHASIA DELIA - JUARA FAVORIT 9 LOMBA CERPEN EUREKA
                    BOOKHOUSE
                  </router-link>
                </h4>
                <span class="stext-102 m-t-20">05 September 2022</span>
              </div>
            </div>
          </div>
          <div class="col-sm-6 col-md-3 p-b-20">
            <div class="blog-item bg0 bor8">
              <div class="blog-pic hov-img0">
                <router-link :to="'/Blog/pohonmangga'">
                  <img
                    class="blog-read-pic"
                    src="https://cdn.eurekabookhouse.co.id/ebh/blog/11-min.jpg"
                  />
                </router-link>
              </div>
              <div class="p-all-15">
                <div class="stext-112 flex-w p-b-14 cl1">
                  <router-link :to="'/Blog/pohonmangga'"
                    >Sahabat Eureka</router-link
                  >
                </div>
                <h4 class="p-b-32">
                  <router-link
                    :to="'/Blog/pohonmangga'"
                    class="mtext-101 cl2 hov-cl1 trans-04"
                  >
                    POHON MANGGA ITU BERBUAH KEMBALI - JUARA FAVORIT 8 LOMBA
                    CERPEN EUREKA BOOKHOUSE
                  </router-link>
                </h4>
                <span class="stext-102 m-t-20">05 September 2022</span>
              </div>
            </div>
          </div>
          <div class="col-sm-6 col-md-3 p-b-20">
            <div class="blog-item bg0 bor8">
              <div class="blog-pic hov-img0">
                <router-link :to="'/Blog/malaikat'">
                  <img
                    class="blog-read-pic"
                    src="https://cdn.eurekabookhouse.co.id/ebh/blog/10-min.jpg"
                  />
                </router-link>
              </div>
              <div class="p-all-15">
                <div class="stext-112 flex-w p-b-14 cl1">
                  <router-link :to="'/Blog/malaikat'"
                    >Sahabat Eureka</router-link
                  >
                </div>
                <h4 class="p-b-32">
                  <router-link
                    :to="'/Blog/malaikat'"
                    class="mtext-101 cl2 hov-cl1 trans-04"
                  >
                    MALAIKAT PENJAGAKU - JUARA FAVORIT 7 LOMBA CERPEN EUREKA
                    BOOKHOUSE
                  </router-link>
                </h4>
                <span class="stext-102 m-t-20">05 September 2022</span>
              </div>
            </div>
          </div>
          <div class="col-sm-6 col-md-3 p-b-20">
            <div class="blog-item bg0 bor8">
              <div class="blog-pic hov-img0">
                <router-link :to="'/Blog/ttgayah'">
                  <img
                    class="blog-read-pic"
                    src="https://cdn.eurekabookhouse.co.id/ebh/blog/9-min.jpg"
                  />
                </router-link>
              </div>
              <div class="p-all-15">
                <div class="stext-112 flex-w p-b-14 cl1">
                  <router-link :to="'/Blog/ttgayah'"
                    >Sahabat Eureka</router-link
                  >
                </div>
                <h4 class="p-b-32">
                  <router-link
                    :to="'/Blog/ttgayah'"
                    class="mtext-101 cl2 hov-cl1 trans-04"
                  >
                    KENANGAN TENTANG AYAH - JUARA FAVORIT 6 LOMBA CERPEN EUREKA
                    BOOKHOUSE
                  </router-link>
                </h4>
                <span class="stext-102 m-t-20">05 September 2022</span>
              </div>
            </div>
          </div>
          <div class="col-sm-6 col-md-3 p-b-20">
            <div class="blog-item bg0 bor8">
              <div class="blog-pic hov-img0">
                <router-link :to="'/Blog/merah'">
                  <img
                    class="blog-read-pic"
                    src="https://cdn.eurekabookhouse.co.id/ebh/blog/8-min.jpg"
                  />
                </router-link>
              </div>
              <div class="p-all-15">
                <div class="stext-112 flex-w p-b-14 cl1">
                  <a
                    href="https://www.eurekabookhouse.co.id/blog/category/sahabat-eureka"
                    >Sahabat Eureka</a
                  >
                </div>
                <h4 class="p-b-32">
                  <router-link
                    :to="'/Blog/merah'"
                    class="mtext-101 cl2 hov-cl1 trans-04"
                  >
                    SEGENGGAM TANAH MERAH - JUARA FAVORIT 5 LOMBA CERPEN EUREKA
                    BOOKHOUSE
                  </router-link>
                </h4>
                <span class="stext-102 m-t-20">05 September 2022</span>
              </div>
            </div>
          </div>
          <div class="col-sm-6 col-md-3 p-b-20">
            <div class="blog-item bg0 bor8">
              <div class="blog-pic hov-img0">
                <router-link :to="'/Blog/jejak'">
                  <img
                    class="blog-read-pic"
                    src="https://cdn.eurekabookhouse.co.id/ebh/blog/7-min.jpg"
                  />
                </router-link>
              </div>
              <div class="p-all-15">
                <div class="stext-112 flex-w p-b-14 cl1">
                  <router-link :to="'/Blog/jejak'">Sahabat Eureka</router-link>
                </div>
                <h4 class="p-b-32">
                  <router-link
                    :to="'/Blog/jejak'"
                    class="mtext-101 cl2 hov-cl1 trans-04"
                  >
                    JEJAK SANDAL AYAH - JUARA FAVORIT 4 LOMBA CERPEN EUREKA
                    BOOKHOUSE
                  </router-link>
                </h4>
                <span class="stext-102 m-t-20">05 September 2022</span>
              </div>
            </div>
          </div>
          <div class="col-sm-6 col-md-3 p-b-20">
            <div class="blog-item bg0 bor8">
              <div class="blog-pic hov-img0">
                <router-link :to="'/Blog/pejuang'">
                  <img
                    class="blog-read-pic"
                    src="https://cdn.eurekabookhouse.co.id/ebh/blog/6-min.jpg"
                  />
                </router-link>
              </div>
              <div class="p-all-15">
                <div class="stext-112 flex-w p-b-14 cl1">
                  <router-link :to="'/Blog/pejuang'">Acara Toko</router-link>
                </div>
                <h4 class="p-b-32">
                  <router-link
                    :to="'/Blog/pejuang'"
                    class="mtext-101 cl2 hov-cl1 trans-04"
                  >
                    PERJUANGAN AYAHKU, UNTUK MASA DEPANKU - JUARA FAVORIT 3
                    LOMBA CERPEN EUREKA BOOKHOUSE
                  </router-link>
                </h4>
                <span class="stext-102 m-t-20">04 September 2022</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script scoped>
import Navbar from "../Nav.vue";
export default {
  name: "ttgayahPage",
  components: {
    Navbar,
  },
};
</script>

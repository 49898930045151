<template>
  <div class="bg-gray-100 min-h-screen">
    <!-- Navbar Component -->
    <Navbar />

    <!-- Blog Section -->
    <section class="max-w-6xl mx-auto py-10 bg-blog-background">
      <div class="text-center mb-6">
        <p class="text-p mt-2">
          Kumpulan informasi menarik yang telah disajikan oleh tim
        </p>
        <h1 class="text-3xl font-bold mb-2 mt-3">Eureka Bookhouse</h1>
      </div>
      <div class="mb-6 mt-4 flex justify-center">
        <div class="mb-6 mt-4 flex justify-center">
          <select
            class="px-2 py-2 border border-white rounded-lg bg-transparent text-white focus:outline-none focus:ring-2 focus:ring-white"
            @change="handleCategoryChange($event)"
          >
            <option value="">Kategori</option>
            <option value="/Blog/acaratoko">Acara Toko</option>
            <option value="/Blog/rekomondasi">Rekomendasi Buku</option>
            <option value="/Blog/promosi">Promosi</option>
            <option value="/Blog/review">Review Buku</option>
            <option value="/Blog/triva">Trivia</option>
            <option value="/Blog/fiksi">Fiksi</option>
            <option value="/Blog/nonfiksi">Non-Fiksi</option>
            <option value="/Blog/date">Update</option>
            <option value="/Blog/allblog">Sahabat Eureka</option>
          </select>
        </div>
      </div>
    </section>

    <section class="bg0 p-t-20 text-left" style="min-height: 100%">
      <div class="container">
        <h3 class="ltext-101 p-tb-20">Review Buku</h3>
        <div class="row"></div>
      </div>
    </section>
    <!-- <footer class="bgu1 p-t-15 p-b-15">
    <div class="container">
        <div class="p-t-10">
            <p class="stext-107 cl0 txt-center">
                Copyright &copy;Dikembangkan oleh Eureka IT Developer
            </p>
        </div>
    </div>
</footer> -->
  </div>
</template>

<script scoped>
import Navbar from "../Nav.vue";
export default {
  name: " reviewPage",
  components: {
    Navbar,
  },

  methods: {
    handleCategoryChange(event) {
      const selectedValue = event.target.value;
      if (selectedValue) {
        this.$router.push(selectedValue);
      }
    },
  },
};
</script>

<style scoped>
.bg-blog-background {
  background-image: url("../../assets/images/bg_blog.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding: 40px 20px;
  color: white;
  font-weight: bold;
}
.text-p {
  color: #acacac;
}

select {
  transition: all 0.3s ease;
}
select:hover {
  background-color: rgba(255, 255, 255, 0.1);
}
select option {
  background-color: #1f2937; /* sama seperti bg-gray-800 */
  color: white;
}
</style>

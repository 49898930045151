<template>
  <div class="bg-gray-100 min-h-screen">
    <!-- Navbar Component -->
    <Navbar />

    <div class="container mobile-blog-rapi-atas">
      <div class="bread-crumb flex-w p-tb-18 p-lr-0-lg">
        <a
          href="https://www.eurekabookhouse.co.id/"
          class="stext-109 cl8 hov-cl1 trans-04"
        >
          Home
          <i class="fa fa-angle-right m-l-9 m-r-10" aria-hidden="true"></i>
        </a>

        <a
          href="https://www.eurekabookhouse.co.id/blog"
          class="stext-109 cl8 hov-cl1 trans-04"
        >
          Blog
          <i class="fa fa-angle-right m-l-9 m-r-10" aria-hidden="true"></i>
        </a>

        <span class="stext-109 cl4 text-left">
          MALAIKAT PENJAGAKU - JUARA FAVORIT 7 LOMBA CERPEN EUREKA BOOKHOUSE
        </span>
      </div>
    </div>

    <!-- Content page -->
    <section class="bg0 p-b-20 text-left">
      <div class="container p-lr-80-lg">
        <div class="row">
          <div class="col-md-1"></div>
          <div class="col-md-10">
            <h1 class="ltext-102 cl2 p-t-28">
              MALAIKAT PENJAGAKU - JUARA FAVORIT 7 LOMBA CERPEN EUREKA BOOKHOUSE
            </h1>
            <div class="flex-m flex-sb m-tb-15">
              <div class="flex-i flex-w flex-m stext-111 cl2">
                <span>
                  <span class="cl4">Oleh</span> Eureka
                  <span class="cl12 m-l-4 m-r-6">|</span>
                </span>
                <span>
                  05 Sep, 2022 <span class="cl12 m-l-4 m-r-6">|</span>
                </span>
                <div id="share" class="m-l-50-lg">
                  <a
                    class="fs-14 badge p-all-5 hov-cl0 facebook customer share"
                    href="https://www.facebook.com/sharer.php?u=https://www.eurekabookhouse.co.id/blog/read/malaikat-penjagaku---juara-favorit-7-lomba-cerpen-eureka-bookhouse"
                    target="_blank"
                    ><i class="fa fa-facebook"></i
                  ></a>
                  <a
                    class="fs-14 badge p-all-5 hov-cl0 twitter customer share"
                    href="http://twitter.com/share?url=https://www.eurekabookhouse.co.id/blog/read/malaikat-penjagaku---juara-favorit-7-lomba-cerpen-eureka-bookhouse&amp;text=Blog: MALAIKAT PENJAGAKU - JUARA FAVORIT 7 LOMBA CERPEN EUREKA BOOKHOUSE &amp;hashtags=eurekabookhouse"
                    title="Twitter share"
                    target="_blank"
                    ><i class="fa fa-twitter"></i
                  ></a>
                  <a
                    class="fs-14 badge p-all-5 hov-cl0 googleplus customer share"
                    href="https://plus.google.com/share?url=https://www.eurekabookhouse.co.id/blog/read/malaikat-penjagaku---juara-favorit-7-lomba-cerpen-eureka-bookhouse"
                    title="Google Plus Share"
                    target="_blank"
                    ><i class="fa fa-google-plus"></i
                  ></a>
                </div>
              </div>
              <div class="flex-r-m">
                <span class="stext-107 text-center"
                  ><i class="fa fa-eye"></i> 2484</span
                >
              </div>
            </div>
            <div class="wrap-pic-w how-pos5-parent">
              <img
                src="https://cdn.eurekabookhouse.co.id/ebh/blog/10-min.jpg"
                alt="IMG-BLOG"
                class="blog-read-pic"
              />
              <div class="flex-col-c-m size-123 bg9 how-pos5">
                <span class="ltext-107 cl2 txt-center"> 05 </span>

                <span class="stext-109 cl3 txt-center"> 09 2022 </span>
              </div>
            </div>
          </div>
          <div class="col-md-1"></div>
        </div>
        <div class="row p-t-35 p-b-80">
          <div class="col-lg-2"></div>
          <div class="col-md-8 col-lg-8">
            <div class="p-r-0-lg">
              <div>
                <div class="isi_blog ctext-101 fs-18 cl2 p-b-26">
                  <p>
                    Malam ini hujan deras melanda kota kelahiranku. Petir
                    menyambar seakan Tuhan sedang murka dengan penduduk bumi.
                    Hempasan angin dingin yang tiada henti perlahan menerpa
                    kulit wajahku yang saat ini sedang termenung didepan jendela
                    kamar. Begitu malang orang yang sedang berlalu lalang
                    dijalan raya, mereka kedinginan karena air hujan yang
                    terus-terusan turun dengan lebatnya. Hatiku seketika tak
                    tenang. Aku sedikit khawatir dengan keadaan bapakku yang
                    belum juga pulang hingga saat ini, harap-harap Bapak tidak
                    kedinginan diluar sana.
                  </p>

                  <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    Akhir-akhir ini Bapak sering pulang tengah malam karena
                    urusan pekerjaan yang tak kunjung terselesaikan. Bapak
                    selalu memintaku untuk jangan mengkhawatirkannya ketika
                    dirinya tak kunjung sampai rumah. Namun nyatanya aku tak
                    bisa menahan rasa was-was yang seringkali menghantui
                    ketenanganku. Rasa takut akan kehilangan seorang pahlawan
                    dan cinta pertamaku selalu terlintas dalam benak dan angan.
                    Ini semua dianggap wajar oleh bapakku, aku adalah seorang
                    anak tunggal yang ditinggal meninggal oleh ibunya. Ragaku
                    selalu rapuh dimata Bapak, dan jiwaku selalu beliau anggap
                    sabar karena sering disakiti oleh garis takdir dan keadaaan.
                  </p>

                  <p>
                    &ldquo;Naya..&rdquo; Sebuah suara lembut mengalun perlahan
                    diindera pendengaranku. Sudah kuduga, itu suara Bapak yang
                    sedari tadi kutunggu kehadirannya.
                  </p>

                  <p>
                    Aku menatap sayu kearahnya, sosok tegar itu berdiri tegap
                    diambang pintu. &ldquo;Bapak tidak kehujanan, kan?&rdquo;
                    Tanyaku khawatir padanya.
                  </p>

                  <p>
                    &ldquo;Tidak, lihat saja baju Bapak. Tidak basah sama
                    sekali, kamu jangan khawatirkan keadaan bapak.&rdquo; Aku
                    tidak tahu Bapak sedang berbohong atau tidak. Sosok pahlawan
                    satu ini selalu berusaha untuk tertawa diatas beban
                    kehidupan yang ada.
                  </p>

                  <p>&ldquo;Bapak tidak sedang berbohong, kan?&rdquo;</p>

                  <p>
                    &ldquo;Naya, sejak kapan bapak pandai berbohong
                    denganmu?&rdquo;
                  </p>

                  <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    Aku tidak mengerti lagi, mengapa laki-laki yang sangat
                    kucintai ini selalu tegar dalam menerima kenyataan. Aku
                    tidak setegar bapak, aku gadis yang sangat rapuh ketika
                    harus menerima kepergian ibu untuk selama-lamanya. Namun
                    Bapak tidak selemah itu, beliau tetap berusaha tersenyum
                    untuk menguatkanku. Lima tahun sudah aku hidup hanya dengan
                    Bapak, dan saat ini umurku genap diangka 17. Semakin tua
                    usia, semakin banyak pula beban penderitaannya. Hingga detik
                    ini, aku belum mampu untuk membahagiakan bapak. Sedangkan
                    bapak selalu bisa membuatku tertawa dan tersenyum disetiap
                    harinya.
                  </p>

                  <p>
                    &ldquo;Selamat makan, Naya.&rdquo; Ucap Bapak seraya
                    tersenyum kearahku, senyuman itu benar-benar menenangkan
                    hatiku yang sedang gundah.
                  </p>

                  <p>&ldquo;Selamat makan juga, Pak.&rdquo;</p>

                  <p>
                    &ldquo;Kamu terlihat murung hari ini, ada apa?&rdquo; Tanya
                    Bapak kepadaku.
                  </p>

                  <p>
                    &ldquo;Tidak, Pak.&rdquo; Terpaksa aku harus berbohong
                    kepadanya, aku tidak mau Bapak kepikiran akan suatu hal yang
                    sudah lama kupendam ini.
                  </p>

                  <p>
                    &ldquo;Bapak tahu kamu sedang berbohong, Naya.&rdquo; Ujar
                    Bapak seraya menyuapkan sesendok nasi bersama lauk tempe
                    goreng tersebut.
                  </p>

                  <p>
                    Aku hanya mengaduk-aduk nasi yang sudah ada dihadapanku,
                    mengapa dalam sekejap rasa tempe goreng ini hambar
                    dilidahku. &ldquo;Tagihan uang gedung Naya belum dibayar,
                    Pak.&rdquo; Aku masih melihat mata lesu itu berbinar terang
                    bak rembulan, &ldquo;Naya punya rencana, besok Naya akan
                    jualan gorengan. Naya kan dulu sering bantu Almarhumah Ibu
                    jualan, itung-itung buat bantu Bapak nyari uang.&rdquo;
                    Lanjutku dengan nada melemah.
                  </p>

                  <p>&ldquo;Tidak usah, Nak.&rdquo;</p>

                  <p>
                    &ldquo;Bapak selalu melarang Naya untuk jualan, padahal Naya
                    kasihan Bapak harus kerja sekeras ini.&rdquo; Aku menyanggah
                    jawabaan singkat Bapak.
                  </p>

                  <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    Aku tidak tahu lagi, Bapak selalu pura-pura baik-baik saja
                    didepanku. Tubuhnya yang semakin kurus, bahunya yang legam
                    termakan sinar matahari, dan kulitnya yang semakin keriput
                    membuatku tak tega melihatnya terus-menerus bekerja dihari
                    tuanya. Aku benar-benar tidak berguna untuknya, betapa payah
                    dan bodohnya diriku ini. Setiap harinya, aku harus melihat
                    raga ringkih itu berjalan kesana kemari menjemput rezeki.
                    Tak jarang aku melihat Bapak mengusap kasar keringat yang
                    bercucuran dari dahinya. Bodoh, aku bodoh dan sangat bodoh.
                    Aku hanyalah beban untuknya, mengapa Tuhan masih
                    menghidupkanku hingga saat ini?
                  </p>

                  <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    Malam semakin larut, Bapak sudah masuk ke kamarnya bersiap
                    untuk terlelap. Aku masih sibuk membereskan meja yang
                    tadinya kita pakai untuk makan malam. Beberapa piring dan
                    gelas kotor segera kucuci bersih, selanjutnya ku rapikan
                    taplak meja lusuh tersebut. Tak lama kemudian, aku segera
                    bersiap menuju kamarku untuk bersiap tidur. Tak sengaja ku
                    dapati Bapak yang sudah tertidur pulas diranjang reotnya,
                    diatasnya terdapat topeng badut yang menggantung sempurna
                    disana. Topeng badut itu sudah kotor dan menjadi sarang
                    debu, hanya barang kecil itu yang dapat membantu keluarga
                    kecil ini bertahan hidup.
                  </p>

                  <p>
                    &ldquo;Tuhan, izinkan aku untuk membahagiakannya.&rdquo;
                    Batinku pelan sambil melihat sosok Bapak yang semakin hari
                    semakin menua itu.
                  </p>

                  <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    Keesokan harinya saat sore hari tiba, Bapak yang baru saja
                    pulang sehabis mencari uang tiba-tiba menunjukkan senyuman
                    lebar itu kepadaku. Aku tidak dapat menebak isi hati Bapak
                    sekarang, beliau terlihat sangat bahagia hari ini. Aku yang
                    menyambutnya diambang pintu segera membalas senyuman teduh
                    tersebut. Kuraih tangannya untuk menanyakan suatu hal
                    padanya, aku berharap Bapak mau menjawab pertanyaan ini
                    dengan sejujur-jujurnya.
                  </p>

                  <p>
                    &ldquo;Kelihatannya, ada kabar gembira nih dari
                    Bapak.&rdquo; Aku bersalaman dengan tangan keriput milik
                    Bapak. Senyuman Bapak belum juga sirna dari bibirnya.
                  </p>

                  <p>
                    &ldquo;Ini untuk kamu.&rdquo; Bapak tersenyum lagi kearahku.
                    Heran, mengapa sosok satu ini bak malaikat penjaga dimataku?
                  </p>

                  <p>
                    &ldquo;Bapak dapat uang sebanyak ini darimana?&rdquo; Aku
                    kembali dibuat heran dengan puluhan lembar uang seratus
                    ribuan yang baru saja Bapak berikan kepadaku.
                  </p>

                  <p>
                    Bapak menggenggam tanganku erat. &ldquo;Pakai saja uangnya
                    untuk biaya sekolahmu, Nak.&rdquo; Ucapnya penuh dengan
                    kesabaran.
                  </p>

                  <p>&ldquo;Bapak mencuri?&rdquo;</p>

                  <p>
                    &ldquo;Bapak tidak mungkin menghalalkan segala cara untuk
                    mendapatkan uang sebanyak ini.&rdquo;
                  </p>

                  <p>
                    Aku yang semakin curiga tanpa henti bertanya pada Bapak yang
                    masih mengukir senyuman tersebut. &ldquo;Bapak pinjam
                    uangnya siapa?&rdquo;
                  </p>

                  <p>
                    &ldquo;Bapak pinjam uangnya tetangga sebelah. Ada tetaangga
                    yang baik hati meminjamkan uang sebanyak ini kepada
                    Bapak.&rdquo; Bapak menjelaskan hal itu kepadaku,
                    &ldquo;besok segera bayar uang tagihan sekolahnya ya, jangan
                    ditunda-tunda biar kamu nggak dikeluarin dari
                    sekolah.&rdquo; Ujar Bapak kembali seraya memelukku erat.
                  </p>

                  <p>
                    Aku terisak dipelukan hangatnya, tak tega melihat Bapak
                    berjuaang sekeras ini demi keberlangsungan hidupku.
                    &ldquo;Naya janji akan belajar yang rajin, biar Bapak bisa
                    bangga sama Naya.&rdquo; Ucapku lembut padanya.
                  </p>

                  <p>
                    Malamnya sebelum aku tertidur pulas, aku menangis
                    tersedu-sedu kala membayangkan sosok Bapak yang susah payah
                    mencari pundi-pundi uang untukku. Biaya hidup semakin mahal,
                    mencari uang semakin tak gampang, dan semesta menuntutnya
                    untuk selalu tegar menghadapi drama kehidupan. Disaat aku
                    ingin berputus asa, Bapak selalu memberiku sebuah semangat
                    lewat senyumannya. Bapak adalah sosok yang sangat berharga
                    untukku, bagaimanapun kondisinya, ia akan tetap sempurna
                    dimataku. Apapun pekerjaannya, beliau tetap menjadi pria
                    terkaya yang ada dikehidupan anaknya. Cintanya yang selalu
                    ada untukku, senyumannya yang selalu menjadi penyemangatku
                    dan sorot matanya yang selalu mampu mebuatku terharu. Begitu
                    sempurnanya sosok Bapak dimataku, ingin aku berterimakasih
                    kepada Tuhan yang sudah mengirimkan sosok setangguh dia
                    dihidupku.
                  </p>

                  <p>
                    &ldquo;Tuhan, jagalah dia yang begitu tulus
                    mencintaiku.&rdquo;
                  </p>

                  <p>Penulis: <strong>INDIRA SEPTI</strong></p>
                </div>
              </div>
              <div class="media ava-bloger">
                <img
                  class="mr-3 rounded-circle img-thumbnail"
                  src="https://eurekabookhouse.co.id/assets/uplod/profile/04112020_1586576240.png"
                  alt="Generic placeholder image"
                />
                <div class="media-body">
                  <p class="stext-101 m-b-8">DITULIS OLEH</p>
                  <h5 class="mt-0 mtext-111">Eureka Writer</h5>
                  <p class="badge badge-primary">Content Writer</p>
                  <p class="stext-107">Content Writter eurekabookhouse.co.id</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="sec-relate-product bg6 p-t-20 text-left">
      <div class="container p-tb-10">
        <div class="p-b-15">
          <h3 class="session-title mtext-106 cl5">New Entry</h3>
        </div>
        <div class="row">
          <div class="col-sm-6 col-md-3 p-b-20">
            <div class="blog-item bg0 bor8">
              <div class="blog-pic hov-img0">
                <router-link :to="'/Blog/kisahkasih'">
                  <img
                    class="blog-read-pic"
                    src="https://cdn.eurekabookhouse.co.id/ebh/blog/13-min.jpg"
                  />
                </router-link>
              </div>
              <div class="p-all-15">
                <div class="stext-112 flex-w p-b-14 cl1">
                  <router-link :to="'/Blog/kisahkasih'"
                    >Sahabat Eureka</router-link
                  >
                </div>
                <h4 class="p-b-32">
                  <router-link
                    :to="'/Blog/kisahkasih'"
                    class="mtext-101 cl2 hov-cl1 trans-04"
                  >
                    KISAH KASIH - JUARA FAVORIT 10 LOMBA CERPEN EUREKA BOOKHOUSE
                  </router-link>
                </h4>
                <span class="stext-102 m-t-20">05 September 2022</span>
              </div>
            </div>
          </div>
          <div class="col-sm-6 col-md-3 p-b-20">
            <div class="blog-item bg0 bor8">
              <div class="blog-pic hov-img0">
                <router-link :to="'/Blog/rahasiadelia'">
                  <img
                    class="blog-read-pic"
                    src="https://cdn.eurekabookhouse.co.id/ebh/blog/12-min.jpg"
                  />
                </router-link>
              </div>
              <div class="p-all-15">
                <div class="stext-112 flex-w p-b-14 cl1">
                  <router-link :to="'/Blog/rahasiadelia'"
                    >Sahabat Eureka</router-link
                  >
                </div>
                <h4 class="p-b-32">
                  <router-link
                    :to="'/Blog/rahasiadelia'"
                    class="mtext-101 cl2 hov-cl1 trans-04"
                  >
                    RAHASIA DELIA - JUARA FAVORIT 9 LOMBA CERPEN EUREKA
                    BOOKHOUSE
                  </router-link>
                </h4>
                <span class="stext-102 m-t-20">05 September 2022</span>
              </div>
            </div>
          </div>
          <div class="col-sm-6 col-md-3 p-b-20">
            <div class="blog-item bg0 bor8">
              <div class="blog-pic hov-img0">
                <router-link :to="'/Blog/pohonmangga'">
                  <img
                    class="blog-read-pic"
                    src="https://cdn.eurekabookhouse.co.id/ebh/blog/11-min.jpg"
                  />
                </router-link>
              </div>
              <div class="p-all-15">
                <div class="stext-112 flex-w p-b-14 cl1">
                  <router-link :to="'/Blog/pohonmangga'"
                    >Sahabat Eureka</router-link
                  >
                </div>
                <h4 class="p-b-32">
                  <router-link
                    :to="'/Blog/pohonmangga'"
                    class="mtext-101 cl2 hov-cl1 trans-04"
                  >
                    POHON MANGGA ITU BERBUAH KEMBALI - JUARA FAVORIT 8 LOMBA
                    CERPEN EUREKA BOOKHOUSE
                  </router-link>
                </h4>
                <span class="stext-102 m-t-20">05 September 2022</span>
              </div>
            </div>
          </div>
          <div class="col-sm-6 col-md-3 p-b-20">
            <div class="blog-item bg0 bor8">
              <div class="blog-pic hov-img0">
                <router-link :to="'/Blog/malaikat'">
                  <img
                    class="blog-read-pic"
                    src="https://cdn.eurekabookhouse.co.id/ebh/blog/10-min.jpg"
                  />
                </router-link>
              </div>
              <div class="p-all-15">
                <div class="stext-112 flex-w p-b-14 cl1">
                  <router-link :to="'/Blog/malaikat'"
                    >Sahabat Eureka</router-link
                  >
                </div>
                <h4 class="p-b-32">
                  <router-link
                    :to="'/Blog/malaikat'"
                    class="mtext-101 cl2 hov-cl1 trans-04"
                  >
                    MALAIKAT PENJAGAKU - JUARA FAVORIT 7 LOMBA CERPEN EUREKA
                    BOOKHOUSE
                  </router-link>
                </h4>
                <span class="stext-102 m-t-20">05 September 2022</span>
              </div>
            </div>
          </div>
          <div class="col-sm-6 col-md-3 p-b-20">
            <div class="blog-item bg0 bor8">
              <div class="blog-pic hov-img0">
                <router-link :to="'/Blog/ttgayah'">
                  <img
                    class="blog-read-pic"
                    src="https://cdn.eurekabookhouse.co.id/ebh/blog/9-min.jpg"
                  />
                </router-link>
              </div>
              <div class="p-all-15">
                <div class="stext-112 flex-w p-b-14 cl1">
                  <router-link :to="'/Blog/ttgayah'"
                    >Sahabat Eureka</router-link
                  >
                </div>
                <h4 class="p-b-32">
                  <router-link
                    :to="'/Blog/ttgayah'"
                    class="mtext-101 cl2 hov-cl1 trans-04"
                  >
                    KENANGAN TENTANG AYAH - JUARA FAVORIT 6 LOMBA CERPEN EUREKA
                    BOOKHOUSE
                  </router-link>
                </h4>
                <span class="stext-102 m-t-20">05 September 2022</span>
              </div>
            </div>
          </div>
          <div class="col-sm-6 col-md-3 p-b-20">
            <div class="blog-item bg0 bor8">
              <div class="blog-pic hov-img0">
                <router-link :to="'/Blog/merah'">
                  <img
                    class="blog-read-pic"
                    src="https://cdn.eurekabookhouse.co.id/ebh/blog/8-min.jpg"
                  />
                </router-link>
              </div>
              <div class="p-all-15">
                <div class="stext-112 flex-w p-b-14 cl1">
                  <a
                    href="https://www.eurekabookhouse.co.id/blog/category/sahabat-eureka"
                    >Sahabat Eureka</a
                  >
                </div>
                <h4 class="p-b-32">
                  <router-link
                    :to="'/Blog/merah'"
                    class="mtext-101 cl2 hov-cl1 trans-04"
                  >
                    SEGENGGAM TANAH MERAH - JUARA FAVORIT 5 LOMBA CERPEN EUREKA
                    BOOKHOUSE
                  </router-link>
                </h4>
                <span class="stext-102 m-t-20">05 September 2022</span>
              </div>
            </div>
          </div>
          <div class="col-sm-6 col-md-3 p-b-20">
            <div class="blog-item bg0 bor8">
              <div class="blog-pic hov-img0">
                <router-link :to="'/Blog/jejak'">
                  <img
                    class="blog-read-pic"
                    src="https://cdn.eurekabookhouse.co.id/ebh/blog/7-min.jpg"
                  />
                </router-link>
              </div>
              <div class="p-all-15">
                <div class="stext-112 flex-w p-b-14 cl1">
                  <router-link :to="'/Blog/jejak'">Sahabat Eureka</router-link>
                </div>
                <h4 class="p-b-32">
                  <router-link
                    :to="'/Blog/jejak'"
                    class="mtext-101 cl2 hov-cl1 trans-04"
                  >
                    JEJAK SANDAL AYAH - JUARA FAVORIT 4 LOMBA CERPEN EUREKA
                    BOOKHOUSE
                  </router-link>
                </h4>
                <span class="stext-102 m-t-20">05 September 2022</span>
              </div>
            </div>
          </div>
          <div class="col-sm-6 col-md-3 p-b-20">
            <div class="blog-item bg0 bor8">
              <div class="blog-pic hov-img0">
                <router-link :to="'/Blog/pejuang'">
                  <img
                    class="blog-read-pic"
                    src="https://cdn.eurekabookhouse.co.id/ebh/blog/6-min.jpg"
                  />
                </router-link>
              </div>
              <div class="p-all-15">
                <div class="stext-112 flex-w p-b-14 cl1">
                  <router-link :to="'/Blog/pejuang'">Acara Toko</router-link>
                </div>
                <h4 class="p-b-32">
                  <router-link
                    :to="'/Blog/pejuang'"
                    class="mtext-101 cl2 hov-cl1 trans-04"
                  >
                    PERJUANGAN AYAHKU, UNTUK MASA DEPANKU - JUARA FAVORIT 3
                    LOMBA CERPEN EUREKA BOOKHOUSE
                  </router-link>
                </h4>
                <span class="stext-102 m-t-20">04 September 2022</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script scoped>
import Navbar from "../Nav.vue";
export default {
  name: "malaikatPage",
  components: {
    Navbar,
  },
};
</script>

<template>
  <div class="bg-gray-100 min-h-screen">
    <!-- Navbar Component -->
    <Navbar />

    <div class="container mobile-blog-rapi-atas">
      <div class="bread-crumb flex-w p-tb-18 p-lr-0-lg">
        <a
          href="https://www.eurekabookhouse.co.id/"
          class="stext-109 cl8 hov-cl1 trans-04"
        >
          Home
          <i class="fa fa-angle-right m-l-9 m-r-10" aria-hidden="true"></i>
        </a>

        <a
          href="https://www.eurekabookhouse.co.id/blog"
          class="stext-109 cl8 hov-cl1 trans-04"
        >
          Blog
          <i class="fa fa-angle-right m-l-9 m-r-10" aria-hidden="true"></i>
        </a>

        <span class="stext-109 cl4 text-left">
          KISAH KASIH - JUARA FAVORIT 10 LOMBA CERPEN EUREKA BOOKHOUSE
        </span>
      </div>
    </div>
    <section class="bg0 p-b-20">
      <div class="container p-lr-80-lg">
        <div class="row">
          <div class="col-md-1"></div>
          <div class="col-md-10">
            <h1 class="ltext-102 cl2 p-t-28 text-left">
              KISAH KASIH - JUARA FAVORIT 10 LOMBA CERPEN EUREKA BOOKHOUSE
            </h1>
            <div class="flex-m flex-sb m-tb-15">
              <div class="flex-i flex-w flex-m stext-111 cl2">
                <span>
                  <span class="cl4">Oleh</span> Eureka
                  <span class="cl12 m-l-4 m-r-6">|</span>
                </span>
                <span>
                  05 Sep, 2022 <span class="cl12 m-l-4 m-r-6">|</span>
                </span>
                <div id="share" class="m-l-50-lg">
                  <a
                    class="fs-14 badge p-all-5 hov-cl0 facebook customer share"
                    href="https://www.facebook.com/sharer.php?u=https://www.eurekabookhouse.co.id/blog/read/kisah-kasih---juara-favorit-10-lomba-cerpen-eureka-bookhouse"
                    target="_blank"
                    ><i class="fa fa-facebook"></i
                  ></a>
                  <a
                    class="fs-14 badge p-all-5 hov-cl0 twitter customer share"
                    href="http://twitter.com/share?url=https://www.eurekabookhouse.co.id/blog/read/kisah-kasih---juara-favorit-10-lomba-cerpen-eureka-bookhouse&amp;text=Blog: KISAH KASIH - JUARA FAVORIT 10 LOMBA CERPEN EUREKA BOOKHOUSE &amp;hashtags=eurekabookhouse"
                    title="Twitter share"
                    target="_blank"
                    ><i class="fa fa-twitter"></i
                  ></a>
                  <a
                    class="fs-14 badge p-all-5 hov-cl0 googleplus customer share"
                    href="https://plus.google.com/share?url=https://www.eurekabookhouse.co.id/blog/read/kisah-kasih---juara-favorit-10-lomba-cerpen-eureka-bookhouse"
                    title="Google Plus Share"
                    target="_blank"
                    ><i class="fa fa-google-plus"></i
                  ></a>
                </div>
              </div>
              <div class="flex-r-m">
                <span class="stext-107 text-center"
                  ><i class="fa fa-eye"></i> 3137</span
                >
              </div>
            </div>
            <div class="wrap-pic-w how-pos5-parent">
              <img
                src="https://cdn.eurekabookhouse.co.id/ebh/blog/13-min.jpg"
                alt="IMG-BLOG"
                class="blog-read-pic"
              />
              <div class="flex-col-c-m size-123 bg9 how-pos5">
                <span class="ltext-107 cl2 txt-center"> 05 </span>

                <span class="stext-109 cl3 txt-center"> 09 2022 </span>
              </div>
            </div>
          </div>
          <div class="col-md-1"></div>
        </div>
        <div class="row p-t-35 p-b-80">
          <div class="col-lg-2"></div>
          <div class="col-md-8 col-lg-8">
            <div class="p-r-0-lg">
              <div>
                <div class="isi_blog ctext-101 fs-18 cl2 p-b-26 text-left">
                  <p>&nbsp;</p>

                  <p>
                    Seorang gadis berambut hitam legam duduk termenung di depan
                    foto mendiang ibunya. Ia terlihat menahan air matanya yang
                    sudah siap jatuh itu. Gadis itu bernama Siti Maysaroh, siswi
                    di SMA Pembangun Cendekia. Ia tinggal berdua bersama
                    bapaknya. Meskipun ibunya telah tiada, bapaknya enggan untuk
                    menikah lagi. Setiap hari bapaknya itu berangkat ke sawah
                    yang terletak di dekat hutan jati.
                  </p>

                  <p>
                    &ldquo;May, Bapak mau berangkat dulu. Jangan lupa sarapan
                    kalau mau berangkat,&rdquo; Ujar Bapaknya kepada Siti.
                    Memang nama panggilan Siti adalah May.
                  </p>

                  <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &ldquo;Iya pak, hati-hati dijalan,&rdquo; jawabnya.
                  </p>

                  <p>
                    Sebelum ia beranjak, ia mengamati bapaknya itu. Bapaknya
                    mengambil cangkul dan bersiap mengayuh sepeda gunungnya. Ia
                    merasa kasihan kepada bapaknya yang setiap hari kerja
                    serabutan untuk membiayai sekolahnya. Setelah bapaknya sudah
                    tak terlihat, ia beranjak ke dapur untuk sarapan. Hari ini
                    menu sarapannya oseng-oseng tempe yang ia masak tadi subuh.
                    Dengan menu yang sederhana, ia tetap makan dnegan lahap.
                    Selesai ia sarapan, ia mengambil tas di bilik kamarnya yang
                    berukuran 2 x 4 meter itu dan segera memakai sepatu. Setelah
                    semuanya siap, ia berangkat kesekolah&nbsp; dengan sepeda
                    gunungnya.
                  </p>

                  <p>
                    May, telah sampai di sekolahnya sebelum pukul tujuh. Ia
                    segera masuk kelas dan menempati tempat duduknya yang berada
                    di barisan kedua dekat jendela. Ia sebangku dengan Linda.
                    Linda sosok yang ceria, beda dengan dia yang pendiam dan
                    tidak memiliki banyak teman. May, termasuk siswi yang
                    pandai, buktinya ia bisa masuk 10 besar di kelasnya.
                  </p>

                  <p>***</p>

                  <p>
                    Jam dinding telah menunjukkan pukul 14.55, sebentar lagi
                    pembelajarn akan usai. Bu Sari, guru pelajaran terakhir
                    sudah menutup pembelajaran dan siswa-siswi sudah mulai
                    memasukkan buku beserta peralatannya kedalam tas mereka. Tak
                    lama bel pulang berbunyi.
                  </p>

                  <p>
                    &ldquo;Baik anak-anak, pertemuan kita hari ini Ibu cukupkan
                    sampai disini. Silakan ketua kelas memimpin berdoa, dan
                    langsung pulang kerumahnya masing-masing,&rdquo; ucap Bu
                    Sari.
                  </p>

                  <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    Selesai berdoa, May bergegas menuju parkiran dan mulai
                    mengayuh sepedanya. Sebelum pulang, ia mampir ke toko
                    kelontong di dekat rumahnya. Ia akan membeli sayur untuk
                    menu makan malamnya nanti.
                  </p>

                  <p>&ldquo;Mak, ada sayur apa?&rdquo; tanya May.</p>

                  <p>
                    &ldquo;Ini ada kangkung, sawi. Baru pulang sekolah May kok
                    masih pake seragam?&rdquo;
                  </p>

                  <p>
                    &ldquo;Iya Mak, langsung mampir sini. Mak aku beli kangkung
                    sama tahu, berapa semuanya?&rdquo;
                  </p>

                  <p>&ldquo;Lima ribu. Mau pake plastik?&rdquo;</p>

                  <p>
                    &ldquo;Ndak usah Mak ini aku udah bawa kantong. Ini ya Mak
                    uangnya,&rdquo; ucap May sambil menyerahkan selembar uang
                    lima ribuan kepada Mak Warsinem.
                  </p>

                  <p>&ldquo;Iya, <em>maturnuwun</em>,&rdquo;</p>

                  <p>&ldquo;Iya Mak, aku pamit dulu Mak,&rdquo;</p>

                  <p>&ldquo;Ati-ati Nduk!!&rdquo;</p>

                  <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    May bergegas pulang kerumah, takutnya bapaknya sudah datang
                    dan makanan belum siap. Sesampainya di rumah, ia langsung
                    ganti baju dan pergi kedapur. Hari-harinya memang pekerjaan
                    rumah seperti ini ia yang lakukan semenjak ibunya meninggal.
                    Ia tak merasa kelelahan, justru ia bangga bisa menghidangkan
                    makanan untuk sang bapak. Ia mulai mencuci kangkung yang
                    dibelinya tadi dan menyiapkan bumbunya.
                  </p>

                  <p>
                    Selesai ia memasak tumis kangkung, iya mulai menyiapkan
                    bumbu untuk menggoreng tahu. Tak lama kemudian ia mendengar
                    bapaknya sudah datang.
                  </p>

                  <p>
                    &ldquo;Pak, mau mandi dulu apa makan dulu,&rdquo; tanya May.
                    Jam&nbsp; dinding sudah menunjukkan pukul setengah lima.
                  </p>

                  <p>
                    &ldquo;Mau mandi dulu saja, ini bapak kotor banget,&rdquo;
                  </p>

                  <p>
                    &ldquo;Yaudah Pak, itu airnya sudah panas,&rdquo; May
                    kemudian mengambil ember dan menyiapkan air hangat untuk
                    bapaknya.
                  </p>

                  <p>
                    &ldquo;Iya <em>Nduk, maturnuwun</em>,&rdquo; ungkap
                    bapaknya.
                  </p>

                  <p>
                    Sambil menunggu bapaknya mandi, ia mulai membuka buku dan
                    mengerjakakan PR. Selesai mandi, bapaknya bergegas makan.
                    Dan gantian May yang mandi. Ia masuk ke kamar mandi dan
                    mulai mengguyur tubuhnya dengan air.
                  </p>

                  <p>
                    Malam harinya ia kembali membuka bukunya dan melanjutkan PR
                    yang tadi belum selesai. Ketika ia melihat bapaknya yang
                    berada di dekat jendela, bapaknya sedang mengurut kakinya
                    itu dengan minyak urut. Kemudian ia menutup bukunya
                    dan&nbsp; beranjak menuju bapaknya.
                  </p>

                  <p>&ldquo;Kenapa kakinya Pak?&rdquo; tanya May.</p>

                  <p>
                    &ldquo;Ngak apa-apa kok Nduk, tadi cuma capek aja,&rdquo;
                    jawab bapaknya sembari tersenyum.
                  </p>

                  <p>
                    May merasa kasihan terhadap bapaknya. Ia kemudian mengambil
                    alih untuk memijat kaki bapaknya itu.
                  </p>

                  <p>&ldquo;Besok ndak usah ke sawah dulu Pak,&rdquo;</p>

                  <p>
                    &ldquo;Ndak apa-apa bentar lagi juga hilang capeknya kan
                    sudah dipijet sama anak bapak yang paling cantik,&rdquo;
                    jawab bapaknya.
                  </p>

                  <p>
                    &ldquo;Lagi pula besok setelah dari sawah disuruh ke
                    rumahnya Pak Mustaqim, bersih-bersih disana,&rdquo; imbuhnya
                  </p>

                  <p>&ldquo;Besok aku ikut kesawah ya pak,&rdquo;</p>

                  <p>
                    &ldquo;<em>Ndak</em> usah kamu dirumah saja, belajar,&rdquo;
                  </p>

                  <p>
                    &ldquo;Kan besok Sabtu pak, besok libur. PR ku juga tinggal
                    sedikit kok,&rdquo;
                  </p>

                  <p>
                    &ldquo;Terserah kamu lah Nduk. Bapak mau tidur dulu
                    ya,&rdquo;
                  </p>

                  <p>&ldquo;Iya pak,&rdquo;</p>

                  <p>
                    Ia kemudian kembali lagi mengerjakan PR nya yang sejak sore
                    tadi tertunda. Mulai dibaca dan di pahami deretan angka itu.
                    Selesai mengerjakan PR ia kemudian masuk bilik kamarnya
                    untuk tidur. Ia harus bangun pagi besok.
                  </p>

                  <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    Bagaskara mulai menampakkan sinarnya dan membangunkan orang
                    dari lelapnya. May sudah bangun dari tadi, ia tengah
                    memanaskan lauk yang ia masak kemarin dan memasak nasi.
                    Bapaknya tengah memperbaiki cangkulnya yang rusak. Setelah
                    makanan siap, May mengajak bapaknya untuk makan sebeum
                    beranjak ke sawah.
                  </p>

                  <p>
                    Sesampainya di sawah, ia dan bapaknya langsung bekerja.
                    Sawah itu merupakan sawah Pak Mustaqim. Bapaknya hanya buruh
                    untuk merawat sawah tersebut. May mulai menebarkan pupuk dan
                    bapaknya memperbaki saluran air yang tersumbat. Setelah
                    pekerjaan di sawah selesai, ia pergi ke hutan jati untuk
                    mengambil beberapa kayu yang akan digunakan untuk memasak
                    nanti. Hari semkain siang, ia dan bapaknya berteduh di gubuk
                    dan menyantap bekal yang ia bawa.
                  </p>

                  <p>
                    &ldquo;Habis ini, kamu pulang saja Nduk. Bapak mau ke
                    rumahnya Pak Mustaqim dulu,&rdquo; ucap Bapaknya
                  </p>

                  <p>&ldquo;Iya Pak,&rdquo;</p>

                  <p>
                    Selesai ia makan ia bergegas pulang ke rurmah sedangkan
                    bapaknya pergi ke rumah Pak Mustaqim untuk bersih-bersih
                    disana. Ketika menjelang sore bapaknya tak kunjung&nbsp;
                    sampai di rumah. Tiba-tiba ada tetangganya yang
                    memberitahukan bahwa&nbsp; bapaknya di Puskesmas. Ia
                    langsung bergegas ke Puskesmas.
                  </p>

                  <p>
                    &ldquo;Bapak kenapa, kok sampai di sini?&rdquo; tanyanya
                  </p>

                  <p>
                    &ldquo;Pak Sutris tadi&nbsp; jatuh dari tangga Nduk, katanya
                    bu dokter disuruh istirahat dua pekan,&rdquo;
                  </p>

                  <p>
                    May menahan air matanya agar tidak jatuh, bapaknya bekerja
                    keras untuk membiayai sekolahnya. Walaupun lelah, bapaknya
                    tidak pernah ngeluh.
                  </p>

                  <p>
                    &ldquo;May, tadi sudah <em>tak</em> bayar obatnya. Nggak
                    usah diganti uangnya ya,&rdquo; ujar Pak Mustaqim
                  </p>

                  <p>&ldquo;Iya pak, terimakasih banyak Pak,&rdquo; jawabnya</p>

                  <p>
                    Kemudian ia dan bapaknya diantarkan pulang oleh Pak Mustaqim
                    menggunakan mobil beliau. Sesampainya dirumah, ia menyuruh
                    bapaknya untuk istirahat.
                  </p>

                  <p>
                    &ldquo;Bapak mau mandi dulu Nduk, badan lengket
                    <em>ndak</em> bisa tidur,&rdquo;
                  </p>

                  <p>
                    &ldquo;Yaudah Pak, <em>tak</em> siapkan dulu airnya,&rdquo;
                    ia bergegas menyiapkan air hangat untuk bapaknya.
                  </p>

                  <p>
                    Kemudian ia memapah bapaknya ke kamar mandi yang berada
                    dekat dapur itu. Selesai bapaknya mandi ia menyiapkan
                    makanan.
                  </p>

                  <p>
                    &ldquo;Bapak istirahat saja, kalau butuh apa-apa panggil May
                    saja,&rdquo;
                  </p>

                  <p>
                    &ldquo;Iya Nduk, maaf ya bapak cuma bisa ngrepotin harusnya
                    kamu fokus belajar,&rdquo;
                  </p>

                  <p>
                    &ldquo;Bapak ngomong apa <em>to</em>, nggak ada yang namanya
                    ngrepotin. Sekarang Bapak tidur sudah malam. Obatnya tadi
                    sudah diminumkan?&rdquo;
                  </p>

                  <p>&ldquo;Sudah Nduk kamu juga tidur ya,&rdquo;</p>

                  <p>
                    Bapaknya kemudian melihat May yang tumbuh dengan baik
                    walaupun tanpa ibu disisinya. Ia merasa sedih belum bisa
                    memberikan kehidupan yang layak untuk putrinya itu. Ia
                    mengusap air matanya dan kemudian memejamkan matanya.
                  </p>

                  <p>***</p>

                  <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    Senin telah tiba, May telah berada di sekolah untuk
                    mengikuti upacara. Selesai upacara semua siswa kembali ke
                    kelas untuk mengikuti pembelajaran. Pada saat istirahat ia
                    di panggil oleh Bu Santi.
                  </p>

                  <p>
                    &ldquo;May, dipanggil Bu Santi. Di suruh ke TU,&rdquo; ucap
                    Linda kepada May.
                  </p>

                  <p>&ldquo;Makasih Lin,&rdquo;</p>

                  <p>
                    Kemudian ia bergegas menuju TU untuk menemui Bu Santi. Ia
                    mengetuk pintu dan terdengar sahutan dari dalam, ia masuk
                    dan duduk.
                  </p>

                  <p>
                    &ldquo;Begini May, kamu kan belum bayar SPP 3 bulan
                    sedangkan minggu depan sudah ulangan. Kamu harus melunasi
                    tunggakan kamu dulu biar bisa mengikuti ulangan,&rdquo;
                    terang Bu Santi
                  </p>

                  <p>
                    &ldquo;Baik bu, nanti saya sampaikan kepada Bapak,&rdquo;
                    jawabnya kemudian pamit dan kembali ke kelas.
                  </p>

                  <p>
                    &nbsp;Sepanjang pembelajaran ia terus memikirkan bagaimana
                    mendapatkan uang untuk melunasi tunggakannnya itu. Hingga
                    tak berasa sudah waktunya pulang. Ia tak tega memberitahukan
                    ini kepada Bapaknya,&nbsp; sedangkan bapaknya sedang sakit.
                    Saat perjalanan pulang ia melihat setumpuk kardus dan botol
                    plastik berserakan. Kemudian ia memiliki akal, ia meminta
                    kantong sak kepada Mak Warsinem dan kembali lagi ke tempat
                    kardus itu. Ia mulai memasukkan kardus dan plastik itu
                    kemudian dibawanya&nbsp; ke tempat pengempul rosok.
                    Sesampainya disana, kardus dan botol itu di timbang dan May
                    mendapatkan uang dua puluh ribu. Lumayan untuk melunasi SPP
                    pikirnya. Ia bergegas pulang dan berniat tidak memberitahu
                    bapaknya kalau dia mulung sampah.
                  </p>

                  <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    Esoknya ia bersiap ke sekolah dan tak lupa membawa baju
                    ganti yang akan ia gunakan untuk mulung sampah. Ia kemudian
                    berpamitan kepada Bapaknya. Pulang sekolah ia kembali mulung
                    dan kali ini ia mendapatkan banyak uang. Setelah selesai ia
                    mengganti bajunya&nbsp; dengan baju seragam lagi agar
                    Bapaknya tak curiga. Sesampainya dirumah, bapaknya sudah
                    berada di teras menunggu kedatangan May.
                  </p>

                  <p>
                    &ldquo;Bapak kok nggak istirahat, sudah mendingan ?&rdquo;
                    tanya May.
                  </p>

                  <p>
                    &ldquo;Bapak nggak akan tenang setelah tahu putri Bapak
                    mulung sampah,&rdquo; sarkas Bapaknya
                  </p>

                  <p>May kaget, bagaimana bapaknya tahu kalau ia mulung.</p>

                  <p>
                    &ldquo;Kenapa, uang saku kamu kurang?&rdquo; tanya bapaknya
                  </p>

                  <p>
                    Ia bingung harus menjawab apa. May hanya bisa tertunduk
                    diam.
                  </p>

                  <p>
                    &ldquo;Bapak ndak pernah nyuruh May buat mulung, Bapak ingin
                    May hanya fokus ke sekolahnya May saja,&rdquo;
                  </p>

                  <p>
                    &ldquo;Maafin May Pak, May hanya <em>ndak</em> pengen
                    ngerepotin Bapak. Apalagi Bapak sakit,&rdquo; ucap May
                    sambil menahan isak tangis
                  </p>

                  <p>
                    &ldquo;May sudah ditagih uang SPP pak sama Bu Santi, kalau
                    May ndak bayar May ndak ikut ulangan minggu depan,&rdquo;
                    imbuhnya
                  </p>

                  <p>
                    Setelah mendengar itu, bapaknya langsung berkaca-kaca.
                    Bagaimanapun ia sosok yang rapuh.
                  </p>

                  <p>
                    &ldquo;Kenapa May ndak bilang,&rdquo; kemudian bapaknya
                    masuk kedalam rumah dan diikuti May dibelakangnya. Bapaknya
                    ke kamar dengan jalan yang tertatih, dan menyerahkan
                    beberapa lembar uang.
                  </p>

                  <p>
                    &ldquo;Ini buat bayar SPP kamu, mulai besok nggak usah
                    mulung lagi,&rdquo;
                  </p>

                  <p>
                    Pecahlah tangis May dipelukan Bapaknya itu. Bapaknya memang
                    luar biasa, kasih sayang kepada May tak tertandingi lagi.
                    May tahu, uang itu adalah uang yang seharusnya digunakan
                    untuk membayar utang kepada tetangganya.
                  </p>

                  <p>
                    &ldquo;Sudah, jangan nangis lagi uang bisa di cari lagi.
                    Sana mandi terus makan,&rdquo; ujar Bapaknya.
                  </p>

                  <p>
                    Ia sudah tak sanggup berkata-kata lagi, ia bergegas mandi
                    dan bersih-bersih rumah.
                  </p>

                  <p>***</p>

                  <p>
                    Esok harinya, May bersiap pergi ke sekolah. Bapaknya itu
                    juga bersiap pergi kesawah.
                  </p>

                  <p>
                    &ldquo;Loh Bapak kok sudah pergi ke sawah kan kakinya masih
                    sakit ?&rdquo; tanya May.
                  </p>

                  <p>
                    &ldquo;Nggak apa-apa kok Nduk, sudah mendingan. Sudah sana
                    berangkat jangan khawatirkan Bapak,&rdquo; ucap Bapaknya
                    sambil tersenyum
                  </p>

                  <p>
                    May merasa sangat bersalah kepada bapaknya, ia ingin
                    cepat-cepat lulus dan bekerja untuk membantu bapaknya.
                    Segera ia berangkat menuju sekolahnya.
                  </p>

                  <p>
                    Enam tahun kemudian May telah bekerja di Industri Pangan
                    setelah menempuh pendidikan S1 Agroindustri. Lulus SMA ia
                    mendapatkan beasiswa sehingga ia dapat melanjutkan
                    pendidikannya. Kehidupannya juga mapan, ia telah berhasil
                    membelikan sawah dan rumah untuk bapaknya. Bapaknya juga tak
                    di bolehkan bekerja yang berat mengingat umurnya yang renta.
                    Ia pun berhasil memberangkatkan haji bapaknya, cita-cita
                    bapaknya sejak dulu. Namun, diusia yang ke 61 tahun,
                    bapaknya di panggil oleh Sang Pencipta. Dia merasa sangat
                    terpukul, namun ia kembali ingat bahwa sejatinya setiap
                    manusia akan meninggal.
                  </p>

                  <p>***</p>

                  <p>
                    May menutup album foto bersama almarhum bapaknya. Di usapnya
                    air mata yang turun berjatuhan.
                  </p>

                  <p>
                    &ldquo;Mama kok nangis, kenapa Ma ada yang nyakitin Mama
                    ya?&rdquo; tanya Kia, putri kecilnya May.
                  </p>

                  <p>
                    &ldquo;Enggak kok, Mama cuma kangen sama Eyang Kia,&rdquo;
                    ujarnya
                  </p>

                  <p>
                    &ldquo;Eyang itu, sosok yang sangat sayang kepada Mama dulu.
                    Beliau rela berkorban untuk Mama. Nah Kia, sekarang jangan
                    suka membantah kata Ayah ya. Kasihan Ayah, Ayah bekerja
                    keras untuk Kia dan Mama. Kia sayang nggak sama Ayah?&rdquo;
                  </p>

                  <p>
                    &ldquo;Aku sayang banget sama Ayah Ma,&rdquo;&nbsp; celetuk
                    Kia.
                  </p>

                  <p>
                    &ldquo;Yaudah sekarang kita masak yuk, biar Ayah pulang
                    makanan sudah siap,&rdquo; ajak May.
                  </p>

                  <p>&ldquo;Let&rsquo;s&nbsp; go!&rdquo;</p>

                  <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    Ayah adalah sosok pahlawan tak kenal lelah dalam berjuang
                    untuk anak istrinya di rumah. Teriknya matahari tak
                    dihiarukannya. Ia sosok yang tangguh, walaupun sakit ia
                    tetap bisa tersenyum. Ayah sosok yang luar biasa untuk hidup
                    kita, tanpa Ayah kita bukan siapa-siapa. Terimakasih ayah
                    atas segala pengorbananmu, semoga tenang disana.
                  </p>

                  <p>Salam sayang, Siti Maysaroh.</p>

                  <p><strong>~T A M A T~</strong></p>

                  <p><strong>Biodata Pengarang :</strong></p>

                  <p>
                    Namanya Ika Saputri Ningsih, biasa di panggil Ika, ia
                    tinggal di&nbsp; Pacitan, Jawa Timur. Saat ini duduk di
                    bangku Sekolah Menengah Kejuruan dengan jurusan Akuntansi
                    dan Keuangan Lembaga.
                  </p>

                  <p>&nbsp;</p>

                  <p>&nbsp;</p>
                </div>
              </div>
              <div class="media ava-bloger">
                <img
                  class="mr-3 rounded-circle img-thumbnail"
                  src="https://eurekabookhouse.co.id/assets/uplod/profile/04112020_1586576240.png"
                  alt="Generic placeholder image"
                />
                <div class="media-body text-left">
                  <p class="stext-101 m-b-8">DITULIS OLEH</p>
                  <h5 class="mt-0 mtext-111">Eureka Writer</h5>
                  <p class="badge badge-primary">Content Writer</p>
                  <p class="stext-107">Content Writter eurekabookhouse.co.id</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="sec-relate-product bg6 p-t-20 text-left">
      <div class="container p-tb-10">
        <div class="p-b-15">
          <h3 class="session-title mtext-106 cl5">New Entry</h3>
        </div>
        <div class="row">
          <div class="col-sm-6 col-md-3 p-b-20">
            <div class="blog-item bg0 bor8">
              <div class="blog-pic hov-img0">
                <router-link :to="'/Blog/kisahkasih'">
                  <img
                    class="blog-read-pic"
                    src="https://cdn.eurekabookhouse.co.id/ebh/blog/13-min.jpg"
                  />
                </router-link>
              </div>
              <div class="p-all-15">
                <div class="stext-112 flex-w p-b-14 cl1">
                  <router-link :to="'/Blog/kisahkasih'"
                    >Sahabat Eureka</router-link
                  >
                </div>
                <h4 class="p-b-32">
                  <router-link
                    :to="'/Blog/kisahkasih'"
                    class="mtext-101 cl2 hov-cl1 trans-04"
                  >
                    KISAH KASIH - JUARA FAVORIT 10 LOMBA CERPEN EUREKA BOOKHOUSE
                  </router-link>
                </h4>
                <span class="stext-102 m-t-20">05 September 2022</span>
              </div>
            </div>
          </div>
          <div class="col-sm-6 col-md-3 p-b-20">
            <div class="blog-item bg0 bor8">
              <div class="blog-pic hov-img0">
                <router-link :to="'/Blog/rahasiadelia'">
                  <img
                    class="blog-read-pic"
                    src="https://cdn.eurekabookhouse.co.id/ebh/blog/12-min.jpg"
                  />
                </router-link>
              </div>
              <div class="p-all-15">
                <div class="stext-112 flex-w p-b-14 cl1">
                  <router-link :to="'/Blog/rahasiadelia'"
                    >Sahabat Eureka</router-link
                  >
                </div>
                <h4 class="p-b-32">
                  <router-link
                    :to="'/Blog/rahasiadelia'"
                    class="mtext-101 cl2 hov-cl1 trans-04"
                  >
                    RAHASIA DELIA - JUARA FAVORIT 9 LOMBA CERPEN EUREKA
                    BOOKHOUSE
                  </router-link>
                </h4>
                <span class="stext-102 m-t-20">05 September 2022</span>
              </div>
            </div>
          </div>
          <div class="col-sm-6 col-md-3 p-b-20">
            <div class="blog-item bg0 bor8">
              <div class="blog-pic hov-img0">
                <router-link :to="'/Blog/pohonmangga'">
                  <img
                    class="blog-read-pic"
                    src="https://cdn.eurekabookhouse.co.id/ebh/blog/11-min.jpg"
                  />
                </router-link>
              </div>
              <div class="p-all-15">
                <div class="stext-112 flex-w p-b-14 cl1">
                  <router-link :to="'/Blog/pohonmangga'"
                    >Sahabat Eureka</router-link
                  >
                </div>
                <h4 class="p-b-32">
                  <router-link
                    :to="'/Blog/pohonmangga'"
                    class="mtext-101 cl2 hov-cl1 trans-04"
                  >
                    POHON MANGGA ITU BERBUAH KEMBALI - JUARA FAVORIT 8 LOMBA
                    CERPEN EUREKA BOOKHOUSE
                  </router-link>
                </h4>
                <span class="stext-102 m-t-20">05 September 2022</span>
              </div>
            </div>
          </div>
          <div class="col-sm-6 col-md-3 p-b-20">
            <div class="blog-item bg0 bor8">
              <div class="blog-pic hov-img0">
                <router-link :to="'/Blog/malaikat'">
                  <img
                    class="blog-read-pic"
                    src="https://cdn.eurekabookhouse.co.id/ebh/blog/10-min.jpg"
                  />
                </router-link>
              </div>
              <div class="p-all-15">
                <div class="stext-112 flex-w p-b-14 cl1">
                  <router-link :to="'/Blog/malaikat'"
                    >Sahabat Eureka</router-link
                  >
                </div>
                <h4 class="p-b-32">
                  <router-link
                    :to="'/Blog/malaikat'"
                    class="mtext-101 cl2 hov-cl1 trans-04"
                  >
                    MALAIKAT PENJAGAKU - JUARA FAVORIT 7 LOMBA CERPEN EUREKA
                    BOOKHOUSE
                  </router-link>
                </h4>
                <span class="stext-102 m-t-20">05 September 2022</span>
              </div>
            </div>
          </div>
          <div class="col-sm-6 col-md-3 p-b-20">
            <div class="blog-item bg0 bor8">
              <div class="blog-pic hov-img0">
                <router-link :to="'/Blog/ttgayah'">
                  <img
                    class="blog-read-pic"
                    src="https://cdn.eurekabookhouse.co.id/ebh/blog/9-min.jpg"
                  />
                </router-link>
              </div>
              <div class="p-all-15">
                <div class="stext-112 flex-w p-b-14 cl1">
                  <router-link :to="'/Blog/ttgayah'"
                    >Sahabat Eureka</router-link
                  >
                </div>
                <h4 class="p-b-32">
                  <router-link
                    :to="'/Blog/ttgayah'"
                    class="mtext-101 cl2 hov-cl1 trans-04"
                  >
                    KENANGAN TENTANG AYAH - JUARA FAVORIT 6 LOMBA CERPEN EUREKA
                    BOOKHOUSE
                  </router-link>
                </h4>
                <span class="stext-102 m-t-20">05 September 2022</span>
              </div>
            </div>
          </div>
          <div class="col-sm-6 col-md-3 p-b-20">
            <div class="blog-item bg0 bor8">
              <div class="blog-pic hov-img0">
                <router-link :to="'/Blog/merah'">
                  <img
                    class="blog-read-pic"
                    src="https://cdn.eurekabookhouse.co.id/ebh/blog/8-min.jpg"
                  />
                </router-link>
              </div>
              <div class="p-all-15">
                <div class="stext-112 flex-w p-b-14 cl1">
                  <a
                    href="https://www.eurekabookhouse.co.id/blog/category/sahabat-eureka"
                    >Sahabat Eureka</a
                  >
                </div>
                <h4 class="p-b-32">
                  <router-link
                    :to="'/Blog/merah'"
                    class="mtext-101 cl2 hov-cl1 trans-04"
                  >
                    SEGENGGAM TANAH MERAH - JUARA FAVORIT 5 LOMBA CERPEN EUREKA
                    BOOKHOUSE
                  </router-link>
                </h4>
                <span class="stext-102 m-t-20">05 September 2022</span>
              </div>
            </div>
          </div>
          <div class="col-sm-6 col-md-3 p-b-20">
            <div class="blog-item bg0 bor8">
              <div class="blog-pic hov-img0">
                <router-link :to="'/Blog/jejak'">
                  <img
                    class="blog-read-pic"
                    src="https://cdn.eurekabookhouse.co.id/ebh/blog/7-min.jpg"
                  />
                </router-link>
              </div>
              <div class="p-all-15">
                <div class="stext-112 flex-w p-b-14 cl1">
                  <router-link :to="'/Blog/jejak'">Sahabat Eureka</router-link>
                </div>
                <h4 class="p-b-32">
                  <router-link
                    :to="'/Blog/jejak'"
                    class="mtext-101 cl2 hov-cl1 trans-04"
                  >
                    JEJAK SANDAL AYAH - JUARA FAVORIT 4 LOMBA CERPEN EUREKA
                    BOOKHOUSE
                  </router-link>
                </h4>
                <span class="stext-102 m-t-20">05 September 2022</span>
              </div>
            </div>
          </div>
          <div class="col-sm-6 col-md-3 p-b-20">
            <div class="blog-item bg0 bor8">
              <div class="blog-pic hov-img0">
                <router-link :to="'/Blog/pejuang'">
                  <img
                    class="blog-read-pic"
                    src="https://cdn.eurekabookhouse.co.id/ebh/blog/6-min.jpg"
                  />
                </router-link>
              </div>
              <div class="p-all-15">
                <div class="stext-112 flex-w p-b-14 cl1">
                  <router-link :to="'/Blog/pejuang'">Acara Toko</router-link>
                </div>
                <h4 class="p-b-32">
                  <router-link
                    :to="'/Blog/pejuang'"
                    class="mtext-101 cl2 hov-cl1 trans-04"
                  >
                    PERJUANGAN AYAHKU, UNTUK MASA DEPANKU - JUARA FAVORIT 3
                    LOMBA CERPEN EUREKA BOOKHOUSE
                  </router-link>
                </h4>
                <span class="stext-102 m-t-20">04 September 2022</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script scope>
import Navbar from "../Nav.vue";
export default {
  name: "kisahkasihPage",
  components: {
    Navbar,
  },
};
</script>

<template>
  <div class="bg-gray-100 min-h-screen">
    <!-- Navbar Component -->
    <Navbar />

    <section class="bg0 p-b-20 text-left">
      <div class="container p-lr-80-lg">
        <div class="row">
          <div class="col-md-1"></div>
          <div class="col-md-10">
            <h1 class="ltext-102 cl2 p-t-28">
              AYAH, YOU ARE MY HERO - JUARA FAVORIT 1 LOMBA CERPEN
            </h1>
            <div class="flex-m flex-sb m-tb-15">
              <div class="flex-i flex-w flex-m stext-111 cl2">
                <span>
                  <span class="cl4">Oleh</span> Eureka
                  <span class="cl12 m-l-4 m-r-6">|</span>
                </span>
                <span>
                  14 Nov, 2022 <span class="cl12 m-l-4 m-r-6">|</span>
                </span>
                <div id="share" class="m-l-50-lg">
                  <a
                    class="fs-14 badge p-all-5 hov-cl0 facebook customer share"
                    href="https://www.facebook.com/sharer.php?u=https://www.eurekabookhouse.co.id/blog/read/ayah--you-are-my-hero---juara-favorit-1-lomba-cerpen"
                    target="_blank"
                    ><i class="fa fa-facebook"></i
                  ></a>
                  <a
                    class="fs-14 badge p-all-5 hov-cl0 twitter customer share"
                    href="http://twitter.com/share?url=https://www.eurekabookhouse.co.id/blog/read/ayah--you-are-my-hero---juara-favorit-1-lomba-cerpen&amp;text=Blog: AYAH, YOU ARE MY HERO - JUARA FAVORIT 1 LOMBA CERPEN &amp;hashtags=eurekabookhouse"
                    title="Twitter share"
                    target="_blank"
                    ><i class="fa fa-twitter"></i
                  ></a>
                  <a
                    class="fs-14 badge p-all-5 hov-cl0 googleplus customer share"
                    href="https://plus.google.com/share?url=https://www.eurekabookhouse.co.id/blog/read/ayah--you-are-my-hero---juara-favorit-1-lomba-cerpen"
                    title="Google Plus Share"
                    target="_blank"
                    ><i class="fa fa-google-plus"></i
                  ></a>
                </div>
              </div>
              <div class="flex-r-m">
                <span class="stext-107 text-center"
                  ><i class="fa fa-eye"></i> 1273</span
                >
              </div>
            </div>
            <div class="wrap-pic-w how-pos5-parent">
              <img
                src="https://cdn.eurekabookhouse.co.id/ebh/blog/3-min.jpg"
                alt="IMG-BLOG"
                class="blog-read-pic"
              />
              <div class="flex-col-c-m size-123 bg9 how-pos5">
                <span class="ltext-107 cl2 txt-center"> 14 </span>

                <span class="stext-109 cl3 txt-center"> 11 2022 </span>
              </div>
            </div>
          </div>
          <div class="col-md-1"></div>
        </div>
        <div class="row p-t-35 p-b-80">
          <div class="col-lg-2"></div>
          <div class="col-md-8 col-lg-8">
            <div class="p-r-0-lg">
              <div>
                <div class="isi_blog ctext-101 fs-18 cl2 p-b-26">
                  <p>
                    Maura Mayang Lestari, nama yang sangat indah. Nama pemberian
                    dari sang ibu yang kini sudah tiada. Maura duduk di kursi
                    rodanya sambil menunggu sang ayah yang akan menjemputnya. Di
                    sela-sela menunggu ayahnya, Maura memainkan gantungan tasnya
                    yang berbentuk boneka beruang hadiah ulang tahun dari
                    Keilana.
                  </p>

                  <p>
                    &ldquo;Maura.&rdquo; Maura tersenyum lebar melihat ayahnya
                    yang kini berjalan ke arahnya. Pria dengan setelan jas
                    dokter itu mendekat ke arah Maura. Mengecup puncak kepala
                    Maura dengan sayang,
                  </p>

                  <p>&ldquo;Ayah.&rdquo;</p>

                  <p>&ldquo;Maaf ya, Ayah lama,&rdquo; ujar Putra.</p>

                  <p>
                    Maura menggelengkan kepalanya, &ldquo;Gak apa-apa, kan, Ayah
                    kerja. Tadi Maura di temanin Kei.&rdquo; Putra menoleh ke
                    kiri dan kanan, mencari sosok yang dibicarakan putrinya.
                  </p>

                  <p>
                    &ldquo;Kei ada latihan basket jadi gak bisa nemenin Maura
                    terus, Yah,&rdquo; jelas Maura. Gadis berseragam putih biru
                    itu seolah tahu apa yang tengah dipikirkan sang ayah.
                  </p>

                  <p>
                    &ldquo;Iya Sayang. Ayah nyari Kei juga buat ngucapin terima
                    kasih udah nemenin putri Ayah yang cantik ini,&rdquo; ucap
                    Putra, telapak tangannya yang besar mengusap puncak kepala
                    Maura. &ldquo;Ayo, kita pulang.&rdquo; Putra segera
                    mendorong kursi roda putrinya. Sejak kematian sang istri,
                    Putra berjuang keras untuk membesarkan Maura seorang diri.
                    Tidak mudah baginya untuk tetap kuat, bayang-bayang sang
                    istri sampai detik ini selalu memenuhi kepala dan hatinya.
                    Meskipun kepergian wanita yang dia cintai sudah
                    bertahun-tahun lamanya.
                  </p>

                  <p>
                    Putra menggendong Maura dan mendudukkan di kursi penumpang,
                    dia lalu melipat kursi roda Maura dan meletakannya di
                    bagasi.
                  </p>

                  <p>
                    &ldquo;Udah siap?&rdquo; tanya Putra begitu dia duduk. Maura
                    mengangguk. Sabuk pengamannya sudah terpasang dengan baik.
                    Mobil yang dikendarai Putra mulai ke luar dari halaman
                    sekolah. Membelah jalanan kota Jakarta yang begitu ramai
                    dengan berbagai macam kendaraan berlalu lalang.
                  </p>

                  <p>&ldquo;Ayah,&rdquo; panggil Maura.</p>

                  <p>
                    &ldquo;Iya,&rdquo; Putra menyahut pelan, tatapannya tetap
                    fokus pada jalanan di depannya.
                  </p>

                  <p>&ldquo;Besok di sekolah ada lomba melukis.&rdquo;</p>

                  <p>&ldquo;Maura ikut?&rdquo; tanya Putra.</p>

                  <p>&ldquo;Iya. Ayah besok datang, kan?&rdquo;</p>

                  <p>
                    &ldquo;Tentu Sayang. Ayah pasti datang. Jam berapa?&rdquo;
                  </p>

                  <p>&ldquo;Jam sepuluh.&rdquo;</p>

                  <p>&ldquo;Oke. Ayah pasti datang.&rdquo;</p>

                  <p>
                    Putra tidak pernah berkata tidak untuk Maura, baginya, jika
                    bisa memberikan nyawanya untuk Maura, Putra pun akan
                    melakukan hal itu. Maura sudah seperti napasnya. Maura
                    adalah harta berharganya bersama Alivia. Putra boleh saja
                    kehilangan Alvia, boleh saja dia gagal menyelamatkan Alivia
                    di meja operasi. Kesalahannya yang tidak pernah dia lupakan
                    sepanjang hidupnya.
                  </p>

                  <p>
                    Sepanjang perjalanan menuju rumah, Putra ditemani suara
                    dengkuran halus milik Maura. Gadis berusia lima belas tahun
                    itu tertidur dengan lelap. Ketika lampu merah menyala, Putra
                    menoleh ke samping, menatap Maura dengan senyum sendu. Putra
                    mengulurkan tangannya untuk mengusap rambut sang putri,
                    rambut yang sama persis seperti milik istrinya.
                  </p>

                  <p>
                    &ldquo;Vi, Maura tumbuh dengan baik. Semakin hari dia
                    semakin mirip kamu, Vi,&rdquo; lirih Putra. Apa yang
                    dikatakan Putra adalah benar. Maura bagaikan pinang dibelah
                    dua dengan sang ibu. Semua orang yang berada di dekat Putra
                    selalu mengatakan hal yang sama. Bahkan, Alvian yang
                    merupakan kakak sepupu Alivia juga berkata demikian.
                  </p>

                  <p>
                    &ldquo;Vi, anak kita sudah tumbuh dewasa. Aku berharap, kamu
                    bisa melihatnya dari sana.&rdquo; Tanpa Putra sadari semua
                    ucapannya di dengar oleh Maura. Gadis itu sudah terbangun
                    ketika merasakan usapan halus di puncak kepalanya. Namun,
                    Maura memilih pura-pura tertidur. Dia tidak ingin ayahnya
                    tahu. Akan tetapi, semua itu tidak berlangsung lama. Maura
                    tidak bisa terus berpura-pura tertidur, di saat dia
                    mendengar isakan kecil dari bibir ayahnya. Seketika matanya
                    terbuka.
                  </p>

                  <p>
                    &ldquo;Ayah,&rdquo; panggil Maura, &ldquo;jangan nangis.
                    Bunda pasti sedih kalau tahu Ayah nangis.&rdquo; Putra tidak
                    bisa menyembunyikan tangisnya, meski dia memaksakan untuk
                    tersenyum sekalipun.
                  </p>

                  <p>
                    &ldquo;Ayah, Maura sayang Ayah. Ayang jangan nangis
                    lagi,&rdquo; pinta Maura. Gadis itu mengusap air mata Putra
                    dengan lembut. Putra tidak tahu apa yang membuat dia tidak
                    bisa menghentikan air matanya. Padahal, dia sudah berjanji
                    untuk tidak menangis di depan Maura. Dia tidak ingin
                    putrinya tahu bahwa dia lemah. Dia ingin Maura selalu
                    melihatnya tersenyum, kuat dan berani.
                  </p>

                  <p>
                    &ldquo;Maaf Sayang. Maafin Ayah,&rdquo; ucap Putra. Dengan
                    segera dia menarik Maura dalam pelukannya.
                  </p>

                  <p>
                    &ldquo;Maafkan Ayah yang tidak bisa memberikan sosok Bunda
                    untuk Maura. Maafkan Ayah yang belum bisa jadi Ayah yang
                    baik untuk Maura. Maafkan Ayah yang tidak bisa selalu ada di
                    samping Maura. Maafkan Ayah, Maura.&rdquo;
                  </p>

                  <p>
                    Maura menggeleng dalam pelukan ayahnya, dia berusaha
                    membantah semua yang dikatakan Puta.
                  </p>

                  <p>
                    &ldquo;Ayah udah jadi Ayah yang hebat buat Maura. Ayah
                    selalu ada buat Maura. Maura juga gak butuh sosok Bunda
                    lagi,&rdquo; ujar Maura dengan suara seraknya.
                  </p>

                  <p>
                    <em
                      >Bunda, terima kasih sudah memberikan Maura seorang Ayah
                      yang hebat,</em
                    >
                    batin Maura.
                  </p>

                  <p>&nbsp;</p>

                  <p>Penulis: <strong>LISA RIYANA</strong></p>
                </div>
              </div>
              <div class="media ava-bloger">
                <img
                  class="mr-3 rounded-circle img-thumbnail"
                  src="https://eurekabookhouse.co.id/assets/uplod/profile/04112020_1586576240.png"
                  alt="Generic placeholder image"
                />
                <div class="media-body">
                  <p class="stext-101 m-b-8">DITULIS OLEH</p>
                  <h5 class="mt-0 mtext-111">Eureka Writer</h5>
                  <p class="badge badge-primary">Content Writer</p>
                  <p class="stext-107">Content Writter eurekabookhouse.co.id</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="sec-relate-product bg6 p-t-20 text-left">
      <div class="container p-tb-10">
        <div class="p-b-15">
          <h3 class="session-title mtext-106 cl5">New Entry</h3>
        </div>
        <div class="row">
          <div class="col-sm-6 col-md-3 p-b-20">
            <div class="blog-item bg0 bor8">
              <div class="blog-pic hov-img0">
                <a
                  href="https://www.eurekabookhouse.co.id/blog/read/kisah-kasih---juara-favorit-10-lomba-cerpen-eureka-bookhouse"
                >
                  <img
                    class="blog-read-pic"
                    src="https://cdn.eurekabookhouse.co.id/ebh/blog/13-min.jpg"
                  />
                </a>
              </div>
              <div class="p-all-15">
                <div class="stext-112 flex-w p-b-14 cl1">
                  <a
                    href="https://www.eurekabookhouse.co.id/blog/category/sahabat-eureka"
                    >Sahabat Eureka</a
                  >
                </div>
                <h4 class="p-b-32">
                  <a
                    href="https://www.eurekabookhouse.co.id/blog/read/kisah-kasih---juara-favorit-10-lomba-cerpen-eureka-bookhouse"
                    class="mtext-101 cl2 hov-cl1 trans-04"
                  >
                    KISAH KASIH - JUARA FAVORIT 10 LOMBA CERPEN EUREKA BOOKHOUSE
                  </a>
                </h4>
                <span class="stext-102 m-t-20">14 November 2022</span>
              </div>
            </div>
          </div>
          <div class="col-sm-6 col-md-3 p-b-20">
            <div class="blog-item bg0 bor8">
              <div class="blog-pic hov-img0">
                <a
                  href="https://www.eurekabookhouse.co.id/blog/read/rahasia-delia---juara-favorit-9-lomba-cerpen-eureka-bookhouse"
                >
                  <img
                    class="blog-read-pic"
                    src="https://cdn.eurekabookhouse.co.id/ebh/blog/12-min.jpg"
                  />
                </a>
              </div>
              <div class="p-all-15">
                <div class="stext-112 flex-w p-b-14 cl1">
                  <a
                    href="https://www.eurekabookhouse.co.id/blog/category/sahabat-eureka"
                    >Sahabat Eureka</a
                  >
                </div>
                <h4 class="p-b-32">
                  <a
                    href="https://www.eurekabookhouse.co.id/blog/read/rahasia-delia---juara-favorit-9-lomba-cerpen-eureka-bookhouse"
                    class="mtext-101 cl2 hov-cl1 trans-04"
                  >
                    RAHASIA DELIA - JUARA FAVORIT 9 LOMBA CERPEN EUREKA
                    BOOKHOUSE
                  </a>
                </h4>
                <span class="stext-102 m-t-20">14 November 2022</span>
              </div>
            </div>
          </div>
          <div class="col-sm-6 col-md-3 p-b-20">
            <div class="blog-item bg0 bor8">
              <div class="blog-pic hov-img0">
                <a
                  href="https://www.eurekabookhouse.co.id/blog/read/pohon-mangga-itu-berbuah-kembali---juara-favorit-8-lomba-cerpen-eureka-bookhouse"
                >
                  <img
                    class="blog-read-pic"
                    src="https://cdn.eurekabookhouse.co.id/ebh/blog/11-min.jpg"
                  />
                </a>
              </div>
              <div class="p-all-15">
                <div class="stext-112 flex-w p-b-14 cl1">
                  <a
                    href="https://www.eurekabookhouse.co.id/blog/category/sahabat-eureka"
                    >Sahabat Eureka</a
                  >
                </div>
                <h4 class="p-b-32">
                  <a
                    href="https://www.eurekabookhouse.co.id/blog/read/pohon-mangga-itu-berbuah-kembali---juara-favorit-8-lomba-cerpen-eureka-bookhouse"
                    class="mtext-101 cl2 hov-cl1 trans-04"
                  >
                    POHON MANGGA ITU BERBUAH KEMBALI - JUARA FAVORIT 8 LOMBA
                    CERPEN EUREKA BOOKHOUSE
                  </a>
                </h4>
                <span class="stext-102 m-t-20">14 November 2022</span>
              </div>
            </div>
          </div>
          <div class="col-sm-6 col-md-3 p-b-20">
            <div class="blog-item bg0 bor8">
              <div class="blog-pic hov-img0">
                <a
                  href="https://www.eurekabookhouse.co.id/blog/read/malaikat-penjagaku---juara-favorit-7-lomba-cerpen-eureka-bookhouse"
                >
                  <img
                    class="blog-read-pic"
                    src="https://cdn.eurekabookhouse.co.id/ebh/blog/10-min.jpg"
                  />
                </a>
              </div>
              <div class="p-all-15">
                <div class="stext-112 flex-w p-b-14 cl1">
                  <a
                    href="https://www.eurekabookhouse.co.id/blog/category/sahabat-eureka"
                    >Sahabat Eureka</a
                  >
                </div>
                <h4 class="p-b-32">
                  <a
                    href="https://www.eurekabookhouse.co.id/blog/read/malaikat-penjagaku---juara-favorit-7-lomba-cerpen-eureka-bookhouse"
                    class="mtext-101 cl2 hov-cl1 trans-04"
                  >
                    MALAIKAT PENJAGAKU - JUARA FAVORIT 7 LOMBA CERPEN EUREKA
                    BOOKHOUSE
                  </a>
                </h4>
                <span class="stext-102 m-t-20">14 November 2022</span>
              </div>
            </div>
          </div>
          <div class="col-sm-6 col-md-3 p-b-20">
            <div class="blog-item bg0 bor8">
              <div class="blog-pic hov-img0">
                <a
                  href="https://www.eurekabookhouse.co.id/blog/read/kenangan-tentang-ayah---juara-favorit-6-lomba-cerpen-eureka-bookhouse"
                >
                  <img
                    class="blog-read-pic"
                    src="https://cdn.eurekabookhouse.co.id/ebh/blog/9-min.jpg"
                  />
                </a>
              </div>
              <div class="p-all-15">
                <div class="stext-112 flex-w p-b-14 cl1">
                  <a
                    href="https://www.eurekabookhouse.co.id/blog/category/sahabat-eureka"
                    >Sahabat Eureka</a
                  >
                </div>
                <h4 class="p-b-32">
                  <a
                    href="https://www.eurekabookhouse.co.id/blog/read/kenangan-tentang-ayah---juara-favorit-6-lomba-cerpen-eureka-bookhouse"
                    class="mtext-101 cl2 hov-cl1 trans-04"
                  >
                    KENANGAN TENTANG AYAH - JUARA FAVORIT 6 LOMBA CERPEN EUREKA
                    BOOKHOUSE
                  </a>
                </h4>
                <span class="stext-102 m-t-20">14 November 2022</span>
              </div>
            </div>
          </div>
          <div class="col-sm-6 col-md-3 p-b-20">
            <div class="blog-item bg0 bor8">
              <div class="blog-pic hov-img0">
                <a
                  href="https://www.eurekabookhouse.co.id/blog/read/segenggam-tanah-merah---juara-favorit-5-lomba-cerpen-eureka-bookhouse"
                >
                  <img
                    class="blog-read-pic"
                    src="https://cdn.eurekabookhouse.co.id/ebh/blog/8-min.jpg"
                  />
                </a>
              </div>
              <div class="p-all-15">
                <div class="stext-112 flex-w p-b-14 cl1">
                  <a
                    href="https://www.eurekabookhouse.co.id/blog/category/sahabat-eureka"
                    >Sahabat Eureka</a
                  >
                </div>
                <h4 class="p-b-32">
                  <a
                    href="https://www.eurekabookhouse.co.id/blog/read/segenggam-tanah-merah---juara-favorit-5-lomba-cerpen-eureka-bookhouse"
                    class="mtext-101 cl2 hov-cl1 trans-04"
                  >
                    SEGENGGAM TANAH MERAH - JUARA FAVORIT 5 LOMBA CERPEN EUREKA
                    BOOKHOUSE
                  </a>
                </h4>
                <span class="stext-102 m-t-20">14 November 2022</span>
              </div>
            </div>
          </div>
          <div class="col-sm-6 col-md-3 p-b-20">
            <div class="blog-item bg0 bor8">
              <div class="blog-pic hov-img0">
                <a
                  href="https://www.eurekabookhouse.co.id/blog/read/jejak-sandal-ayah---juara-favorit-4-lomba-cerpen-eureka-bookhouse"
                >
                  <img
                    class="blog-read-pic"
                    src="https://cdn.eurekabookhouse.co.id/ebh/blog/7-min.jpg"
                  />
                </a>
              </div>
              <div class="p-all-15">
                <div class="stext-112 flex-w p-b-14 cl1">
                  <a
                    href="https://www.eurekabookhouse.co.id/blog/category/sahabat-eureka"
                    >Sahabat Eureka</a
                  >
                </div>
                <h4 class="p-b-32">
                  <a
                    href="https://www.eurekabookhouse.co.id/blog/read/jejak-sandal-ayah---juara-favorit-4-lomba-cerpen-eureka-bookhouse"
                    class="mtext-101 cl2 hov-cl1 trans-04"
                  >
                    JEJAK SANDAL AYAH - JUARA FAVORIT 4 LOMBA CERPEN EUREKA
                    BOOKHOUSE
                  </a>
                </h4>
                <span class="stext-102 m-t-20">14 November 2022</span>
              </div>
            </div>
          </div>
          <div class="col-sm-6 col-md-3 p-b-20">
            <div class="blog-item bg0 bor8">
              <div class="blog-pic hov-img0">
                <a
                  href="https://www.eurekabookhouse.co.id/blog/read/perjuangan-ayahku--untuk-masa-depanku---juara-favorit-3-lomba-cerpen-eureka-bookhouse"
                >
                  <img
                    class="blog-read-pic"
                    src="https://cdn.eurekabookhouse.co.id/ebh/blog/6-min.jpg"
                  />
                </a>
              </div>
              <div class="p-all-15">
                <div class="stext-112 flex-w p-b-14 cl1">
                  <a
                    href="https://www.eurekabookhouse.co.id/blog/category/acara-toko"
                    >Acara Toko</a
                  >
                </div>
                <h4 class="p-b-32">
                  <a
                    href="https://www.eurekabookhouse.co.id/blog/read/perjuangan-ayahku--untuk-masa-depanku---juara-favorit-3-lomba-cerpen-eureka-bookhouse"
                    class="mtext-101 cl2 hov-cl1 trans-04"
                  >
                    PERJUANGAN AYAHKU, UNTUK MASA DEPANKU - JUARA FAVORIT 3
                    LOMBA CERPEN EUREKA BOOKHOUSE
                  </a>
                </h4>
                <span class="stext-102 m-t-20">14 November 2022</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- Footer -->
    <footer class="bgu1 p-t-15 p-b-15">
      <div class="container">
        <div class="p-t-10">
          <p class="stext-107 cl0 txt-center">
            Copyright &copy; Dikembangkan oleh Eureka IT Developer
          </p>
        </div>
      </div>
    </footer>
  </div>
</template>
<script scoped>
import Navbar from "../Nav.vue";
export default {
  name: "heroPage",
  components: {
    Navbar,
  },
};
</script>

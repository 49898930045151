<template>
  <div class="bg-gray-100 min-h-screen">
    <!-- Navbar Component -->
    <Navbar />

    <section class="bg0 p-b-20 text-left">
      <div class="container p-lr-80-lg">
        <div class="row">
          <div class="col-md-1"></div>
          <div class="col-md-10">
            <h1 class="ltext-102 cl2 p-t-28">
              KAU, AYAHKU - JUARA 3 LOMBA CERPEN EUREKA BOOKHOUSE
            </h1>
            <div class="flex-m flex-sb m-tb-15">
              <div class="flex-i flex-w flex-m stext-111 cl2">
                <span>
                  <span class="cl4">Oleh</span> Eureka
                  <span class="cl12 m-l-4 m-r-6">|</span>
                </span>
                <span>
                  14 Nov, 2022 <span class="cl12 m-l-4 m-r-6">|</span>
                </span>
                <div id="share" class="m-l-50-lg">
                  <a
                    class="fs-14 badge p-all-5 hov-cl0 facebook customer share"
                    href="https://www.facebook.com/sharer.php?u=https://www.eurekabookhouse.co.id/blog/read/kau--ayahku---juara-3-lomba-cerpen-eureka-bookhouse"
                    target="_blank"
                    ><i class="fa fa-facebook"></i
                  ></a>
                  <a
                    class="fs-14 badge p-all-5 hov-cl0 twitter customer share"
                    href="http://twitter.com/share?url=https://www.eurekabookhouse.co.id/blog/read/kau--ayahku---juara-3-lomba-cerpen-eureka-bookhouse&amp;text=Blog: KAU, AYAHKU - JUARA 3 LOMBA CERPEN EUREKA BOOKHOUSE &amp;hashtags=eurekabookhouse"
                    title="Twitter share"
                    target="_blank"
                    ><i class="fa fa-twitter"></i
                  ></a>
                  <a
                    class="fs-14 badge p-all-5 hov-cl0 googleplus customer share"
                    href="https://plus.google.com/share?url=https://www.eurekabookhouse.co.id/blog/read/kau--ayahku---juara-3-lomba-cerpen-eureka-bookhouse"
                    title="Google Plus Share"
                    target="_blank"
                    ><i class="fa fa-google-plus"></i
                  ></a>
                </div>
              </div>
              <div class="flex-r-m">
                <span class="stext-107 text-center"
                  ><i class="fa fa-eye"></i> 1111</span
                >
              </div>
            </div>
            <div class="wrap-pic-w how-pos5-parent">
              <img
                src="https://cdn.eurekabookhouse.co.id/ebh/blog/4-min.jpg"
                alt="IMG-BLOG"
                class="blog-read-pic"
              />
              <div class="flex-col-c-m size-123 bg9 how-pos5">
                <span class="ltext-107 cl2 txt-center"> 14 </span>

                <span class="stext-109 cl3 txt-center"> 11 2022 </span>
              </div>
            </div>
          </div>
          <div class="col-md-1"></div>
        </div>
        <div class="row p-t-35 p-b-80">
          <div class="col-lg-2"></div>
          <div class="col-md-8 col-lg-8">
            <div class="p-r-0-lg">
              <div>
                <div class="isi_blog ctext-101 fs-18 cl2 p-b-26">
                  <p>
                    Malam itu, dimana tak seorang pun lewat di Gang Gelap,
                    Mulyatun Nisa seorang diri, melewati gang itu. Udara pengap
                    bercampur dengan aroma kuat <em>beer</em> di setiap sudut
                    gang. Suara tawa para pria berhidung belang terdengar
                    menggelegar diiringi desah para pelacur dari rumah-rumah
                    bordil di Gang Gelap. Mulyah, begitu dia biasa dipanggil,
                    menunduk sedalam dalamnya, bahkan ujung kerudungnya ia
                    gunakan sebagai penutup mata.
                  </p>

                  <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    Setiap langkahnya dihantui was-was. Tepat di mulut Gang
                    Gelap, Mulyah menarik napas lega, tak ada yang perlu
                    dikhawatirkan. Tiba-tiba terdengar suara tawa dari samping
                    Mulyah, dia menoleh, tampak seorang pria tengah menggoda
                    seorang <em>waria</em>. Tangan nakal nya menyentuh setiap
                    jengkal tubuh <em>waria</em> itu dengan tatapan bernafsu,
                    seakan tak sadar bahwa yang ia tengah gauli sekarang adalah
                    sesamanya.
                  </p>

                  <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    Mulyah beistghfar, kedua orang yang tengah bersama itu pun
                    menoleh kepadanya, Mulyah terlalu keras berbicara. Si pria
                    hidung belang itu pun menghampirinya, menarik kerudungnya
                    dan melemparkannya ke sembarang arah. Mulyah yang terkejut
                    langsung terduduk dan hendak mengambil kerudungnya. Tak
                    disangka, si pria hidung belang itu meninju kepalanya, dan
                    membopongnya.
                  </p>

                  <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <em>Waria</em> yang tadi pun mengkuti si pria hidung belang.
                    Lompat kedepan si pria dan menendang kelamin si pria. Si
                    pria yang kesakitan menjatuhkan Mulyah, dengan cekatan waria
                    itu menangkap tubuh mungil Mulyah, membawanya kabur, menjauh
                    dari si pria hidung belang.
                  </p>

                  <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    Mulyah bangun dari pingsannya, menatap langit-langit kumuh
                    yang tak dikenalnya, dia menatap sekeliling dengan
                    penglihatan yang masih berkunang-kunang. Seseorang tampak
                    sedang berkaca sambil menghapus riasannya.
                    <em>Waria dari Gang Gelap itu </em>Mulyah mengingatnya.
                    Mulyah pun terduduk dan memerhatikan waria itu. Sadar
                    diperhatikan, <em>waria</em> itu lantas menyapa Mulyah,
                    bertanya kabarnya.
                  </p>

                  <p>&nbsp;</p>

                  <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    Mulyah pun terjebak pembicaraan dengan <em>waria</em> yang
                    sudah menghapus semua riasannya. Tak ada lagi
                    <em>wig blonde</em> mengombak, <em>make up</em> tebal,
                    anting-anting dan segala rupa. Wajah <em>waria</em> itu
                    berubah total, sama sekali tak tampak bahwa baru saja dia
                    menggoda para lelaki. Mulyah berkata bahwa dia berasal dari
                    selatan kota, dia anak pemilik salah satu yayasan pendidikan
                    disana. Kontras dengan Mulyah, <em>waria</em> itu berasal
                    dari pedesaan kumuh, dan dia hidup sebatang kara sama sekali
                    tanpa ada saudara. Tetapi, dia hidup bersama anak-anak
                    terlantar yang lain, dan dia adalah yang tertua diantara
                    mereka. Nama <em>waria</em> itu, Jovi Indara. Cukup maskulin
                    untuk nama seorang lelaki yang berakhir menjadi
                    <em>waria</em>.
                  </p>

                  <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &ldquo;Sudah larut neng, mau saya antar pulang?&rdquo; Tanya
                    Indara.
                  </p>

                  <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    Mulyah mengangguk, tak ada pilihan selain menerima tawaran
                    menarik itu. Lagi pula Indara juga tidak akan berbuat
                    macam-macam, tidak ada yang perlu Mulyah khawatirkan.
                  </p>

                  <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    Deru sepeda motor butut Indara memecah hening malam. Tak ada
                    pembicaraan, hanya lengang diantara mereka berdua.
                  </p>

                  <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &ldquo;Neng, enak ya punya keluarga? Kadang saya iri dengan
                    orang-orang.&rdquo; Akhirnya Indara buka suara.
                  </p>

                  <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &ldquo;Semua itu sudah ada porsinya masing-masing Kang.
                    Semua manusia punya enak masing-masing di hidupnya.&rdquo;
                  </p>

                  <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    Indara mengangguk.
                  </p>

                  <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &ldquo;Maaf kalau menyinggung, tapi Kang Indara ini mengapa
                    bekerja sebagai waria? Padahal saya lihat-lihat Kang Indara
                    ini sama sekali ndak <em>ngondek</em>.&rdquo;
                  </p>

                  <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    Indara menghentikan laju motornya. Menyuruh Mulyah untuk
                    turun. Mereka pun duduk di bahu jalan, saling terdiam.
                  </p>

                  <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &ldquo;Aku memang tak punya keluarga Neng, tapi aku punya
                    teman-teman kecil yang sudah kuanggap adik, aku bertanggung
                    jawab atas mereka. Dan kenapa aku memilih perkerjaan hina
                    ini adalah bukan karena aku kelainan, aku masih normal.
                    Tetapi, aku tak punya kemampuan apapun, aku tak pernah
                    bersekolah, aku hidup dengan belas kasih orang, aku pernah
                    diasuh oleh sekelompok pemain lenong, disana, aku diajari
                    bermain peran meski hanya sebentar, karena mereka bilang
                    melihat bakat alamiku.&rdquo;
                  </p>

                  <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &ldquo;Karena mereka pemain lenong yang singgah dari tempat
                    ke tempat, mereka akhirnya pergi, dan aku tetap tinggal
                    karena aku tak bisa meninggalkan teman-teman kecilku. Aku
                    memutar otak, dan akhirnya aku menemukan Gang Gelap itu. Dan
                    perlu kau tau Neng, aku hanya bermain peran. Gerak tubuh,
                    ekspresi, suara, nada bicara bahkan riasan aku kuasai semua
                    sebagai pemain lenong. Hanya dengan aku bermain peran
                    seperti wanita ketka para lelaki itu menyentuhku, aku sudah
                    mendapat banyak sekali uang.&rdquo;
                  </p>

                  <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    Mulyah mengangguk mantap, dia terkesan.
                  </p>

                  <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &ldquo;Tapi Neng, jujur aku ingin sekali keluar dari
                    pekerjaan hina ini. Tapi bagaimana dengan teman-teman
                    kecilku? Apalagi dengan Abeng, dia yang paling tua diantara
                    yang lain, dia juga cerdas, sayang kalau dia terus
                    terlantar, aku ingin dia sekolah.&rdquo;
                  </p>

                  <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &ldquo;Besok, datanglah ke rumahku, aku akan ajak kau
                    bertemu ayahku.&rdquo; Mulyah tersenyum.
                  </p>

                  <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    Mereka kembali melanjutkan perjalanan, dan Mulyah akhirnya
                    tiba di rumahnya. Dia turun dari motor Indara,
                    berterimakasih, lalu masuk ke rumahnya. Indara masih terdiam
                    di depan rumah Mulyah, terkagum-kagum betapa besar rumah
                    Mulyah sampai dia sadar bahwa tengah malam sudah datang.
                  </p>

                  <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    Esok hari, Indara benar-benar datang kembali, Mulyah
                    menyambutnya. Indara bertemu ayah Mulyah, lelaki paruh baya
                    dengan wajah pemarah, lalu ibu Mulyah, wajahnya sangat
                    damai, persis seperti Mulyah. Dan terakhir, kakak laki-laki
                    Mulyah, Raden Aziz Perwira.
                  </p>

                  <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &ldquo;Jadi ini yang kau suruh bapak sekolahkan? Dia tampak
                    cerdas memang, sorot matanya tajam.&rdquo;
                  </p>

                  <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    Ayah Mulyah, Raden Ilham Wicaksono mudah menilai orang.
                    Indara tampak senang mendengar ayah Mulyah bicara begitu.
                  </p>

                  <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &ldquo;Baik tak apa-apa, dia akan langsung ikut
                    penyetaraan.&rdquo;
                  </p>

                  <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    Mulyah tersenyum, Indara mengerutkan dahinya.
                  </p>

                  <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &ldquo;Apa boleh kalau teman-teman kecil saya ikut? Mereka
                    semua cepat belajar. Dan tak mungkin juga mereka akan saya
                    tinggalkan Raden.&rdquo; Takut-takut Indara meminta.
                  </p>

                  <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &ldquo;Kau bercanda Indara? Mereka ada berapa orang?&rdquo;
                  </p>

                  <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &ldquo;Empat Raden.&rdquo; Indara tampak ciut.
                  </p>

                  <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &ldquo;Tentu saja Indara, hanya empat. Sepuluh orang pun aku
                    sanggup biayai hidup mereka. Kau telah menyelamatkan
                    putriku. Besok datangkan mereka.&rdquo;
                  </p>

                  <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    Indara bersungut-sungut senang, tersenyum lepas, begitu
                    tampan dan dari sisi lain, Mulyah yang melihat itu, dia
                    berdesir.
                  </p>

                  <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    Waktu telah banyak terlewati. Indara dan empat teman
                    kecilnya sudah tiga tahun ikut penyetaraan. Indara yang
                    cerdas mampu menyelesaikan kurikulum formal dua belas tahun
                    hanya dalam tiga tahun sekolahnya. Indara masuk perkuliahan
                    terbaik sekarang, bersama Mulyah.
                  </p>

                  <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    Dari awal, mereka sudah saling mencintai, bahkan Indara
                    telah mengagumi Mulyah sejak pertama kali bertemu. Dia
                    mengagumi Mulyah yang tetap memanggilnya &lsquo;Kang&rsquo;
                    meski telah melihat tingkah gemulainya malam itu.
                  </p>

                  <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    Setelah lulus, Indara langsung bekerja sebagai pemadam
                    kebakaran, impian kecilnya yang tak satupun orang tau. Yang
                    dia kira tak akan pernah terwujud. Pertemuannya dengan
                    Mulyah adalah titik balik terbesar dalam hidupnya, yang kini
                    dia putuskan untuk meminang Mulyah.
                  </p>

                  <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    Kehidupan berumah tangga mereka sempurna. Hidup dengan orang
                    yang paling dicintai, istri yang sedang mengandung dan
                    teman-teman Indara yang setia. Tak pernah lupa Indara
                    tentang Gang Gelap, sering-sering dia kesana bersama
                    istrinya menyambangi waria yang lain, mengajak mereka
                    bertaubat. Satu-dua ikut, yang lain memilih tetap.
                  </p>

                  <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </p>

                  <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    Cukuplah kisah bahagia Jovi Indara, si pemain lenong, si
                    <em>waria </em>yang bertaubat. Kini dia tengah menunggui
                    istrinya yang sedang melahirkan, menanti dengan was-was.
                    Berjalan bolak-balik di depan pintu ruang bersalin,
                    menggigit jemari. Bertanya pada setiap suster yang keluar
                    dari ruangan. Dia duduk, melihat lampu ruang operasi masih
                    menyala merah, lalu kembali berdiri, berjalan bolak-balik,
                    lalu duduk lagi, berulang hingga akhirnya dokter keluar
                    memanggilnya.
                  </p>

                  <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    Indara masuk langsung berlari merangkul istrinya, menciumi
                    tangan Mulyah sambil menangis. Dokter datang sembari
                    menggendong bayi yang tengah menangis, bertanya akan diberi
                    nama siapa anak itu.
                  </p>

                  <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &ldquo; Raden Gumilar Nareswara.&rdquo; Mulyah berkata lirih
                    sekali.
                  </p>

                  <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &ldquo;Biarkan dia jadi orang besar seperti kakeknya, selalu
                    berterus terang, mulia dan bijaksana.&rdquo; Mulyah terdiam,
                    matanya tertutup.
                  </p>

                  <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    Indara mengguncang tangan Mulyah, meneriaki dokter. Anak nya
                    yang baru saja lahir diberikan pada suster, dokter melihat
                    kondisi Mulyah, dan menggeleng. Indara memecahkan tangisnya,
                    meremas tangan Mulyah, istri tercintanya. Suasana haru tak
                    lagi tampak, Indara masih terus menangis menggenggam tangan
                    istrinya yang sudah mulai mendingin.
                  </p>

                  <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    Rombongan Raden Ilham Wicaksono datang ke ruang tersebut,
                    ingin bertanya dimana cucunya. Melihat Indara yang menangis
                    hebat disebelah putrinya, Raden Ilham memahami apa yang
                    terjadi. Ibu Mulyah pun ikut menangis disamping Indara,
                    kakak Mulyah memerhatikan dari jauh bersama Raden Ilham,
                    menangis. Hari itu, hari dimana Jovi Indara merasa jatuh ke
                    dasar lautan terdalam.
                  </p>

                  <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    Raden Aziz Perwira mengambil langkah, mendekati Indara.
                    Menepuk bahu Indara, menenangkannya. Indara bangkit dan
                    keluar ruangan bersama Raden Aziz Perwira. Di ruang tunggu,
                    Indara belum berhenti terisak.
                  </p>

                  <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &ldquo;Sudahlah Indara. Tak pantas kau terus menangis.
                    Anakmu harus kau urus.&rdquo;
                  </p>

                  <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    Indara mendongak, dia teringat kalau belum melihat wajah
                    anak nya barang sedetik. Indara bangkit, dan berjalan gontai
                    kearah ruangan dimana bayi yang baru lahir ditempatkan.
                  </p>

                  <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <em>Raden Gumilar Nareswara, </em>suara istrinya menggema
                    dalam pikirannya, membuncah dari relung hati terdalam. Dia
                    mencari nama anaknya, menatap setiap <em>boks </em>bayi yang
                    dia lewati. Hingga dia temukan, tak dapat menahan air mata
                    kesekian kalinya, Indara kalah telak. Wajah Ares (begini si
                    anak akan dipanggil) begitu mirip dengan mendiang istrinya.
                    <em
                      >Apapun yang terjadi kau harus merasakan memiliki seorang
                      ibu, kau harus tumbuh dengan kasih sayang seorang ibu,
                      meski tanpa ayah. Bagimu yang terpenting adalah ibu. </em
                    >Indara berkata dalam hati.
                  </p>

                  <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    Esok pagi, hari pemakaman Mulyah. Abeng duduk disebelah
                    Indara ikut menatap kosong jasad Mulyah yang sedang
                    dikebumikan.
                  </p>

                  <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &ldquo;Aku akan jadi ibu untuk Ares.&rdquo; Indara berkata.
                  </p>

                  <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &ldquo;Kenapa <em>single parent?</em>Kalau kau bisa menikah
                    lagi Kang?&rdquo;
                  </p>

                  <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &ldquo;Bukan, aku akan benar-benar menjadi ibunya.&rdquo;
                  </p>

                  <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    Abeng menatap tidak percaya Indara,
                    <em>apa maksudnya? </em>Demikian maksud tatapan Abeng.
                  </p>

                  <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    Setelah itu, Indara membawa pergi Ares, kembali ke rumah
                    Indara yang dulu, dan merenovasi ulang rumah dari awal.
                    Berkata pada Raden Ilham kalau dia ingin mengasuhnya
                    sendiri, Raden Ilham tak bisa menolak, Indara sangat
                    terpuruk.
                  </p>

                  <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    Beberapa bulan kemudian, Abeng datang ke rumah Indara.
                    Menunggu di depan pintu. Lalu seorang wanita datang membuka
                    pintu.
                  </p>

                  <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &ldquo;KANG INDARR!?&rdquo;
                  </p>

                  <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    Abeng ditarik masuk kedalam, dan pintu ditutup rapat.
                  </p>

                  <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &ldquo;Apa yang Kang Indara lakukan?&rdquo;
                  </p>

                  <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    Indara menyuruh Abeng duduk, menarik napas panjang, dan
                    mulai bicara.
                  </p>

                  <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &ldquo;Ini tidak seperti yang kau pikirkan, hanya kau yang
                    tau siapa aku ketika aku memakai riasan seperti ini. Tolong
                    sembunyikan dari Ares, aku hanya ingin dia memiliki sosok
                    ibu. Masalah bagaimana dia makan, aku sudah mendapat donor
                    ASI. Aku sangat menyayangi Ares. Sekarang ini masih mungkin,
                    mungkin beberapa tahun setelah ini, aku akan menitipkan
                    beberapa barang-barang ku seperti seragam DAMKAR
                    padamu.&rdquo;
                  </p>

                  <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    Abeng mengangguk, tak bisa berkata apa-apa. Ide gila Indara
                    tak bisa dipupuskan, semua untuk Ares. Indara akan
                    bersandiwara menjadi sosok ibu bagi Ares.
                  </p>

                  <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    Hari demi hari, Ares tumbuh dewasa, kini ia sudah di
                    penghujung sekolah dasar. Indara memainkan perannya dengan
                    baik.
                  </p>

                  <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &ldquo;Buk, Menstruasi itu apa buk?&rdquo; Tanya Ares polos
                    pada &lsquo;ibunya&rsquo;.
                  </p>

                  <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &nbsp;&ldquo;Sesuatu yang dialimi perempuan setiap sebulan
                    sekali.&rdquo; Jawab &lsquo;ibunya&rsquo;.
                  </p>

                  <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &ldquo;Temenku bilang, mamanya pakai popok terus buk, kok
                    ibuk nggak?&rdquo;
                  </p>

                  <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &ldquo;Ibuk sudah tidak lagi, namanya
                    <em>menopause.</em>&rdquo; Jawab &lsquo;ibunya&rsquo;, penuh
                    kebohongan.
                  </p>

                  <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &ldquo;Nanti juga kamu akan dijelaskan di sekolah Res,
                    jangan tanya-tanya ibuk.&rdquo; Lanjutnya.
                  </p>

                  <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    Ares tak pernah curiga sedikit pun, Ares memang cerdas, tapi
                    kemampuan berakting Indara melebihi apapun. Ini tahun ke-11
                    Indara sudah bersandiwara, menjadi ibu bagi Ares. Suara,
                    wajah dan tingkah Indara telah sangat baik memerankan
                    seorang ibu. Hanya saja, Indara tak pernah sekalipun keluar
                    rumah dengan riasan seperti itu, sandiwara nya hanya untuk
                    Ares. <em>Segalanya untuk Ares.</em>
                  </p>

                  <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    Sering teman-teman Ares berkata bahwa mereka tak pernah
                    melihat orang tua Ares, dan Ares hanya menjawab ayahnya
                    telah meninggal dari sedari dia kecil, dan ibunya sibuk
                    dirumah, seperti yang selalu Indara katakan. Ares hanya
                    tersenyum ketika setiap mengambil rapot, melihat
                    teman-temanya duduk diantara kadua orang tua mereka. Dan dia
                    bersama dengan Abeng, teman kecil Indara.
                  </p>

                  <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &ldquo;Paman, Ares pulang dulu ya, Ares pusing, Paman bawa
                    rapotnya saja dulu.&rdquo; Pinta Ares. Abeng mengangguk.
                  </p>

                  <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    Ares bangkit dari kursi, mengambil tasnya dan beranjak
                    pergi. Sampai dirumah, dia mengetuk tak ada jawaban,
                    Berulang kali, pun tetap sama. Ares yang sudah tidak tahan
                    dengan pusingnya pun memilih bertanya pada tetangga sebelah
                    rumah, yang sebenarnya ini pertama kalinya dia berbicara
                    pada tetangga nya itu..
                  </p>

                  <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &ldquo;Ibumu? Bukannya yang biasanya bersamamu adalah
                    ayahmu?&rdquo; Tetangga nya tahu tentang ibu Ares yang telah
                    lama meninggal.
                  </p>

                  <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &ldquo;Ayah? Ayahku sudah meninggal sejak lama paman.&rdquo;
                    Tetangganya memasang wajah kebingungan.
                    <em>Eh meninggal?</em> Mungkin demikian maksut ekspresinya.
                  </p>

                  <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    Indara yang dari kejauhan datang melihat Ares dan
                    tetangganya sedang bicara. Dia berlari dan segera masuk
                    kedalam rumah. Berganti pakaian, memakai riasan, dan
                    berlagak seperti ibu-ibu yang baru bangun tidur.
                  </p>

                  <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    Ares yang sudah malas mencari jalan keluar pun kembali ke
                    teras rumah dan duduk di depan pintu, sejurus kemudian
                    Indara keluar dengan riasan sempurna, dia sudah menjadi
                    Mulyah, ibu Ares. Berkata bahwa tadi dia ketiduran, tidak
                    mendengar Ares mengetuk pintu.
                  </p>

                  <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    Waktu berjalan cepat sekali, beberapa hari lagi Ares sudah
                    masuk Sekolah Menengah Atas, Ares telah tumbuh dewasa.
                  </p>

                  <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &ldquo;Buk, aku menyukai seseorang buk.&rdquo;
                  </p>

                  <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &ldquo;Oh, kalau begitu kenapa kau tak jujur padanya?&rdquo;
                    Jawab &lsquo;ibunya&rsquo;.
                  </p>

                  <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &ldquo;Masalahnya sahabatku juga menyukai gadis itu
                    buk.&rdquo;
                  </p>

                  <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &ldquo;Terus kenapa? Kalian kan bersaing.&rdquo;
                  </p>

                  <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &ldquo;Buk, aku meminta saran saran yang baik. Dia temanku,
                    apa ibu tidak bisa seperti ibu-ibu pada umumnya? Yang bisa
                    diajak bercerita. Ibuk terlalu kaku, dari dulu aku selalu
                    berpikir ibu itu aneh. Ibuk seperti bukan ibuk sebenarnya.
                    Memangnya kenapa? Apa sesuatu menjadi alasan?&rdquo;
                  </p>

                  <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &ldquo;Bukan itu Ares ibuk itu cum-.&rdquo;
                  </p>

                  <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &ldquo;Halah,&nbsp; aku tidak peduli sekali terhadap itu,
                    sudah lama aku ingin mengatakan ini, aku pikir ibuk sadar
                    ketika aku tidak pernah bercerita apapun pada ibuk, ternyata
                    tidak!&rdquo; Bentak Ares.
                  </p>

                  <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &ldquo;Oh ya buk, karena besok umurku sudah 17, aku minta
                    Kartu Keluarga kita buk. Aku ingin membuat KTP.&rdquo; Pinta
                    Ares dengan wajah kesal.
                  </p>

                  <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &ldquo;Tidak, kau tidak boleh. Setelah membentak ibumu kau
                    berkata seperti itu?&rdquo; Sergah &lsquo;ibunya&rsquo;.
                  </p>

                  <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &ldquo;Kartu Keluarga saja tidak boleh? Aku bahkan tidak
                    pernah melihatnya seumur hidupku! Aneh.&rdquo; Ares
                    berpaling, berjalan keluar rumah, meninggalkan
                    &lsquo;ibunya&rsquo; sendirian.
                  </p>

                  <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    Indara yang kalut terduduk di sofa. Merenung, berkata pada
                    dirinya sendiri
                    <em>apakah Ares sudah tahu kalau aku bukan ibunya?</em>
                    Sehebat apapun Indara dalam berperan, yang ia bohongi
                    sekarang adalah remaja yang kecerdasannya bahkan diatas
                    rata-rata, Indara terlalu meremehkan anak laki-lakiya.
                  </p>

                  <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    Malam harinya. Ares baru kembali, &lsquo;ibunya&rsquo; sudah
                    tertidur di kamarnya. Ares melangkah perlahan, dan masuk ke
                    kamar Indara. Ares berdiri di ambang pintu, memerhatikan
                    &lsquo;ibunya&rsquo; yang sedang tertidur.
                    <em
                      >Dia tampak berbeda dengan wanita biasanya, tapi aku tak
                      tau apa. Hanya terasa berbeda saja.</em
                    >
                    Ares berkata dalam hati.
                  </p>

                  <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    Esok harinya. Kecanggungan terlihat antara Ares dan
                    &lsquo;ibunya&rsquo;. Ares yang tak mau menatap
                    &lsquo;ibunya&rsquo;, &lsquo;ibunya&rsquo; yang bahkan sama
                    sekali tak bicara pada Ares. Padahal, hari ini Ares berulang
                    tahun yang ke-17. Ares berangkat sekolah tanpa sepatah kata
                    pun.
                  </p>

                  <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &ldquo;Aku balik duluan ya. Bilang aja aku sakit, nanti juga
                    Bu Agnes gak tanya-tanya lagi.&rdquo; Ares berkata.
                  </p>

                  <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &ldquo;Mau ngapain emang nya?&rdquo; Tanya seorang temannya.
                  </p>

                  <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &ldquo;Ada perlu.&rdquo;
                  </p>

                  <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    Jam sembilan tepat, Ares memutuskan kembali pulang,
                    meninggalkan sekolahnya. Sampai di rumah dia membuka pintu,
                    dan benar saja dikunci. Ares tidak bodoh, dia sudah punya
                    salinan kunci nya, dan berhasil dia buka.
                  </p>

                  <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    Sampai di dalam, dia luluh lantahkan semua isi lemari
                    Indara, pakaian-pakaian dan barang-barang penting lain.
                    <em>Kartu Keluarga itu tidak disini</em> batin Ares. Dia
                    beranjak, mengembalikan semua hal yang telah ia bongkar.
                  </p>

                  <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    Ares berpikir cepat, dia membuka knop pintu gudang yang
                    terletak di loteng, dan naik. Gudang itu berada di dalam
                    kamar Indara. Dia melihat tumpukan album lama yang tertindih
                    barang-barang usang. Ares memindahkannya satu per satu.
                  </p>

                  <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    Ada banyak sekali foto lama, seorang wanita dan pasangannya.
                    Yang dia tahu itu bukanlah ibunya, dan ketika dia melihat
                    paras pasangan wanita itu, dilihat-lihat persis
                    &lsquo;ibunya&rsquo;. Dia membalik halaman demi halaman.
                    Ares berdecak, Kartu Keluarga itu ada disini.
                  </p>

                  <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    Tidak ada nama ibu disana, hanya ada Jovi Indara sebagai
                    kepala keluarga, dengan status pernikahan
                    <em>cerai mati.</em> Dan dimana ibunya yang bernama Mulyah?
                    Tanpa berlama-lama disitu Ares berdiri hendak membuka pintu
                    gudang, dan terhenti ketika mendengar pintu kamar
                    &lsquo;ibunya&rsquo; terbuka. Seorang lelaki masuk.
                  </p>

                  <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    Ares memicingkan mata, melihat gerak gerik lelaki itu, dan
                    seperti sudah sering pergi ke kamar &lsquo;ibunya&rsquo;.
                    Lelaki itu kini berdiri di depan cermin besar
                    &lsquo;ibunya&rsquo; terlihat dari tempat Ares mengintip
                    sekarang wajah, dan kenampakan depan lelaki itu,&nbsp; mirip
                    seperti yang ada di album, Ares terkejut. Setelah beberapa
                    saat, akhirnya lelaki itu pergi dan Ares turun dari loteng.
                    Wajah Ares terlihat masam.
                  </p>

                  <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    Malamya, Ares sedang membaca di dalam kamarnya, membaca
                    Kartu Keluarga yang tadi pagi dia &lsquo;curi&rsquo; dari
                    gudang. Matanya melihat kertas itu memang, tapi pikiran Ares
                    melanglang buana. Ares akhirnya bangkit dari kamarnya dan
                    keluar. Terlihat &lsquo;ibunya&rsquo; sedang duduk menonton
                    televisi,
                  </p>

                  <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &ldquo;BUK!&rdquo; Ares melempar Kartu Keluarga itu pada
                    &lsquo;ibunya&rsquo;. Sontak, &lsquo;ibunya&rsquo; berdiri
                    dengan ekspresi wajah yang sulit dijelaskan.
                  </p>

                  <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &ldquo;Kau?&rdquo; Kata &lsquo;ibunya&rsquo; tercekat.
                  </p>

                  <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &ldquo;AKU TAU SEMUANYA! JOVI INDARA! AKU TAU KEMANA IBUKU
                    MULYAH PERGI!&rdquo; Bentak Ares.
                  </p>

                  <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &ldquo;Sebenarnya sedari dulu aku tau, tapi aku tak mau
                    berpikiran buruk tentang satu satunya orang tuaku, tapi
                    ketika tadi pagi aku benar-benar melihat Jovi Indara. Aku
                    tak punya alasan mengelak. Aku benar-benar jijik mempunyai
                    satu-satunya keluarga sepertimu Indara!&rdquo; Ares
                    mengambil napas panjang.
                  </p>

                  <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &ldquo;BAHKAN KAU SENDIRI WARIA! Jangan tanya darimana aku
                    tau, aku sering melewati Gang Gelap, disana aku sering
                    bertemu para waria, mereka sering menyebut-nyebut namamu,
                    bilang kalau aku benar-benar mirip Mulyah. Dan hingga suatu
                    hari aku kenal salah seorang dari waria itu, dia
                    menceritakan semua masa lalumu, bahwa ibuku telah meninggal,
                    dan ayahku seorang waria!&rdquo;
                  </p>

                  <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &ldquo;Bagaimana kau bisa keluar malam-malam?&rdquo; Tanya
                    &lsquo;ibunya&rsquo;.
                  </p>

                  <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &ldquo;KAU BERTANYA BAGAIMANA? Aku sadar kau selalu
                    membuatkan ku segelas susu dengan obat tidur didalamnya, aku
                    menyadari itu ketika aku baru masuk SMP, aku sadar ketika
                    teman-temanku bertanya kenapa aku tidak bisa begadang meski
                    sudah minum kopi? Apa yang tearkhir aku minum? Dan pada
                    puncaknya, aku benar-benar melihatmu menuangkan
                    <em>antihistamin.</em> Dan sejak itu aku melihatmu keluar
                    malam setelah aku berpura-pura tidur, aku tak menyangka kau
                    masih melakukan pekerjaan kotor itu. AKU MALU PUNYA AYAH
                    SEPERTIMU INDARA, AKU MALU, DASAR WARIA HINA!&rdquo;
                  </p>

                  <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    Setelah Ares menyelesaikan kalimatnya, dia pun berlalu
                    pergi, meninggalkan Indara yang seakan masih tak bisa
                    memercayai kejadian barusan.
                  </p>

                  <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    Ares yang tak tahu harus kemana memilih untuk
                    berputar-putar, dan akhirnya dia sampai di Gang Gelap. Dia
                    melewati gang itu untuk kesekian kalianya, mendengar para
                    manusia hina bersahutan, memecah&nbsp; hening malam dengan
                    desah yang berkepanjangan. Ares berhenti di salah satu rumah
                    bordil.
                  </p>

                  <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &ldquo; Aku mencari Teh Moni.&rdquo; Kata Ares pada seorang
                    wanita yang menyambutnya di ambang pintu dengan pakaian yang
                    amat terbuka, sengaja memamerkan buah dadanya.
                  </p>

                  <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &ldquo;Kenapa? Aku bahkan sudah menyambutmu disini? Untuk
                    apa kau mencari bos? Apa kau kurang kalau hanya aku? Aku
                    bisa memuaskanmu walau sendiri.&rdquo; Kata wanita itu
                    dengan manja, suaranya ketika berbicara bercampur desah,
                    tangan nya bermain di tubuh Ares.
                  </p>

                  <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &ldquo;Tidak, aku ada perlu dengan Teh Moni.&rdquo; Ares
                    berkata ketus.
                  </p>

                  <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &ldquo;Apa kau benar-benar menolak aku dan pesona-&ldquo;
                  </p>

                  <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &ldquo;Sudah Sarah, dia tamu isitimewaku!&rdquo;
                  </p>

                  <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <em>Cabo </em>yang dipanggil Sarah itu mengerucutkan bibir
                    merah meronanya, menghentak-hentakkan kakinya, membuat buah
                    dadanya yang terbuka itu bergetar.
                  </p>

                  <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &ldquo;Kau akhirnya datang kesini Ares, setidaknya kau
                    sangat jantan, bernafsu seperti lelaki, tak seperti ayah
                    <em>gigolo</em> mu itu, kau ingin ku bawakan siapa? Aku
                    bahkan memiliki model brilian disini, kau tau Nina
                    kan?&rdquo;.
                  </p>

                  <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    Ares menggeleng.
                  </p>

                  <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &ldquo;Aku ingin bicara, tidak disini, terlalu
                    berisik.&rdquo; Memang benar, rumah bordil Teh Moni adalah
                    rumah bordil paling laris, bahkan para pelanggan nya adalah
                    pejabat. Waria itu (Teh Moni) lebih sukses menjadi mucikari
                    daripada menjadi <em>gigolo. </em>Ramainya tempat Teh Moni
                    bisa diketahui lewat banyaknya lelaki yang keluar masuk,
                    desah para <em>cabo, </em>dan tertawa lantang para lelaki
                    hidung belang.
                  </p>

                  <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &ldquo;Disini saja, aku sudah tidak mendengar suara becek
                    <em>cabo-cabo</em> mu.&rdquo;
                  </p>

                  <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &ldquo;Haha, Gadis-gadisku memang tangguh, sebanyak apapun,
                    merekan akan tetap bersuara lantang, melebihi anjing yang
                    menyalak.&rdquo; Kata Teh Moni dengan congkak, membanggakan
                    rumah bordil nya.
                  </p>

                  <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &ldquo;Ceritakan tentang Indara.&rdquo;
                  </p>

                  <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &ldquo;Haha, sudah ku bilang dia gigolo di masa lalu. Tapi
                    dia berubah semenjak bertemu Mulyah, ibumu.&rdquo;
                  </p>

                  <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &ldquo;Bukannya dia masih tetap disini? Dia selalu bekerja
                    di malam hari.&rdquo;
                  </p>

                  <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &ldquo;Dasar bodoh, dia itu seorang pemadam kebakaran,
                    pastilah dia bekerja sampai malam.&rdquo;
                  </p>

                  <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &ldquo;Pemadam kebakaran? Kau tidak sedang
                    membualkan?&rdquo;
                  </p>

                  <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &ldquo;Tentu tidak Ares.&rdquo;
                  </p>

                  <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    Ares yang sudah kalut tidak tahu harus kemana, atau percaya
                    pada siapa. Tentang ayahnya yang membohonginya, selama 17
                    tahun menjadi sosok ibu baginya, menjadi <em>waria</em> di
                    masa lalunya. Teh Moni yang bicara kalau ayahnya itu telah
                    bertaubat. Dia bingung, tak tahu harus apa. Teh Moni
                    menawarkannya singgah di rumah bordilnya, barangkali
                    tertarik. Ares yang awalnya menolak, tak bisa berbuat
                    apapun, dia butuh tempat untuk tidur.
                  </p>

                  <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    Ares berbaring diatas kasur rumah bordil itu, terasa lembab.
                    Dinding nya yang tipis membuat suara dari kamar sebelahnya
                    terdengar jelas, desah para <em>cabo</em> menemani malam
                    Ares. Tak dapat tidur, Ares menyalakan televisi. Layar
                    menampilkan sebuah <em>breaknews.</em>
                  </p>

                  <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <em
                      >Kembali lagi dengan Lintas Breaknews, saya Bahar.
                      &nbsp;</em
                    >Presenter laki-laki itu mulai berceloteh.
                  </p>

                  <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <em
                      >Berita kali ini, adalah terbakarnya gudang ransum kota
                      bagian selatan, korban tidak banyak, tetapi dua dari
                      penjaga gudang masih di dalam, dan seorang pemadam
                      kebakaran terjebak di dalam karena mencari dua orang
                      tersebut</em
                    >. Ares tidak tertarik, hendak memindah saluran.
                  </p>

                  <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <em
                      >Diketahui identitas sang pemadam adalah Jovi Indara.</em
                    >
                    Ares berdecak, <em>ayah?</em> Tak pikir panjang, Ares
                    bangkit dan pergi dari situ menuju gudang ransum di kota
                    bagian selatan, tanpa pamit pada Teh Moni.
                  </p>

                  <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    Sampai disana, suasana kacau balau. Para wanita menangis,
                    bingung besok akan memasak apa untuk suami mereka. Api
                    membakar hebat, asap hitam membumbung tinggi membuat sesak
                    orang yang menyaksikan kejadian hebat itu.
                  </p>

                  <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    Ares bingung harus apa, hati nya yang sedang kacau, tapi dia
                    masih sadar, Indara adalah ayah kandungnya, dia harus
                    menemukannya. Ares memaksa masuk, menerobos barikade yang
                    dibuat para pemadam kebakaran.
                  </p>

                  <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &ldquo;KAU DILARANG MASU-, Ares?&rdquo; Salah satu pemadam
                    kebakaran berkata padanya, dia adalah Abeng.
                  </p>

                  <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &ldquo;KEMARI!&rdquo; Abeng berseru, menarik Ares menjauh
                    dari kerumunan.
                  </p>

                  <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &ldquo;Aku yakin pasti kau sudah tau semaunya kalau kau
                    sampai datang kesini. Sekarang ambil ini, dan maaf, aku
                    tidak bisa menahan ayahmu untuk masuk menolong para
                    korban.&rdquo; Abeng merogoh kantong di pakaian oranye nya,
                    mengambil secarik kertas, memberikannya pada Ares.
                  </p>

                  <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    Ares yang bingung sekarang, dia yang beberapa jam lalu marah
                    pada ayahnya, dendam, entah mengapa sekarang di hatinya
                    terasa kekosongan, kini dia yatim piatu.
                  </p>

                  <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    Ares meninggalkan tempat kejadian, berjalan gontai melewati
                    jalan-jalan malam yang lengang. Menatap lampu-lampu yang
                    remang menerangi jalan.
                  </p>

                  <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <em
                      >Untung saja hujan turun, para pemadam merasa tertolong </em
                    >Suara presenter dari acara yang sama yang dia tonton di
                    rumah bordil terdengar dari warkop yang dia lewati, benar
                    saja, hujan turun.
                  </p>

                  <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    Ares tak tau, hatinya terlalu kalut, ragu untuk melangkah,
                    hatinya sangat sakit. Dia berteriak histeris dibawah hujan,
                    terduduk menatap langit. Menangis sejadi-jadinya.
                  </p>

                  <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    Dia pulang dengan tubuh basah kuyup, berbaring meringkuk
                    sambil menangis, sampai dia tertidur.
                  </p>

                  <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &ldquo;Bangun Ares.&rdquo; Sebuah suara membangunkannya,
                    bukan suara lembut ayahnya ketika berperan menjadi ibunya,
                    itu suara Abeng.
                  </p>

                  <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &ldquo;Upacara pemakaman ayahmu sebentar lagi
                    dimulai.&rdquo;
                  </p>

                  <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    Ares bangun dengan wajah sangat sembab, terlalu banyak
                    menangis. Ares bangkit, langsung mengenakan jas yang telah
                    disiapkan Abeng. Ares keluar, melihat bahwa rumahnya telah
                    dipenuhi banyak orang berkabung, atas kepergian ayahnya.
                  </p>

                  <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <em>Apa aku harus sedih?</em>
                  </p>

                  <p>
                    <em
                      >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      Apa aku harus memaafkannya?</em
                    >
                  </p>

                  <p>
                    <em
                      >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      Bahkan setelah 17 tahun dia berbohong?</em
                    >
                  </p>

                  <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    Ares bicara pada diri sendiri sepanjang jalan ke pusara
                    ayahnya. Dia menatap para orang-orang yang hadir, menatap
                    sendu.
                  </p>

                  <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <em>Mereka menangisi seorang waria?</em>
                  </p>

                  <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    Ares yang masih belum terima bahwa dia telah dibohongi,
                    harus marah pada entah siapa lagi, ayahnya telah tiada.
                  </p>

                  <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &ldquo;Aku tidak ingin disini paman, aku tidak ingin.&rdquo;
                    Ares mengeluh pada Abeng.
                  </p>

                  <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &ldquo;Aku memahami itu.&rdquo;
                  </p>

                  <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    Mereka berjalan menjauh dari pusara, jasad Indara sedang
                    dikebumikan, Ares bahkan tak menyaksikannya sama sekali,
                    hatinya terlalu sakit.
                  </p>

                  <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    Mereka duduk dibawah rimbunnya pohon. Membelakangi upacara
                    pemakaman Indara. Dua menit lengang.
                  </p>

                  <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &ldquo;Kau sudah membaca kertas yang kuberi? Itu surat dari
                    ayahmu.&rdquo;
                  </p>

                  <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    Ares mengambilnya dari saku celana, baju nya sekarang adalah
                    baju yang kemarin yang ia kenakan. Dia membuka surat itu,
                    mulai membacanya.
                  </p>

                  <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <em
                      >Untuk Raden Gumilar Nareswara, Aku
                      ayahmu.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      Jangan terkejut, hari dimana kau membaca ini, mungkin kau
                      tidak lagi melihat ayahmu yang berbohong ini. Dan bahkan,
                      kau sudah tau semuanya. Ares, ayahmu ini terlalu khawatir
                      padamu, ayah ingin kau tumbuh diasuh seorang ibu, bukan
                      ayah sepertiku.</em
                    >
                  </p>

                  <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    Ares menghela napas, dadanya penuh sesak.
                  </p>

                  <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <em
                      >Bukan aku ingin menjadi seperti aku di masa lalu, tapi
                      aku terlalu menyayangimu Ares. Maafkanlah ayahmu yang
                      berbohong ini, maaf. Tumbuhlah dengan hebat, jadilah mulia
                      dan bijaksana seperti doa ibumu pada namamu. Janjikan kami
                      sebuah kehidupanmu yang terbaik. MAAFKAN AKU, RADEN
                      GUMILAR NARESWARA.</em
                    >
                  </p>

                  <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    Ares tercekat, tak tau harus berkata apa.
                  </p>

                  <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &ldquo;Ayahmu dulu memang seorang waria, itu demi aku&rdquo;
                    Abeng membuka suara.
                  </p>

                  <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &ldquo;Maksutnya paman?&rdquo; Ares mendongak.
                  </p>

                  <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &ldquo;Aku dan ayahmu sama-sama anak terlantar, kami
                    berempat, dan ayahmu paling tua. Dia memiliki kemampuan
                    berakting karena dulu pernah diasuh oleh sekelompok orang
                    lenong, dan karena desakan dia terpaksa mengambil pekerjaan
                    hina itu. Tetapi dia tetaplah Indara, dia adalah pemilik
                    jiwa lelaki sejati. Dia menyelamatkan ibumu dari lelaki
                    hidung belang di Gang Gelap, mereka saling jatuh
                    cinta.&rdquo;
                  </p>

                  <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &ldquo;Sekian lama, akhirnya mereka menikah, dan hadirlah
                    kamu diantara mereka, Indara yang begitu bahagia,
                    benar-benar telah menutup masalalu nya, hingga ketika kamu
                    lahir, ibumu lah yang harus membayar dengan nyawanya, Indara
                    yang begitu terpukul, menangis hebat saat itu.&rdquo;
                  </p>

                  <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    Abeng menarik napas panjang.
                  </p>

                  <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &ldquo;Melihatmu yang benar-benar mewarisi wajah ibumu,
                    Indara tak bisa apa-apa. Dia benar-benar menginginkan kau
                    bahagia dengan diasuh seorang ibu, tapi dia juga tak pernah
                    ingin menggantikan posisi istrinya dengan orang lain, dia
                    mencintai kalian berdua, dia adalah laki-laki
                    terhebat.&rdquo;
                  </p>

                  <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &ldquo;Dia putuskan mengandalkan kemampuan beraktingnya, dia
                    adalah yang terbaik, belasan tahun hidup denganmu, kau
                    bahkan tak menyadarinya, bahwa dia bukanlah ibumu. Itu semua
                    untukmu, dia tak pernah lagi menjadi waria setelah bertemu
                    ibumu, Ares. Dia hanya berakting untukmu, dia bahkan bekerja
                    bersamaku menjadi pemadam kebakaran, bhakti nya kau lihat
                    sendiri betapa banyaknya orang yang berkabung kemari, meski
                    kau tidak.&rdquo;
                  </p>

                  <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    Ares tertampar, merasa benar-benar durhaka.
                  </p>

                  <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &ldquo;Aku benar-benar tidak tau akan itu paman, aku terlalu
                    bodoh. Aku tak pernah mencari tau cerita sebenarnya, semua
                    kesalahanku, aku tak pernah meminta maaf padanya.&rdquo;
                  </p>

                  <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    Ares mulai menangis, berkata bahwa dia ingin ke pusara
                    ayahnya.
                  </p>

                  <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    Mereka berjalan, Abeng menepuk bahu Ares. Hati Ares
                    benar-benar sesak, dia ingin menggantikan posisi ayahnya,
                    dia terlalu merasa bersalah.
                  </p>

                  <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    Ares duduk disamping pusara Indara, menangis hebat.
                    Menyandarkan wajahnya pada nisan ayahnya.
                  </p>

                  <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <em
                      >Ayah?Ayah tak usah menutupi segalanya kini. Ayah tak lagi
                      harus meminta maaf padaku. Aku yang harusnya meminta maaf,
                      bahkan bersujud padamu yah. Aku sempat merasa malu, jijik,
                      bahkan merasa tak ingin menjadi bagian darimu. Aku tak
                      pernah tau semua itu untukku. Aku disini, benar-benar
                      menyesal tak sempat bicara padamu barang sedetik. Maafkan
                      aku yah.
                    </em>
                  </p>

                  <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    Ares menghapus air matanya, tersenyum tipis.
                  </p>

                  <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <em
                      >Aku sekarang bangga bahwa kau adalah ayahku, belasan
                      tahun bersandiwara, siapa yang mampu? Tak ada yang
                      mengalahkanmu yah. Dan sekarang kau gugur sebagai
                      penyelamat bukan? Aku sangat bangga, tak peduli lagi
                      bagaimana masa lalumu, kau, ayahku.</em
                    >
                  </p>

                  <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </p>

                  <p>Tamat.</p>

                  <p>Penulis: <strong>ELRA NEVATERA K.</strong></p>
                </div>
              </div>
              <div class="media ava-bloger">
                <img
                  class="mr-3 rounded-circle img-thumbnail"
                  src="https://eurekabookhouse.co.id/assets/uplod/profile/04112020_1586576240.png"
                  alt="Generic placeholder image"
                />
                <div class="media-body">
                  <p class="stext-101 m-b-8">DITULIS OLEH</p>
                  <h5 class="mt-0 mtext-111">Eureka Writer</h5>
                  <p class="badge badge-primary">Content Writer</p>
                  <p class="stext-107">Content Writter eurekabookhouse.co.id</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="sec-relate-product bg6 p-t-20 text-left">
      <div class="container p-tb-10">
        <div class="p-b-15">
          <h3 class="session-title mtext-106 cl5">New Entry</h3>
        </div>
        <div class="row">
          <div class="col-sm-6 col-md-3 p-b-20">
            <div class="blog-item bg0 bor8">
              <div class="blog-pic hov-img0">
                <a
                  href="https://www.eurekabookhouse.co.id/blog/read/kisah-kasih---juara-favorit-10-lomba-cerpen-eureka-bookhouse"
                >
                  <img
                    class="blog-read-pic"
                    src="https://cdn.eurekabookhouse.co.id/ebh/blog/13-min.jpg"
                  />
                </a>
              </div>
              <div class="p-all-15">
                <div class="stext-112 flex-w p-b-14 cl1">
                  <a
                    href="https://www.eurekabookhouse.co.id/blog/category/sahabat-eureka"
                    >Sahabat Eureka</a
                  >
                </div>
                <h4 class="p-b-32">
                  <a
                    href="https://www.eurekabookhouse.co.id/blog/read/kisah-kasih---juara-favorit-10-lomba-cerpen-eureka-bookhouse"
                    class="mtext-101 cl2 hov-cl1 trans-04"
                  >
                    KISAH KASIH - JUARA FAVORIT 10 LOMBA CERPEN EUREKA BOOKHOUSE
                  </a>
                </h4>
                <span class="stext-102 m-t-20">14 November 2022</span>
              </div>
            </div>
          </div>
          <div class="col-sm-6 col-md-3 p-b-20">
            <div class="blog-item bg0 bor8">
              <div class="blog-pic hov-img0">
                <a
                  href="https://www.eurekabookhouse.co.id/blog/read/rahasia-delia---juara-favorit-9-lomba-cerpen-eureka-bookhouse"
                >
                  <img
                    class="blog-read-pic"
                    src="https://cdn.eurekabookhouse.co.id/ebh/blog/12-min.jpg"
                  />
                </a>
              </div>
              <div class="p-all-15">
                <div class="stext-112 flex-w p-b-14 cl1">
                  <a
                    href="https://www.eurekabookhouse.co.id/blog/category/sahabat-eureka"
                    >Sahabat Eureka</a
                  >
                </div>
                <h4 class="p-b-32">
                  <a
                    href="https://www.eurekabookhouse.co.id/blog/read/rahasia-delia---juara-favorit-9-lomba-cerpen-eureka-bookhouse"
                    class="mtext-101 cl2 hov-cl1 trans-04"
                  >
                    RAHASIA DELIA - JUARA FAVORIT 9 LOMBA CERPEN EUREKA
                    BOOKHOUSE
                  </a>
                </h4>
                <span class="stext-102 m-t-20">14 November 2022</span>
              </div>
            </div>
          </div>
          <div class="col-sm-6 col-md-3 p-b-20">
            <div class="blog-item bg0 bor8">
              <div class="blog-pic hov-img0">
                <a
                  href="https://www.eurekabookhouse.co.id/blog/read/pohon-mangga-itu-berbuah-kembali---juara-favorit-8-lomba-cerpen-eureka-bookhouse"
                >
                  <img
                    class="blog-read-pic"
                    src="https://cdn.eurekabookhouse.co.id/ebh/blog/11-min.jpg"
                  />
                </a>
              </div>
              <div class="p-all-15">
                <div class="stext-112 flex-w p-b-14 cl1">
                  <a
                    href="https://www.eurekabookhouse.co.id/blog/category/sahabat-eureka"
                    >Sahabat Eureka</a
                  >
                </div>
                <h4 class="p-b-32">
                  <a
                    href="https://www.eurekabookhouse.co.id/blog/read/pohon-mangga-itu-berbuah-kembali---juara-favorit-8-lomba-cerpen-eureka-bookhouse"
                    class="mtext-101 cl2 hov-cl1 trans-04"
                  >
                    POHON MANGGA ITU BERBUAH KEMBALI - JUARA FAVORIT 8 LOMBA
                    CERPEN EUREKA BOOKHOUSE
                  </a>
                </h4>
                <span class="stext-102 m-t-20">14 November 2022</span>
              </div>
            </div>
          </div>
          <div class="col-sm-6 col-md-3 p-b-20">
            <div class="blog-item bg0 bor8">
              <div class="blog-pic hov-img0">
                <a
                  href="https://www.eurekabookhouse.co.id/blog/read/malaikat-penjagaku---juara-favorit-7-lomba-cerpen-eureka-bookhouse"
                >
                  <img
                    class="blog-read-pic"
                    src="https://cdn.eurekabookhouse.co.id/ebh/blog/10-min.jpg"
                  />
                </a>
              </div>
              <div class="p-all-15">
                <div class="stext-112 flex-w p-b-14 cl1">
                  <a
                    href="https://www.eurekabookhouse.co.id/blog/category/sahabat-eureka"
                    >Sahabat Eureka</a
                  >
                </div>
                <h4 class="p-b-32">
                  <a
                    href="https://www.eurekabookhouse.co.id/blog/read/malaikat-penjagaku---juara-favorit-7-lomba-cerpen-eureka-bookhouse"
                    class="mtext-101 cl2 hov-cl1 trans-04"
                  >
                    MALAIKAT PENJAGAKU - JUARA FAVORIT 7 LOMBA CERPEN EUREKA
                    BOOKHOUSE
                  </a>
                </h4>
                <span class="stext-102 m-t-20">14 November 2022</span>
              </div>
            </div>
          </div>
          <div class="col-sm-6 col-md-3 p-b-20">
            <div class="blog-item bg0 bor8">
              <div class="blog-pic hov-img0">
                <a
                  href="https://www.eurekabookhouse.co.id/blog/read/kenangan-tentang-ayah---juara-favorit-6-lomba-cerpen-eureka-bookhouse"
                >
                  <img
                    class="blog-read-pic"
                    src="https://cdn.eurekabookhouse.co.id/ebh/blog/9-min.jpg"
                  />
                </a>
              </div>
              <div class="p-all-15">
                <div class="stext-112 flex-w p-b-14 cl1">
                  <a
                    href="https://www.eurekabookhouse.co.id/blog/category/sahabat-eureka"
                    >Sahabat Eureka</a
                  >
                </div>
                <h4 class="p-b-32">
                  <a
                    href="https://www.eurekabookhouse.co.id/blog/read/kenangan-tentang-ayah---juara-favorit-6-lomba-cerpen-eureka-bookhouse"
                    class="mtext-101 cl2 hov-cl1 trans-04"
                  >
                    KENANGAN TENTANG AYAH - JUARA FAVORIT 6 LOMBA CERPEN EUREKA
                    BOOKHOUSE
                  </a>
                </h4>
                <span class="stext-102 m-t-20">14 November 2022</span>
              </div>
            </div>
          </div>
          <div class="col-sm-6 col-md-3 p-b-20">
            <div class="blog-item bg0 bor8">
              <div class="blog-pic hov-img0">
                <a
                  href="https://www.eurekabookhouse.co.id/blog/read/segenggam-tanah-merah---juara-favorit-5-lomba-cerpen-eureka-bookhouse"
                >
                  <img
                    class="blog-read-pic"
                    src="https://cdn.eurekabookhouse.co.id/ebh/blog/8-min.jpg"
                  />
                </a>
              </div>
              <div class="p-all-15">
                <div class="stext-112 flex-w p-b-14 cl1">
                  <a
                    href="https://www.eurekabookhouse.co.id/blog/category/sahabat-eureka"
                    >Sahabat Eureka</a
                  >
                </div>
                <h4 class="p-b-32">
                  <a
                    href="https://www.eurekabookhouse.co.id/blog/read/segenggam-tanah-merah---juara-favorit-5-lomba-cerpen-eureka-bookhouse"
                    class="mtext-101 cl2 hov-cl1 trans-04"
                  >
                    SEGENGGAM TANAH MERAH - JUARA FAVORIT 5 LOMBA CERPEN EUREKA
                    BOOKHOUSE
                  </a>
                </h4>
                <span class="stext-102 m-t-20">14 November 2022</span>
              </div>
            </div>
          </div>
          <div class="col-sm-6 col-md-3 p-b-20">
            <div class="blog-item bg0 bor8">
              <div class="blog-pic hov-img0">
                <a
                  href="https://www.eurekabookhouse.co.id/blog/read/jejak-sandal-ayah---juara-favorit-4-lomba-cerpen-eureka-bookhouse"
                >
                  <img
                    class="blog-read-pic"
                    src="https://cdn.eurekabookhouse.co.id/ebh/blog/7-min.jpg"
                  />
                </a>
              </div>
              <div class="p-all-15">
                <div class="stext-112 flex-w p-b-14 cl1">
                  <a
                    href="https://www.eurekabookhouse.co.id/blog/category/sahabat-eureka"
                    >Sahabat Eureka</a
                  >
                </div>
                <h4 class="p-b-32">
                  <a
                    href="https://www.eurekabookhouse.co.id/blog/read/jejak-sandal-ayah---juara-favorit-4-lomba-cerpen-eureka-bookhouse"
                    class="mtext-101 cl2 hov-cl1 trans-04"
                  >
                    JEJAK SANDAL AYAH - JUARA FAVORIT 4 LOMBA CERPEN EUREKA
                    BOOKHOUSE
                  </a>
                </h4>
                <span class="stext-102 m-t-20">14 November 2022</span>
              </div>
            </div>
          </div>
          <div class="col-sm-6 col-md-3 p-b-20">
            <div class="blog-item bg0 bor8">
              <div class="blog-pic hov-img0">
                <a
                  href="https://www.eurekabookhouse.co.id/blog/read/perjuangan-ayahku--untuk-masa-depanku---juara-favorit-3-lomba-cerpen-eureka-bookhouse"
                >
                  <img
                    class="blog-read-pic"
                    src="https://cdn.eurekabookhouse.co.id/ebh/blog/6-min.jpg"
                  />
                </a>
              </div>
              <div class="p-all-15">
                <div class="stext-112 flex-w p-b-14 cl1">
                  <a
                    href="https://www.eurekabookhouse.co.id/blog/category/acara-toko"
                    >Acara Toko</a
                  >
                </div>
                <h4 class="p-b-32">
                  <a
                    href="https://www.eurekabookhouse.co.id/blog/read/perjuangan-ayahku--untuk-masa-depanku---juara-favorit-3-lomba-cerpen-eureka-bookhouse"
                    class="mtext-101 cl2 hov-cl1 trans-04"
                  >
                    PERJUANGAN AYAHKU, UNTUK MASA DEPANKU - JUARA FAVORIT 3
                    LOMBA CERPEN EUREKA BOOKHOUSE
                  </a>
                </h4>
                <span class="stext-102 m-t-20">14 November 2022</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <footer class="bgu1 p-t-15 p-b-15">
      <div class="container">
        <div class="p-t-10">
          <p class="stext-107 cl0 txt-center">
            Copyright &copy; Dikembangkan oleh Eureka IT Developer
          </p>
        </div>
      </div>
    </footer>
  </div>
</template>
<script scoped>
import Navbar from "../Nav.vue";
export default {
  name: "kauayahPage",
  components: {
    Navbar,
  },
};
</script>

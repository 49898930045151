import { render, staticRenderFns } from "./triva.vue?vue&type=template&id=f44fba7a&scoped=true"
import script from "./triva.vue?vue&type=script&scoped=true&lang=js"
export * from "./triva.vue?vue&type=script&scoped=true&lang=js"
import style0 from "./triva.vue?vue&type=style&index=0&id=f44fba7a&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f44fba7a",
  null
  
)

export default component.exports
import { render, staticRenderFns } from "./MegaMenu.vue?vue&type=template&id=d89c59e8&scoped=true"
import script from "./MegaMenu.vue?vue&type=script&setup=true&lang=js"
export * from "./MegaMenu.vue?vue&type=script&setup=true&lang=js"
import style0 from "./MegaMenu.vue?vue&type=style&index=0&id=d89c59e8&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d89c59e8",
  null
  
)

export default component.exports
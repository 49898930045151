<template>
  <div>
    <div>
      <!-- BANNER  -->

      <!-- Banner layar besar -->
      <div class="d-none d-lg-block" v-if="sliderData.length > 0">
        <section class="pt-4 pb-5">
          <div class="container">
            <carousel-3d
              ref="carousel3d"
              :perspective="75"
              :space="700"
              :autoplay="true"
              :autoplay-timeout="3000"
              style="max-height: 50%; height: 58vh"
              @after-slide-change="updateActiveSlide"
            >
              <!-- Loop data dari API -->
              <slide
                v-for="(item, index) in sliderData"
                :key="index"
                :index="index"
                style="
                  width: 230%;
                  height: auto;
                  border-radius: 20px;
                  cursor: pointer;
                "
                @click.native="handleClick(index, item.link)"
              >
                <img
                  :src="item.sliderUrl"
                  class="img-fluid"
                  alt="Responsive image"
                />
              </slide>
            </carousel-3d>
          </div>
        </section>
      </div>

      <!-- Banner layar Kecil -->

      <!-- Banner layar Kecil -->
      <div class="d-lg-none" v-if="sliderData.length > 0">
        <section class="pt-4 pb-2">
          <div class="container">
            <b-carousel
              id="carousel-1"
              v-model="slide"
              :interval="1500"
              img-width="1024"
              img-height="480"
              @sliding-start="onSlideStart"
              @sliding-end="onSlideEnd"
            >
              <!-- Use v-for to loop through the API data -->
              <b-carousel-slide
                v-for="(item, index) in sliderData"
                :key="index"
              >
                <!-- Wrap the image in an <a> tag -->
                <a :href="item.link" target="_blank" rel="noopener noreferrer">
                  <img
                    :src="item.sliderUrl"
                    class="d-block w-100"
                    alt="Carousel slide image"
                  />
                </a>
              </b-carousel-slide>
            </b-carousel>
          </div>
        </section>
      </div>
    </div>

    <!-- KATEGORI -->
    <!-- Kategori layar besar -->
    <section
      style="margin-bottom: 0px"
      class="d-none d-lg-block mt-3 sec-relate-product bg6"
    >
      <div
        class="container bguk p-tb-15 p-lr-15 p-lr-5-sm"
        style="padding: 0px"
      >
        <div>
          <h3
            class="fs mtext-105 cl0 mb-2 text-left mr-2"
            style="
              font-size: 20px;
              font-weight: 600;
              color: #32599f;
              padding-left: 25px;
            "
          >
            <!-- Kategori -->
          </h3>
          <div class="mt-3">
            <div class="row" style="padding: 0">
              <div
                class="col-6 col-sm-4 col-md-3 col-lg-2 mb-3 mt-2"
                v-for="(item, index) in produkMaster"
                :key="index"
              >
                <router-link :to="'/kategori/' + item.slug">
                  <div class="card-kategori">
                    <span class="text-kat"> {{ item.name }}</span>
                    <div class="image">
                      <img
                        class="image-kat img-fluid"
                        :src="
                          item.image
                            ? 'https://stagingapi.eurekabookhouse.com/assets/front/images/icons/nerd/' +
                              item.image
                            : 'https://stagingapi.eurekabookhouse.com/assets/front/images/icons/nerd/021-puzzle.svg'
                        "
                      />
                    </div>
                  </div>
                </router-link>
              </div>
              <div class="col-6 col-sm-4 col-md-3 col-lg-2 mb-3 mt-2">
                <router-link to="/kategori">
                  <div class="card-kategori">
                    <span class="text-kat">Semua Kategori</span>
                    <div class="image">
                      <img
                        src="../assets/images/home/al.png"
                        class="image-kat img-fluid"
                        alt="Image"
                      />
                    </div>
                  </div>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- Kategori layar besar -->
    <section class="d-lg-none sec-relate-product mb-5 mt-4">
      <div
        class="container p-tb-15 p-lr-15 p-lr-5-sm"
        style="padding: 15px; background-color: #f2f2f2"
      >
        <div>
          <h3
            class="fs mtext-105 cl0 mb-2 text-left"
            style="font-size: 20px; font-weight: 600; color: #0f3984"
          >
            Kategori
          </h3>
          <div class="mt-3">
            <div class="row" style="padding: 0">
              <div
                class="col-6 col-sm-4 col-md-3 col-lg-2 mb-3 mt-2"
                v-for="(item, index) in produkMaster"
                :key="index"
              >
                <router-link :to="'/kategori/' + item.slug">
                  <div class="card-kategori">
                    <span class="text-kat"> {{ item.name }}</span>
                    <div class="image">
                      <img
                        class="image-kat img-fluid"
                        :src="
                          item.image
                            ? 'https://stagingapi.eurekabookhouse.com/assets/front/images/icons/nerd/' +
                              item.image
                            : 'https://stagingapi.eurekabookhouse.com/assets/front/images/icons/nerd/021-puzzle.svg'
                        "
                      />
                    </div>
                  </div>
                </router-link>
              </div>
              <div class="col-6 col-sm-4 col-md-3 col-lg-2 mb-3 mt-2">
                <router-link to="/kategori">
                  <div class="card-kategori">
                    <span class="text-kat">Semua Kategori</span>
                    <div class="image">
                      <img
                        src="../assets/images/home/al.png"
                        class="image-kat img-fluid"
                        alt="Image"
                      />
                    </div>
                  </div>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- Produk TAM -->
    <!-- <cardTamPage /> -->

    <!-- Produk Erlangga -->
    <cardFlashSalePage />

    <!-- Produk Erlangga -->
    <cardProductPilihanPage />

    <!-- Banner Tengah -->
    <section class="mt-3 bg6 p-b-15 mb-4">
      <div class="container p-lr-0">
        <a
          href="https://stagingapi.eurekabookhouse.com/cari/result?category=ebook"
          target="_blank"
        >
          <img
            src="https://stagingapi.eurekabookhouse.com/assets/uplod/promo/horizontal/hori_banner-min1.png"
            style="
              width: 100%;
              height: auto;
              display: block;
              border-radius: 10px;
              border: 2px solid black; /* Menambahkan border hitam */
            "
        /></a>
      </div>
    </section>

    <!-- Produk Novel -->
    <cardProdukTerbaruPage />

    <!-- Produk Anak -->
    <cardBukuAnakPage />

    <!-- <mallPage /> -->

    <!-- Mall dan Toko Online -->
    <!-- Mall Large size -->
    <section
      style="margin-bottom: 0px"
      class="d-none d-lg-block mt-3 sec-relate-product bg6"
    >
      <div
        class="container bguk p-tb-15 p-lr-15 p-lr-5-sm"
        style="padding: 0px"
      >
        <div>
          <a
            class="title-semua a.stext-101 cl0 pull-right"
            style="
              font-size: 14px;
              line-height: 32px;
              font-weight: 600;
              color: #3860a8;
              padding-right: 15px;
            "
            href="https://www.eurekabookhouse.co.id/cari/result/?category=novel"
            >Lihat semua</a
          >
          <h3
            class="fs mtext-105 cl0 mb-2 text-left mr-2"
            style="font-size: 20px; font-weight: 600; color: #000000"
          >
            Shop & Mall
          </h3>
          <div class="mt-3">
            <div class="row" style="padding: 0">
              <div class="col-4 col-md-4 col-lg-2 mb-4">
                <div
                  class="carousel-cell eurekabookmart is-selected"
                  style="background-color: white; border: 1px solid black"
                >
                  <div class="mall_index">
                    <a
                      target="_blank"
                      href="https://www.tiktok.com/@eurekabookhouse?_t=8rtkTlqsu14&_r=1"
                    >
                      <img
                        class="lazy mall_img_index"
                        style="justify-content: center"
                        src="../assets/images/home/mall/TT.png"
                      />
                    </a>
                  </div>
                  <div style="background: #000000" class="card-title-mall">
                    <a
                      target="_blank"
                      href="https://www.tiktok.com/@eurekabookhouse?_t=8rtkTlqsu14&_r=1"
                      ><span class="title-mall">Tiktok</span></a
                    >
                  </div>
                </div>
              </div>
              <div class="col-4 col-md-4 col-lg-2 mb-4">
                <div
                  class="carousel-cell eurekabookhouse"
                  style="background-color: white; border: 1px solid black"
                >
                  <div
                    class="mall_index"
                    style="display: flex; justify-content: center"
                  >
                    <a
                      target="_blank"
                      href="https://www.instagram.com/eurekabookhouse?igsh=MTV4ZnFxb2x0eGY1bQ=="
                    >
                      <img
                        class="lazy mall_img_index"
                        style="padding: 5px"
                        src="../assets/images/home/mall/ebh-ig.png"
                      />
                    </a>
                  </div>
                  <div style="background: #000000" class="card-title-mall">
                    <a
                      target="_blank"
                      href="https://www.instagram.com/eurekabookhouse?igsh=MTV4ZnFxb2x0eGY1bQ=="
                      ><span class="title-mall">Instagram</span></a
                    >
                  </div>
                </div>
              </div>
              <div class="col-4 col-md-4 col-lg-2 mb-4">
                <div
                  class="carousel-cell bukuerlangga"
                  style="background-color: white; border: 1px solid black"
                >
                  <div
                    class="mall_index"
                    style="display: flex; justify-content: center"
                  >
                    <a
                      target="_blank"
                      href="https://www.facebook.com/ebhcom/?locale=id_ID"
                    >
                      <img
                        class="lazy mall_img_index"
                        src="../assets/images/home/mall/fb-ebh.png"
                      />
                    </a>
                  </div>

                  <div style="background: #000000" class="card-title-mall">
                    <a
                      target="_blank"
                      href="https://www.facebook.com/ebhcom/?locale=id_ID"
                      ><span class="title-mall">Facebook</span>
                    </a>
                  </div>
                </div>
              </div>
              <div class="col-4 col-md-4 col-lg-2 mb-4">
                <div
                  class="carousel-cell shopee"
                  style="background-color: white; border: 1px solid black"
                >
                  <div class="mall_index">
                    <a
                      href="https://shopee.co.id/eurekabookhouse?categoryId=16049&itemId=6607522365"
                    >
                      <img
                        class="lazy mall_img_index"
                        src="../assets/images/home/mall/SP.png"
                      />
                    </a>
                  </div>
                  <div style="background: #000000" class="card-title-mall">
                    <a
                      href="https://shopee.co.id/eurekabookhouse?categoryId=16049&itemId=6607522365"
                      ><span class="title-mall">Shopee</span></a
                    >
                  </div>
                </div>
              </div>
              <div class="col-4 col-md-4 col-lg-2 mb-4">
                <div
                  class="carousel-cell tokopedia"
                  style="background-color: white; border: 1px solid black"
                >
                  <div class="mall_index">
                    <a
                      href="https://www.tokopedia.com/eurekaonline?source=universe&st=product"
                    >
                      <img
                        class="lazy mall_img_index"
                        src="../assets/images/home/mall/TP.png"
                      />
                    </a>
                  </div>
                  <div style="background: #000000" class="card-title-mall">
                    <a
                      href="https://www.tokopedia.com/eurekaonline?source=universe&st=product"
                      ><span class="title-mall">Tokopedia</span></a
                    >
                  </div>
                </div>
              </div>
              <div class="col-4 col-md-4 col-lg-2 mb-4">
                <div
                  class="carousel-cell bukalapak"
                  style="background-color: white; border: 1px solid black"
                >
                  <div class="mall_index">
                    <a
                      href="https://www.bukalapak.com/eureka-bookhouse-official?from=omnisearch&from_keyword_history=false&search_source=omnisearch_brand&source=navbar"
                    >
                      <img
                        class="lazy mall_img_index"
                        src="../assets/images/home/mall/BL2.png"
                      />
                    </a>
                  </div>
                  <div style="background: #000000" class="card-title-mall">
                    <a
                      href="https://www.bukalapak.com/eureka-bookhouse-official?from=omnisearch&from_keyword_history=false&search_source=omnisearch_brand&source=navbar"
                      ><span class="title-mall">BukaLapak</span></a
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <cardManufacturPage />

    <!-- Mall Mobile size -->
    <section class="d-lg-none sec-relate-product bg6 mb-5 mt-4">
      <div
        class="container p-tb-15 p-lr-15 p-lr-5-sm"
        style="padding: 15px; background-color: #f2f2f2"
      >
        <div>
          <h3
            class="fs mtext-105 cl0 mb-2 text-left"
            style="font-size: 20px; font-weight: 600; color: #0f3984"
          >
            Temukan Kami
          </h3>
          <div class="mt-3">
            <div class="row" style="padding: 0">
              <div class="col-6 col-md-3 col-lg-2 mb-4">
                <div
                  class="carousel-cell eurekabookmart is-selected"
                  style="background-color: white"
                >
                  <div class="mall_index">
                    <a
                      target="_blank"
                      href="https://www.tiktok.com/@eurekabookhouse?_t=8rtkTlqsu14&_r=1"
                    >
                      <img
                        class="lazy mall_img_index"
                        src="../assets/images/home/mall/tt-ebh.png"
                      />
                    </a>
                  </div>
                  <div style="background: #000000" class="card-title-mall">
                    <a
                      target="_blank"
                      href="https://www.tiktok.com/@eurekabookhouse?_t=8rtkTlqsu14&_r=1"
                      ><span class="title-mall">Tiktok</span></a
                    >
                  </div>
                </div>
              </div>
              <div class="col-6 col-md-4 col-lg-2 mb-4">
                <div
                  class="carousel-cell eurekabookhouse"
                  style="background-color: white"
                >
                  <div
                    class="mall_index"
                    style="display: flex; justify-content: center"
                  >
                    <a
                      target="_blank"
                      href="https://www.instagram.com/eurekabookhouse?igsh=MTV4ZnFxb2x0eGY1bQ=="
                    >
                      <img
                        class="lazy mall_img_index"
                        src="../assets/images/home/mall/ebh-ig.png"
                      />
                    </a>
                  </div>
                  <div style="background: #6343c3" class="card-title-mall">
                    <a
                      target="_blank"
                      href="https://www.instagram.com/eurekabookhouse?igsh=MTV4ZnFxb2x0eGY1bQ=="
                      ><span class="title-mall">Instagram</span></a
                    >
                  </div>
                </div>
              </div>
              <div class="col-6 col-md-4 col-lg-2 mb-4">
                <div
                  class="carousel-cell bukuerlangga"
                  style="background-color: white"
                >
                  <div
                    class="mall_index"
                    style="display: flex; justify-content: center"
                  >
                    <a
                      target="_blank"
                      href="https://www.facebook.com/ebhcom/?locale=id_ID"
                    >
                      <img
                        class="lazy mall_img_index"
                        src="../assets/images/home/mall/fb-ebh.png"
                      />
                    </a>
                  </div>
                  <div style="background: #2c77ff" class="card-title-mall">
                    <a
                      target="_blank"
                      href="https://www.facebook.com/ebhcom/?locale=id_ID"
                      ><span class="title-mall">Facebook</span>
                    </a>
                  </div>
                </div>
              </div>
              <div class="col-6 col-md-4 col-lg-2 mb-4">
                <div
                  class="carousel-cell shopee"
                  style="background-color: white"
                >
                  <div class="mall_index">
                    <a
                      href="https://shopee.co.id/eurekabookhouse?categoryId=16049&itemId=6607522365"
                    >
                      <img
                        class="lazy mall_img_index"
                        src="../assets/images/home/mall/4.png"
                      />
                    </a>
                  </div>
                  <div style="background: #ef4e2d" class="card-title-mall">
                    <a
                      href="https://shopee.co.id/eurekabookhouse?categoryId=16049&itemId=6607522365"
                      ><span class="title-mall">Shopee</span></a
                    >
                  </div>
                </div>
              </div>
              <div class="col-6 col-md-4 col-lg-2 mb-4">
                <div
                  class="carousel-cell tokopedia"
                  style="background-color: white"
                >
                  <div class="mall_index">
                    <a
                      href="https://www.tokopedia.com/eurekaonline?source=universe&st=product"
                    >
                      <img
                        class="lazy mall_img_index"
                        src="../assets/images/home/mall/5.png"
                      />
                    </a>
                  </div>
                  <div style="background: #5db953" class="card-title-mall">
                    <a
                      href="https://www.tokopedia.com/eurekaonline?source=universe&st=product"
                      ><span class="title-mall">Tokopedia</span></a
                    >
                  </div>
                </div>
              </div>
              <div class="col-6 col-md-4 col-lg-2 mb-4">
                <div
                  class="carousel-cell bukalapak"
                  style="background-color: white"
                >
                  <div class="mall_index">
                    <a
                      href="https://www.bukalapak.com/eureka-bookhouse-official?from=omnisearch&from_keyword_history=false&search_source=omnisearch_brand&source=navbar"
                    >
                      <img
                        class="lazy mall_img_index"
                        style="padding: 10px"
                        src="../assets/images/home/mall/6.png"
                      />
                    </a>
                  </div>
                  <div style="background: #c5005c" class="card-title-mall">
                    <a
                      href="https://www.bukalapak.com/eureka-bookhouse-official?from=omnisearch&from_keyword_history=false&search_source=omnisearch_brand&source=navbar"
                      ><span class="title-mall">BukaLapak</span></a
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- BLOG -->
    <!-- Blog Large -->
    <section
      style="margin-bottom: 0px"
      class="d-none d-lg-block mt-3 sec-relate-product bg6"
    >
      <div
        class="container bguk p-tb-15 p-lr-15 p-lr-5-sm"
        style="padding: 0px"
      >
        <div>
          <a
            class="title-semua a.stext-101 cl0 pull-right"
            style="
              font-size: 14px;
              line-height: 32px;
              font-weight: 400;
              color: #3860a8;
              padding-right: 15px;
            "
            href="https://www.eurekabookhouse.co.id/blog"
            >Lihat semua</a
          >
          <h3
            class="fs mtext-105 cl0 mb-2 text-left mr-2"
            style="font-size: 20px; font-weight: 600; color: #000000"
          >
            Blog Kami
          </h3>
          <div class="mt-3">
            <div class="row" style="padding: 0">
              <div class="col-6 col-sm-3 col-md-3 p-b-5 p-lr-5-sm text-left">
                <div class="block2 blog-item bg0" style="border-radius: 10px">
                  <div class="blog-pic hov-img0">
                    <router-link :to="'/Blog/kisahkasih'">
                      <img
                        class="lazy"
                        src="https://cdn.eurekabookhouse.co.id/ebh/blog/13-min.jpg"
                        style="width: 100%"
                      />
                    </router-link>
                  </div>
                  <div class="blog-text">
                    <div>
                      <div>
                        <span class="badge badge-primary">Sahabat Eureka</span>
                      </div>
                    </div>
                    <h4 class="title m-b-5">
                      <router-link
                        :to="'/Blog/kisahkasih'"
                        class="stext-105 cl2 hov-cl1 trans-04"
                      >
                        KISAH KASIH - JUARA FAVORIT 10 LOMBA CERPEN EUREKA
                        BOOKHOUSE
                      </router-link>
                    </h4>
                  </div>
                </div>
              </div>
              <div class="col-6 col-sm-3 col-md-3 p-b-5 p-lr-5-sm text-left">
                <div class="block2 blog-item bg0" style="border-radius: 10px">
                  <div class="blog-pic hov-img0">
                    <router-link :to="'/Blog/rahasiadelia'">
                      <img
                        class="lazy"
                        src="https://cdn.eurekabookhouse.co.id/ebh/blog/12-min.jpg"
                        style="width: 100%"
                      />
                    </router-link>
                  </div>
                  <div class="blog-text">
                    <div>
                      <div>
                        <span class="badge badge-primary">Sahabat Eureka</span>
                      </div>
                    </div>
                    <h4 class="title m-b-5">
                      <router-link
                        :to="'/Blog/rahasiadelia'"
                        class="stext-105 cl2 hov-cl1 trans-04"
                      >
                        RAHASIA DELIA - JUARA FAVORIT 9 LOMBA CERPEN EUREKA
                        BOOKHOUSE
                      </router-link>
                    </h4>
                  </div>
                </div>
              </div>
              <div class="col-6 col-sm-3 col-md-3 p-b-5 p-lr-5-sm text-left">
                <div class="block2 blog-item bg0" style="border-radius: 10px">
                  <div class="blog-pic hov-img0">
                    <router-link :to="'/Blog/pohonmangga'">
                      <img
                        class="lazy"
                        src="https://cdn.eurekabookhouse.co.id/ebh/blog/11-min.jpg"
                        style="width: 100%"
                      />
                    </router-link>
                  </div>
                  <div class="blog-text">
                    <div>
                      <div>
                        <span class="badge badge-primary">Sahabat Eureka</span>
                      </div>
                    </div>
                    <h4 class="title m-b-5">
                      <router-link
                        :to="'/Blog/pohonmangga'"
                        class="stext-105 cl2 hov-cl1 trans-04"
                      >
                        POHON MANGGA ITU BERBUAH KEMBALI - JUARA FAVORIT 8 LOMBA
                        CERPEN EUREKA BOOKHOUSE
                      </router-link>
                    </h4>
                  </div>
                </div>
              </div>
              <div class="col-6 col-sm-3 col-md-3 p-b-5 p-lr-5-sm text-left">
                <div class="block2 blog-item bg0" style="border-radius: 10px">
                  <div class="blog-pic hov-img0">
                    <router-link :to="'/Blog/malaikat'">
                      <img
                        class="lazy"
                        src="https://cdn.eurekabookhouse.co.id/ebh/blog/10-min.jpg"
                        style="width: 100%"
                      />
                    </router-link>
                  </div>
                  <div class="blog-text">
                    <div>
                      <div>
                        <span class="badge badge-primary">Sahabat Eureka</span>
                      </div>
                    </div>
                    <h4 class="title m-b-5">
                      <router-link
                        :to="'/Blog/malaikat'"
                        class="stext-105 cl2 hov-cl1 trans-04"
                      >
                        MALAIKAT PENJAGAKU - JUARA FAVORIT 7 LOMBA CERPEN EUREKA
                        BOOKHOUSE
                      </router-link>
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- Blog Mobile size -->
    <section class="d-lg-none sec-relate-product bg6 mb-5 mt-4">
      <div
        class="container p-tb-15 p-lr-15 p-lr-5-sm"
        style="padding: 15px; background-color: #f2f2f2"
      >
        <div>
          <h3
            class="fs mtext-105 cl0 mb-2 text-left"
            style="font-size: 20px; font-weight: 600; color: #0f3984"
          >
            Blog
          </h3>
          <div class="mt-3">
            <div class="row" style="padding: 0">
              <div class="col-6 col-sm-3 col-md-3 p-b-5 p-lr-5-sm text-left">
                <div class="block2 blog-item bg0" style="border-radius: 10px">
                  <div class="blog-pic hov-img0">
                    <router-link :to="'/Blog/kisahkasih'">
                      <img
                        class="lazy"
                        src="https://cdn.eurekabookhouse.co.id/ebh/blog/13-min.jpg"
                        style="width: 100%"
                      />
                    </router-link>
                  </div>
                  <div class="blog-text">
                    <div>
                      <div>
                        <span class="badge badge-primary">Sahabat Eureka</span>
                      </div>
                    </div>
                    <h4 class="title m-b-5">
                      <router-link
                        :to="'/Blog/kisahkasih'"
                        class="stext-105 cl2 hov-cl1 trans-04"
                      >
                        KISAH KASIH - JUARA FAVORIT 10 LOMBA CERPEN EUREKA
                        BOOKHOUSE
                      </router-link>
                    </h4>
                  </div>
                </div>
              </div>
              <div class="col-6 col-sm-3 col-md-3 p-b-5 p-lr-5-sm text-left">
                <div class="block2 blog-item bg0" style="border-radius: 10px">
                  <div class="blog-pic hov-img0">
                    <router-link :to="'/Blog/rahasiadelia'">
                      <img
                        class="lazy"
                        src="https://cdn.eurekabookhouse.co.id/ebh/blog/12-min.jpg"
                        style="width: 100%"
                      />
                    </router-link>
                  </div>
                  <div class="blog-text">
                    <div>
                      <div>
                        <span class="badge badge-primary">Sahabat Eureka</span>
                      </div>
                    </div>
                    <h4 class="title m-b-5">
                      <router-link
                        :to="'/Blog/rahasiadelia'"
                        class="stext-105 cl2 hov-cl1 trans-04"
                      >
                        RAHASIA DELIA - JUARA FAVORIT 9 LOMBA CERPEN EUREKA
                        BOOKHOUSE
                      </router-link>
                    </h4>
                  </div>
                </div>
              </div>
              <div class="col-6 col-sm-3 col-md-3 p-b-5 p-lr-5-sm text-left">
                <div class="block2 blog-item bg0" style="border-radius: 10px">
                  <div class="blog-pic hov-img0">
                    <router-link :to="'/Blog/pohonmangga'">
                      <img
                        class="lazy"
                        src="https://cdn.eurekabookhouse.co.id/ebh/blog/11-min.jpg"
                        style="width: 100%"
                      />
                    </router-link>
                  </div>
                  <div class="blog-text">
                    <div>
                      <div>
                        <span class="badge badge-primary">Sahabat Eureka</span>
                      </div>
                    </div>
                    <h4 class="title m-b-5">
                      <router-link
                        :to="'/Blog/pohonmangga'"
                        class="stext-105 cl2 hov-cl1 trans-04"
                      >
                        POHON MANGGA ITU BERBUAH KEMBALI - JUARA FAVORIT 8 LOMBA
                        CERPEN EUREKA BOOKHOUSE
                      </router-link>
                    </h4>
                  </div>
                </div>
              </div>
              <div class="col-6 col-sm-3 col-md-3 p-b-5 p-lr-5-sm text-left">
                <div class="block2 blog-item bg0" style="border-radius: 10px">
                  <div class="blog-pic hov-img0">
                    <router-link :to="'/Blog/malaikat'">
                      <img
                        class="lazy"
                        src="https://cdn.eurekabookhouse.co.id/ebh/blog/10-min.jpg"
                        style="width: 100%"
                      />
                    </router-link>
                  </div>
                  <div class="blog-text">
                    <div>
                      <div>
                        <span class="badge badge-primary">Sahabat Eureka</span>
                      </div>
                    </div>
                    <h4 class="title m-b-5">
                      <router-link
                        :to="'/Blog/malaikat'"
                        class="stext-105 cl2 hov-cl1 trans-04"
                      >
                        MALAIKAT PENJAGAKU - JUARA FAVORIT 7 LOMBA CERPEN EUREKA
                        BOOKHOUSE
                      </router-link>
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <br />
    <br />
  </div>
</template>
<script scope>
import axios from "axios";
import Swal from "sweetalert2";
import cardProdukPage from "@/components/cardProduk.vue";
import cardProdukTerbaruPage from "@/components/cardProdukTerbaru.vue";
import cardNovelPage from "@/components/cardNovel.vue";
import cardFlashSalePage from "@/components/cardFlashSale.vue";
import cardKesehatanPage from "@/components/cardKesehatan.vue";
import cardTamPage from "@/components/cardTam.vue";
import cardBukuAnakPage from "@/components/cardBukuAnak.vue";
import cardManufacturPage from "@/components/cardManufactur.vue";
import cardProductPilihanPage from "./cardProductPilihan.vue";
// import mallPage from "@/components/mall.vue";
export default {
  name: "navBar",
  components: {
    // Flickity,
    // cardKesehatanPage,
    // cardProdukPage, //Produk Erlangga
    // cardNovelPage, //Produk Novel
    // cardTamPage, //Produk Novel
    cardBukuAnakPage, //Produk Anak
    cardProdukTerbaruPage,
    cardFlashSalePage,
    cardManufacturPage,
    cardProductPilihanPage,
    // mallPage
  },
  data() {
    return {
      name: "BootstrapVue",
      isDropdown2Visible: false,
      flickityOptions: {
        initialIndex: 0,
        prevNextButtons: false,
        pageDots: false,
        cellAlign: "left",
        wrapAround: false,
        freeScroll: false,
      },
      slide: 0,
      sliderData: [],
      produkMaster: [],
      activeSlideIndex: 0, // Menyimpan indeks slide yang tengah
    };
  },
  mounted() {
    this.fetchSliderData(); // Ambil data Banner
    this.getKategori(); // Ambil data kategori
  },

  methods: {
    //  Integrasi API Banner
    async fetchSliderData() {
      try {
        const response = await axios.get(
          "https://stagingapi.eurekabookhouse.com/slider"
        );

        this.sliderData = response.data.data.order.map((item) => {
          return {
            // sliderUrl: `https://www.eurekabookhouse.co.id/assets/uplod/slider/${item.slider}`,
            sliderUrl: `https://stagingapi.eurekabookhouse.com/assets/uplod/slider/${item.slider}`,
            link: item.link || "#",
          };
        });
      } catch (error) {
        console.error("Error fetching slider data:", error);
      }
    },

    //  Integrasi API Kategori
    getKategori() {
      const apiUrl =
        "https://stagingapi.eurekabookhouse.com/category/mega-menu";

      axios
        .get(apiUrl)
        .then((response) => {
          if (response.status === 200) {
            const data = response.data.data.order;
            this.produkMaster = data;
          } else {
            console.error(`Response failed, status: ${response.status}`);
            Swal.fire({
              confirmButtonColor: "#3860A8",
              icon: "error",
              title: "Failed to Fetch Product Data",
              text: "An error occurred while fetching product data. Please try again.",
            });
          }
        })
        .catch((error) => {
          console.error(error);

          Swal.fire({
            confirmButtonColor: "#3860A8",
            icon: "error",
            title: "Failed to Fetch Product Data",
            text: "An error occurred while fetching product data. Please try again.",
          });
        });
    },

    //Fungsi Slider banner
    next() {
      this.$refs.flickity.next();
    },
    previous() {
      this.$refs.flickity.previous();
    },

    // Update slide tengah saat berubah
    updateActiveSlide(index) {
      this.activeSlideIndex = index;
    },

    // Handle klik pada slide
    handleClick(index, link) {
      if (index === this.activeSlideIndex && link !== "#") {
        window.open(link, "_blank");
      }
    },

    onSlideStart() {},
    onSlideEnd() {},
  },
};
</script>
<style scoped>
.carousel-control-prev,
.carousel-control-next {
  top: 50%;
  transform: translateY(-50%);
  width: auto;
}

.carousel-control-prev {
  left: 20%;
}

.carousel-control-next {
  right: 20%;
}

.carousel-indicators {
  display: none;
}
.title-semua {
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: -0.16px;
}
.carousel-container {
  align-items: center;
}

.mall_index {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px; /* Sesuaikan tinggi agar seragam */
  width: 100%; /* Agar responsif */
}

.mall_img_index {
  max-width: 80px; /* Ukuran maksimum logo */
  max-height: 80px; /* Pastikan semua logo tidak melebihi batas */
  object-fit: contain; /* Menjaga aspek rasio */
}

.card-title-mall {
  display: flex;
  width: 100%;
  margin-top: 11px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 0px 0px 10px 10px;
}

.title-mall {
  color: var(--neutral-01, #fefefe);
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 15.4px;
  letter-spacing: -0.14px;
}

@media (max-width: 770px) {
  .card-title-mall {
    width: 100%;
  }

  .title-mall {
    font-size: 12px;
  }
}

@media (max-width: 770px) {
  .card-title-mall {
    padding: 8px;
  }

  .title-mall {
    font-size: 13px;
  }
}

.card-slider {
  display: flex;
  padding: 10px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
  border-radius: 0px 0px 10px 10px;
  background: var(--neutral-01, #fefefe);
}
.card-ongkir {
  display: flex;
  width: 100%;
  max-width: 300px;
  height: auto;
  padding: 20px;
  align-items: center;
  gap: 10px;
}

@media (max-width: 576px) {
  .card-ongkir {
    max-width: 100%;
  }
}

@media (max-width: 768px) {
  .card-ongkir {
    padding: 10px;
  }
}

@media (max-width: 992px) {
  .card-ongkir {
    padding: 15px;
  }
}

.img-ongkir {
  width: 80px;
  height: 80px;
  flex-shrink: 0;
}
.img-main {
  width: 80px;
  height: 80px;
  flex-shrink: 0;
}
.img-erl {
  width: 62px;
  height: 80px;
  flex-shrink: 0;
}
.judul-og {
  color: var(--neutral-01, #fefefe);
  text-align: center;
  margin-bottom: 0px;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: -0.16px;
}
.ket-ongkir {
  color: var(--neutral-02, #efefef);
  text-align: center;
  margin-bottom: 0px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 15.4px;
  letter-spacing: -0.14px;
}
.og {
  border-radius: 20px;
  background: linear-gradient(256deg, #667eea 0%, #764ba2 100%);
}
.mainan {
  border-radius: 20px;
  background: linear-gradient(256deg, #91d0b0 0%, #3c8461 100%);
}
.erl {
  border-radius: 20px;
  background: linear-gradient(256deg, #66baea 0%, #163194 100%);
}
.nvl {
  border-radius: 20px;
  background: linear-gradient(256deg, #cd9cf2 0%, #a92c68 100%);
}
.card-kategori {
  display: flex;
  width: 100%;
  height: 80px;
  padding: 8px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;
  border-radius: 10px;
  background: var(--neutral-01, #fefefe);
  border: 1px solid black; /* Menambahkan border hitam */
}
.bu {
  border-radius: 10px;
  background: linear-gradient(249deg, #8c9ce4 29.58%, #7e5ca2 88.96%);
}
.bn {
  border-radius: 10px;
  background: linear-gradient(249deg, #fd90ff 29.58%, #815188 88.96%);
}
.ba {
  border-radius: 10px;
  background: linear-gradient(249deg, #b1dd95 29.58%, #3b8461 88.96%);
}
.mh {
  border-radius: 10px;
  background: linear-gradient(249deg, #ffbd5d 29.58%, #cd4848 88.96%);
}
.bs {
  border-radius: 10px;
  background: linear-gradient(249deg, #ffc4b2 29.58%, #dc8621 88.96%);
}
.bp {
  border-radius: 10px;
  background: linear-gradient(249deg, #8d7bdc 29.58%, #204372 88.96%);
}
.pt {
  border-radius: 10px;
  background: linear-gradient(249deg, #75c5e0 29.58%, #556e99 88.96%);
}
.at {
  border-radius: 10px;
  background: linear-gradient(249deg, #e7955b 29.58%, #952144 88.96%);
}
.bf {
  border-radius: 10px;
  background: linear-gradient(249deg, #9f9696 29.58%, #383839 88.96%);
}
.eb {
  border-radius: 10px;
  background: linear-gradient(249deg, #9bc5c3 29.58%, #0f6965 88.96%);
}
.em {
  border-radius: 10px;
  background: linear-gradient(249deg, #83e1dd 29.58%, #434584 88.96%);
}
.sk {
  border-radius: 10px;
  background: linear-gradient(
    249deg,
    #b1066c 4.06%,
    #d56aaa 44.69%,
    #5f4176 88.96%
  );
}
.text-kat {
  color: #000;
  width: 80px;
  flex-shrink: 0;
  font-size: 13px;
  font-style: normal;
  text-align: left;
  font-weight: 600;
  line-height: 18px; /* 112.5% */
  letter-spacing: -0.16px;
}
.content {
  flex-grow: 1;
  padding: 8px;
  color: white;
}

.image-kat {
  width: 40px;
  height: 40px;
  flex-shrink: 0;
}
.flickity-page-dots {
  bottom: 0px;
}

.flickity-page-dots .dot {
  width: 12px;
  height: 12px;
  opacity: 1;
  background: transparent;
  border: 2px solid white;
}

/* fill-in selected dot */
.flickity-page-dots .dot.is-selected {
  background: white;
}
</style>
<style>
.dot {
  height: 25px;
  width: 25px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
}
</style>

<style lang="scss" scoped>
.carousel-3d-slide.current {
  opacity: 1 !important;
  visibility: visible !important;
  transform: none !important;
  z-index: 999;
  position: relative;
  background-repeat: no-repeat;
}

.carousel-3d-slide {
  opacity: 0.5 !important;
  border-color: transparent;
}

.carousel-3d-container {
  .carousel-3d-slide {
    margin-left: -65%;
  }
}
</style>

import { render, staticRenderFns } from "./acaratoko.vue?vue&type=template&id=67500fac&scoped=true"
import script from "./acaratoko.vue?vue&type=script&scoped=true&lang=js"
export * from "./acaratoko.vue?vue&type=script&scoped=true&lang=js"
import style0 from "./acaratoko.vue?vue&type=style&index=0&id=67500fac&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "67500fac",
  null
  
)

export default component.exports
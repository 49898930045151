<template>
  <div class="bg-gray-100 min-h-screen">
    <!-- Navbar Component -->
    <Navbar />

    <section class="bg0 p-b-20 text-left">
      <div class="container p-lr-80-lg">
        <div class="row">
          <div class="col-md-1"></div>
          <div class="col-md-10">
            <h1 class="ltext-102 cl2 p-t-28">
              MENANTI SENJA - JUARA FAVORIT 2 LOMBA CERPEN EUREKA BOOKHOUSE
            </h1>
            <div class="flex-m flex-sb m-tb-15">
              <div class="flex-i flex-w flex-m stext-111 cl2">
                <span>
                  <span class="cl4">Oleh</span> Eureka
                  <span class="cl12 m-l-4 m-r-6">|</span>
                </span>
                <span>
                  12 Nov, 2022 <span class="cl12 m-l-4 m-r-6">|</span>
                </span>
                <div id="share" class="m-l-50-lg">
                  <a
                    class="fs-14 badge p-all-5 hov-cl0 facebook customer share"
                    href="https://www.facebook.com/sharer.php?u=https://www.eurekabookhouse.co.id/blog/read/menanti-senja---juara-favorit-2-lomba-cerpen-eureka-bookhouse"
                    target="_blank"
                    ><i class="fa fa-facebook"></i
                  ></a>
                  <a
                    class="fs-14 badge p-all-5 hov-cl0 twitter customer share"
                    href="http://twitter.com/share?url=https://www.eurekabookhouse.co.id/blog/read/menanti-senja---juara-favorit-2-lomba-cerpen-eureka-bookhouse&amp;text=Blog: MENANTI SENJA - JUARA FAVORIT 2 LOMBA CERPEN EUREKA BOOKHOUSE &amp;hashtags=eurekabookhouse"
                    title="Twitter share"
                    target="_blank"
                    ><i class="fa fa-twitter"></i
                  ></a>
                  <a
                    class="fs-14 badge p-all-5 hov-cl0 googleplus customer share"
                    href="https://plus.google.com/share?url=https://www.eurekabookhouse.co.id/blog/read/menanti-senja---juara-favorit-2-lomba-cerpen-eureka-bookhouse"
                    title="Google Plus Share"
                    target="_blank"
                    ><i class="fa fa-google-plus"></i
                  ></a>
                </div>
              </div>
              <div class="flex-r-m">
                <span class="stext-107 text-center"
                  ><i class="fa fa-eye"></i> 1006</span
                >
              </div>
            </div>
            <div class="wrap-pic-w how-pos5-parent">
              <img
                src="https://cdn.eurekabookhouse.co.id/ebh/blog/5-min.jpg"
                alt="IMG-BLOG"
                class="blog-read-pic"
              />
              <div class="flex-col-c-m size-123 bg9 how-pos5">
                <span class="ltext-107 cl2 txt-center"> 12 </span>

                <span class="stext-109 cl3 txt-center"> 11 2022 </span>
              </div>
            </div>
          </div>
          <div class="col-md-1"></div>
        </div>
        <div class="row p-t-35 p-b-80">
          <div class="col-lg-2"></div>
          <div class="col-md-8 col-lg-8">
            <div class="p-r-0-lg">
              <div>
                <div class="isi_blog ctext-101 fs-18 cl2 p-b-26">
                  <p>
                    Seorang gadis diam dalam lamunan. Deretan buku yang berjejer
                    rapi dianggurkannya begitu saja. Biasanya malam-malam begini
                    merupakan waktu favoritnya untuk membuka jendela dunia.
                    Namun, lain kali ini. Sepertinya pikirannya telah terpenuhi
                    banyak hal sehingga tak berselera membuka satu halaman buku
                    pun. Di ruang keluarga, adiknya yang masih kelas 4 SD tengah
                    asik menonton tayangan kartun kesukaannya. Sesekali adiknya
                    merengek manja minta ditemani kakaknya.
                  </p>

                  <p>
                    Dua anak ditinggal setiap hari dalam naungan sepi. Ibunya
                    bekerja jauh, ayahnya juga. Bedanya, sang ibu pergi entah
                    kapan kembali, sang ayah pergi pagi pulang petang. Meski
                    terbatas dalam waktu, tak sedikit pun minim cinta dan kasih.
                    Mungkin ibu bisa menghubungi lewat telepon setiap minggu,
                    tapi kasih ayah nyata adanya.
                  </p>

                  <p>***</p>

                  <p>
                    Menjalani dua peran sekaligus awalnya tak mudah bagi seorang
                    bapak yang sifatnya kaku. Tak bisa lagi bersikap dingin dan
                    cuek layaknya dulu. Sekarang ia harus aktif peduli terhadap
                    anak-anaknya. Saat ada istrinya, membuka mata sudah
                    dihidangi kopi. Kini pagi buta sudah sibuk berurusan dengan
                    wajan dan panci.
                  </p>

                  <p>
                    &ldquo;Nak, bangun. Sholat dulu.&rdquo; Sang bapak
                    membangunkan putri pertamanya, Aisya.
                  </p>

                  <p>
                    &ldquo;Iya, pak.&rdquo; jawabnya sambil mengucek mata. Ia
                    pun pelan membisiki adiknya yang masih terlelap untuk
                    bangun.
                  </p>

                  <p>
                    Mereka bertiga mendirikan sholat bersama. Doa yang
                    dipanjatkan bersautan dengan gerimis yang mulai menderas.
                    Saat yang sangat tepat berdialog dengan Sang Pencipta. Usai
                    berdoa, ayat suci dilantunkan oleh Aisya yang rutin
                    mengulang hafalan. Tak tega melihat Inayah yang masih
                    terkantuk-kantuk, Aisya menyuruhnya untuk sekadar tiduran di
                    sofa. Sementara bapak, melanjutkan kembali aktivitasnya di
                    dapur . Pukul 6 pagi, bapak sudah siap untuk bekerja.
                  </p>

                  <p>
                    Kebetulan saat itu sekolah <em>online</em> sehingga Aisya
                    dan Inayah tetap di rumah. Namun, seringnya mereka berdua
                    bertandang ke rumah simbah yang tak jauh jaraknya dari
                    rumah. Maka dari itu, bapak tidak terlalu merisaukan perihal
                    kedua anaknya yang ditinggal sejak mentari mulai menyapa.
                    Meski dalam lubuk hati tak bisa menyangkal bahwa ia ingin
                    terus menghabiskan waktu bersama sepanjang hari.
                  </p>

                  <p>
                    &ldquo;Bapak pamit dulu. Jaga diri baik-baik.&rdquo; Itulah
                    sebuah pesan yang tak absen untuk terucap. Sambil mengelus
                    rambut kedua putrinya, tangan satunya merogoh kantong
                    pinggir. Mencari uang recehan sebagai uang jajan. Setelah
                    menemukan dua lembar uang kertas dua ribuan, ia
                    menyodorkannya. Kedua putrinya dengan senang hati menerima
                    pemberian ayahnya.
                  </p>

                  <p>
                    &ldquo;Hati-hati di jalan, bapak. Ina sayang bapak. Kakak
                    Isya juga, kan?&rdquo; tanyanya sambil menoleh ke Aisya.
                  </p>

                  <p>
                    &ldquo;Kak Isya sayaaaaaang banget sama Ina, bapak, dan
                    ibu.&rdquo; jawabnya sambil mencium tangan bapak. Ina pun
                    mengikutinya. Bapak tersenyum haru.
                  </p>

                  <p>&ldquo;Assalamualaikum.&rdquo;</p>

                  <p>
                    &ldquo;Waalaikumsalam, bapak.&rdquo; Ina dan Isya
                    melambaikan tangannya.
                  </p>

                  <p>
                    Motor bapak meliuk-liuk memilah jalan yang tak ada kubangan
                    air. Gang yang sempit juga memperlambat perjalanan ketika
                    berpapasan dengan kendaraan lain. Tiba di jalan raya yang
                    mulus, bapak bisa mempercepat kendaraannya.
                  </p>

                  <p>Di balik helm tua, matanya jauh menerawang ke depan.</p>

                  <p>***</p>

                  <p>
                    Kakek Harjo terbatuk-batuk di singgasana bambu depan
                    rumahnya. Kulitnya yang keriput pertanda sudah menua.
                    Matanya yang sayup sesekali melirik jalanan di sebelah
                    kanan. Menunggu kedatangan kedua cucunya yang mampu
                    menghidupkan suasana.
                  </p>

                  <p>
                    Baju-baju yang baru saja bermandikan air kini sedang
                    diperas, dikibas, dijemur di halaman rumah. Nenek Harjo yang
                    melakukannya. Meski sudah tua, energinya seakan masih segar.
                    Badannya yang kurus membuat langkahnya semakin ringan.
                    Selesai menjemur, ia duduk di samping suaminya. Turut
                    membersamai menikmati sejuknya udara pagi.
                  </p>

                  <p>
                    Dua gadis berjalan beriringan di pinggir jalan. Semakin
                    dekat semakin terlihat rumah simbah. Aisya terlihat membawa
                    tas yang digantungkan di lengan kirinya. Isinya tak lain
                    adalah buku catatan yang digunakan untuk sekolah
                    <em>online</em>. Kebetulan dalam perihal sinyal, rumah
                    simbah juaranya. Begitu melihat kedua cucunya datang, nenek
                    segera masuk ke dalam rumah. Ia kembali dengan membawa
                    sepiring pisang goreng yang masih hangat. Aisya dan Inayah
                    pun mencium tangan simbahnya.
                  </p>

                  <p>
                    &ldquo;Tadi bapak masak apa, nak?&rdquo; Tanya nenek sembari
                    mendudukan Inayah di pahanya.
                  </p>

                  <p>
                    &ldquo;Bapak masakin nasi goreng yang enaaaaak bangett lho,
                    nek.&rdquo; Jawab Inayah dengan berbinar-binar. Wajahnya
                    yang polos seakan tak merasakan apapun penderitaan
                    keluarganya. Segala hal sederhana ia terima dengan perasaan
                    yang luar biasa.
                  </p>

                  <p>
                    &ldquo;Waaah, besok nenek minta buatin, ya! Nih makan dulu
                    pisang gorengnya.&rdquo; Nenek mengambilkan pisang goreng
                    kemudian menyuapkannya pada Inayah. Aisya ikut mencomot
                    satu, kemudian masuk ke dalam rumah.
                  </p>

                  <p>
                    Menjelang pukul 9 pagi, kakek pergi menjajakan dagangannya.
                    Ia berjualan peralatan rumah tangga seperti sapu, pel,
                    panci, dan seabrek lainnya. Sedangkan nenek menggeluti
                    pekerjaan menganyam kain dari menjelang siang hingga sore.
                    Nenek melakukannya di samping rumah beralaskan rerumputan
                    berpayungkan pepohonan. Selagi masih bisa, mereka tak bisa
                    dilarang untuk bekerja. Meskipun berulang kali dirayu agar
                    duduk manis menikmati masa tuanya di rumah, katanya sangat
                    jengah.
                  </p>

                  <p>
                    Mau di rumah sendiri, sangat sepi. Mau di rumah simbah pun
                    tetap sama rasa sepinya. Itulah yang dirasakan Aisya dan
                    Inayah. Selesai belajar, dua gadis itu pun membantu neneknya
                    menganyam kain. Padahal, sebanyak apapun aktivitas yang
                    dilakukan, menunggu bapak pulang bersama senja adalah hal
                    terfavorit.
                  </p>

                  <p>***</p>

                  <p>
                    Semilir angin di siang hari menghipnotis mata untuk
                    terlelap. Jikalau sultan bisa saja langsung saja rebahan.
                    Tapi bagaimana dengan seorang buruh yang gajinya pas-pasan?
                    Pastilah tertidur semasa bekerja hanya akan mengundang
                    bumerang.
                  </p>

                  <p>
                    Seorang bapak dari kedua putri itu membasuh mukanya di kran
                    yang alirannya saja tak lancar. Ia berjalan menuju kursi
                    kayu yang sudah sedikit reyot kemudian mendudukinya. Sambil
                    memandang awan yang biru, pikirannya melayang ke rumah.
                    Memikirkan apakah kedua putrinya sudah makan? Apakah sudah
                    mengerjakan tugas sekolah? Apakah manut sama kakek neneknya?
                    Ingin hati setiap hari membanjiri dengan kasih sayang 24
                    jam, tapi apa daya tuntutan perekonomian membuatnya tertahan
                    dalam pekerjaan.
                  </p>

                  <p>
                    Jam menunjukkan pukul 1 siang. Saatnya bapak memulai kembali
                    pekerjaannya. Tetes demi tetes keringat merembes tak terasa
                    dalam bajunya. Semua dilakukan demi upah tak seberapa, tapi
                    selalu ia ingin gunakan untuk membahagiakan keluarga
                    kecilnya. Rampung bekerja, bapak segera bergegas pulang.
                    Perjalanan untuk sampai ke rumah memakan waktu sekitar 1
                    jam.
                  </p>

                  <p>
                    Tepat saat bapak memikirkan buah tangan apa untuk Aisya dan
                    Inayah, dari kejauhan terlihat keramaian dari alun-alun.
                    Macet yang tidak bisa dihindari pun memaksanya untuk
                    berhenti. Pantas saja, hari pertama bazar membuat pecinta
                    kuliner, pemburu <em>fashion</em>, penyuka buku, penyanyi
                    jalanan, menyerbu tempat ini. Bapak yang biasanya tidak suka
                    keramaian seperti ini sulit sekali ketika dulu diajak
                    istrinya berkeliling. Namun, kini berbeda. Demi kedua
                    putrinya, ia pun menyempatkan mampir. Berjalan sendirian,
                    berpusing ria, berdesakan dengan pengunjung lainnya untuk
                    membelikan oleh-oleh meski mungkin nanti yang didapat
                    hanyalah jajanan sederhana.
                  </p>

                  <p>
                    Di <em>stand</em> paling pojok utara, ia melihat bazar buku
                    murah. Ia teringat kedua putrinya yang sangat suka membaca
                    buku, terutama Aisya. Hampir setengah jam digunakannya untuk
                    memilah milih. Setelah didapatinya buku yang pas, ia
                    membayarnya. Melintasi toko seberang, hatinya terpikat pada
                    salah satu sandal bermotif warna hitam. Rasa hati ingin
                    membeli, tapi <em>budget</em> tak mencukupi jika harus
                    ditambah dengan jajanan seblak kesukaan anaknya. Ia pun
                    memendam keinginannya lalu membeli seblak di pinggir jalan
                    sekalian pulang. Hari sudah mulai menggelap. Lelah semakin
                    menghinggap. Jalanan memburam.
                  </p>

                  <p>***</p>

                  <p>
                    Siang mulai luntur. Aisya dan Inayah segera pulang ke rumah.
                    Di bangku teras, mereka menyaksikan senja dan bersiap
                    menyambut bapaknya pulang. Ada banyak hal yang ingin mereka
                    ceritakan hari ini. Azan maghrib berkumandang. Bapak belum
                    juga pulang. Was-was mulai menghadang. Aisya sebagai seorang
                    kakak pun tak tega melihat adiknya yang terus-terusan
                    digigit nyamuk. Aisya menyuruh Ina bergantian mandi. Namun,
                    hingga mereka berdua selesai mandi dan sholat pun, tak ada
                    tanda-tanda kepulangan bapak. Nomor pun tak aktif.
                  </p>

                  <p>
                    Heningnya malam yang pekat mulai mencekam. Inayah mulai
                    merengek, katanya ngantuk. Ina pun tertidur. Aisya masih
                    terjaga. Dinyalakannya televisi meskipun acaranya pun tak
                    dinikmati. Sesekali ia pun melongok jendela depan. Jika
                    nanti pukul 9 malam bapak tak kunjung datang, ia akan
                    mengabari simbahnya. Namun, tak dapat disangkal bahwa kantuk
                    menghampiri sebelum jam 9. Aisya gagal menahan matanya untuk
                    tetap terbuka. Seharian ini mereka berdua lebih banyak
                    membantu neneknya melakukan pekerjaan. Lelah tak
                    tertahankan.
                  </p>

                  <p>
                    Silih pun berganti. Saat kedua anak itu terlelap, deru motor
                    dari kejauhan terdengar semakin mendekat. Ia adalah bapak
                    dari Aisya dan Inayah. Segera diparkirkan motornya di
                    samping rumah. Ia sudah tak sabar bertemu anaknya,
                    memberikan oleh-oleh, dan utamanya meminta maaf karena
                    pulang larut malam. Tangannya meraih gagang pintu. Ternyata
                    terkunci. Dapat ditebak bahwa putrinya sudah tidur karena
                    mereka tak biasa tidur larut malam. Sebenarnya ia bisa saja
                    mengetuk berulang kali. Namun, ia lebih memilih untuk di
                    luar saja.
                  </p>

                  <p>
                    Pagi pun menjelang. Azan subuh berkumandang. Bapak terbangun
                    dari tidurnya. Ia tahu bahwa kedua putrinya pasti sebentar
                    lagi bangun. Diketuknya pintu depan. Aisya yang mendengar
                    langsung membukanya. Begitu Aisya tahu bahwa bapaknya
                    pulang, Ina pun dipanggil. Mereka berpelukan.
                  </p>

                  <p>
                    &ldquo;Bapak kok baru pulang? Aisya sama Ina khawatir,
                    tau.&rdquo; Kata Aisya.
                  </p>

                  <p>
                    &ldquo;Maafin bapak, nak. Nanti bapak jelasin. Sholat subuh
                    dulu, yuk.&rdquo; Bapak pun lebih dulu ke belakang untuk
                    mengambil air wudhu.
                  </p>

                  <p>
                    Setelah sholat berjamaah, Aisya dan Inayah mencium tangan
                    bapaknya. Masih memakai mukena, mereka berdua mengoceh
                    tentang hari kemarin dari pagi hingga malam. Bapak hanya
                    mendengarkan sembari tersenyum. Sungguh pendengar yang baik.
                    Saat anaknya berhenti berbicara, bapak pun menjelaskan
                    perihal pulang malam. Katanya, saat pulang dari tempat
                    bazar, ternyata ban motornya bocor. Penerangan jalan yang
                    temaram tak cukup jelas untuk mendeteksi adanya paku. Ia
                    berjalan cukup jauh mencari tambal ban, belum lagi antrian
                    panjang. Mau menghubungi pun teleponnya mati, nomor anaknya
                    tak hafal.
                  </p>

                  <p>
                    &ldquo;Bapak hanya berdoa semoga kalian aman saja di rumah.
                    Setelah bapak pulang, ternyata rumah terkunci. Bapak senang
                    kalian sangat berhari-hati.&ldquo; Kata bapak.
                  </p>

                  <p>
                    &ldquo;Tapi pak, maafin Aisya. Bapak jadi tidur di luar.
                    Kenapa ngga digedor aja pintunya. Isya pasti denger
                    kok.&rdquo; Raut mukanya sedih.
                  </p>

                  <p>
                    &ldquo;Ngga papa, nak. Bapak ngga ingin waktu tidur kalian
                    terganggu. Tidur di luar juga enak, kok. Udaranya
                    segar.&rdquo; Jawab bapak samil mengibas-ibaskan tangannya.
                    Padahal, dirinya saat itu mulai merasakan masuk angin.
                  </p>

                  <p>
                    &ldquo;Itu apa, pak?&rdquo; tanya Ina sambil menunjuk
                    keresek warna hitam di meja. Bapak pun mengambilnya. Aisya
                    dan Inaya berebut untuk membukanya.
                  </p>

                  <p>
                    &ldquo;<em>Surprise</em>&hellip;&rdquo; Begitu kata bapak
                    setelah isinya terbuka. Mata kedua anaknya berbinar.
                  </p>

                  <p>
                    &ldquo;Waah.. Ina suka banget pak, bukunya keren. Iya kan,
                    kak?&rdquo; tanyanya sambil menoleh Isya.
                  </p>

                  <p>
                    &ldquo;Iya nih, Isya juga suka banget jadi nambah bahan
                    bacaan. Makasih, bapak.&rdquo;
                  </p>

                  <p>&ldquo;Makasih bapak, sayang bapak.&rdquo; Timpal Ina.</p>

                  <p>
                    &ldquo;Jadi kemarin bapak cuma beli ini jadi pulang
                    terlambat? Isya jadi merasa bersalah udah
                    <em>suudzon</em> ke bapak karena pulang malam.&rdquo;
                  </p>

                  <p>
                    &ldquo;Sssstt, ngga ada yang salah. Bapak kan pengin
                    nyenengin putri bapak. Emang ngga boleh? Udah sekarang bapak
                    mau mandi dulu, ya.&rdquo; Bapak pun beranjak dari lantai.
                    Setelah itu, ia diam-diam menyembunyikan seblak yang kemarin
                    dibeli untuk kedua putrinya. Dimakannya sendiri di dapur
                    karena tak ingin memberikan seblak yang sudah mendingin,
                    cita rasa pun sudah tak lagi sama dengan kemarin.
                  </p>

                  <p>
                    Betapa sayangnya seorang ayah terhadap anaknya, tak selalu
                    terucap dalam rangkaian kata yang indah. Hanya saja,
                    siapapun anak yang disayangi oleh seorang ayah, pasti dengan
                    sendirinya akan merasakan secara nyata betapa melimpahnya
                    kasih sayang yang ia dapatkan.
                  </p>

                  <p>Penulis: APRILIA FERDIANA</p>
                </div>
              </div>
              <div class="media ava-bloger">
                <img
                  class="mr-3 rounded-circle img-thumbnail"
                  src="https://eurekabookhouse.co.id/assets/uplod/profile/04112020_1586576240.png"
                  alt="Generic placeholder image"
                />
                <div class="media-body">
                  <p class="stext-101 m-b-8">DITULIS OLEH</p>
                  <h5 class="mt-0 mtext-111">Eureka Writer</h5>
                  <p class="badge badge-primary">Content Writer</p>
                  <p class="stext-107">Content Writter eurekabookhouse.co.id</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="sec-relate-product bg6 p-t-20 text-left">
      <div class="container p-tb-10">
        <div class="p-b-15">
          <h3 class="session-title mtext-106 cl5">New Entry</h3>
        </div>
        <div class="row">
          <div class="col-sm-6 col-md-3 p-b-20">
            <div class="blog-item bg0 bor8">
              <div class="blog-pic hov-img0">
                <a
                  href="https://www.eurekabookhouse.co.id/blog/read/kisah-kasih---juara-favorit-10-lomba-cerpen-eureka-bookhouse"
                >
                  <img
                    class="blog-read-pic"
                    src="https://cdn.eurekabookhouse.co.id/ebh/blog/13-min.jpg"
                  />
                </a>
              </div>
              <div class="p-all-15">
                <div class="stext-112 flex-w p-b-14 cl1">
                  <a
                    href="https://www.eurekabookhouse.co.id/blog/category/sahabat-eureka"
                    >Sahabat Eureka</a
                  >
                </div>
                <h4 class="p-b-32">
                  <a
                    href="https://www.eurekabookhouse.co.id/blog/read/kisah-kasih---juara-favorit-10-lomba-cerpen-eureka-bookhouse"
                    class="mtext-101 cl2 hov-cl1 trans-04"
                  >
                    KISAH KASIH - JUARA FAVORIT 10 LOMBA CERPEN EUREKA BOOKHOUSE
                  </a>
                </h4>
                <span class="stext-102 m-t-20">12 November 2022</span>
              </div>
            </div>
          </div>
          <div class="col-sm-6 col-md-3 p-b-20">
            <div class="blog-item bg0 bor8">
              <div class="blog-pic hov-img0">
                <a
                  href="https://www.eurekabookhouse.co.id/blog/read/rahasia-delia---juara-favorit-9-lomba-cerpen-eureka-bookhouse"
                >
                  <img
                    class="blog-read-pic"
                    src="https://cdn.eurekabookhouse.co.id/ebh/blog/12-min.jpg"
                  />
                </a>
              </div>
              <div class="p-all-15">
                <div class="stext-112 flex-w p-b-14 cl1">
                  <a
                    href="https://www.eurekabookhouse.co.id/blog/category/sahabat-eureka"
                    >Sahabat Eureka</a
                  >
                </div>
                <h4 class="p-b-32">
                  <a
                    href="https://www.eurekabookhouse.co.id/blog/read/rahasia-delia---juara-favorit-9-lomba-cerpen-eureka-bookhouse"
                    class="mtext-101 cl2 hov-cl1 trans-04"
                  >
                    RAHASIA DELIA - JUARA FAVORIT 9 LOMBA CERPEN EUREKA
                    BOOKHOUSE
                  </a>
                </h4>
                <span class="stext-102 m-t-20">12 November 2022</span>
              </div>
            </div>
          </div>
          <div class="col-sm-6 col-md-3 p-b-20">
            <div class="blog-item bg0 bor8">
              <div class="blog-pic hov-img0">
                <a
                  href="https://www.eurekabookhouse.co.id/blog/read/pohon-mangga-itu-berbuah-kembali---juara-favorit-8-lomba-cerpen-eureka-bookhouse"
                >
                  <img
                    class="blog-read-pic"
                    src="https://cdn.eurekabookhouse.co.id/ebh/blog/11-min.jpg"
                  />
                </a>
              </div>
              <div class="p-all-15">
                <div class="stext-112 flex-w p-b-14 cl1">
                  <a
                    href="https://www.eurekabookhouse.co.id/blog/category/sahabat-eureka"
                    >Sahabat Eureka</a
                  >
                </div>
                <h4 class="p-b-32">
                  <a
                    href="https://www.eurekabookhouse.co.id/blog/read/pohon-mangga-itu-berbuah-kembali---juara-favorit-8-lomba-cerpen-eureka-bookhouse"
                    class="mtext-101 cl2 hov-cl1 trans-04"
                  >
                    POHON MANGGA ITU BERBUAH KEMBALI - JUARA FAVORIT 8 LOMBA
                    CERPEN EUREKA BOOKHOUSE
                  </a>
                </h4>
                <span class="stext-102 m-t-20">11 November 2022</span>
              </div>
            </div>
          </div>
          <div class="col-sm-6 col-md-3 p-b-20">
            <div class="blog-item bg0 bor8">
              <div class="blog-pic hov-img0">
                <a
                  href="https://www.eurekabookhouse.co.id/blog/read/malaikat-penjagaku---juara-favorit-7-lomba-cerpen-eureka-bookhouse"
                >
                  <img
                    class="blog-read-pic"
                    src="https://cdn.eurekabookhouse.co.id/ebh/blog/10-min.jpg"
                  />
                </a>
              </div>
              <div class="p-all-15">
                <div class="stext-112 flex-w p-b-14 cl1">
                  <a
                    href="https://www.eurekabookhouse.co.id/blog/category/sahabat-eureka"
                    >Sahabat Eureka</a
                  >
                </div>
                <h4 class="p-b-32">
                  <a
                    href="https://www.eurekabookhouse.co.id/blog/read/malaikat-penjagaku---juara-favorit-7-lomba-cerpen-eureka-bookhouse"
                    class="mtext-101 cl2 hov-cl1 trans-04"
                  >
                    MALAIKAT PENJAGAKU - JUARA FAVORIT 7 LOMBA CERPEN EUREKA
                    BOOKHOUSE
                  </a>
                </h4>
                <span class="stext-102 m-t-20">11 November 2022</span>
              </div>
            </div>
          </div>
          <div class="col-sm-6 col-md-3 p-b-20">
            <div class="blog-item bg0 bor8">
              <div class="blog-pic hov-img0">
                <a
                  href="https://www.eurekabookhouse.co.id/blog/read/kenangan-tentang-ayah---juara-favorit-6-lomba-cerpen-eureka-bookhouse"
                >
                  <img
                    class="blog-read-pic"
                    src="https://cdn.eurekabookhouse.co.id/ebh/blog/9-min.jpg"
                  />
                </a>
              </div>
              <div class="p-all-15">
                <div class="stext-112 flex-w p-b-14 cl1">
                  <a
                    href="https://www.eurekabookhouse.co.id/blog/category/sahabat-eureka"
                    >Sahabat Eureka</a
                  >
                </div>
                <h4 class="p-b-32">
                  <a
                    href="https://www.eurekabookhouse.co.id/blog/read/kenangan-tentang-ayah---juara-favorit-6-lomba-cerpen-eureka-bookhouse"
                    class="mtext-101 cl2 hov-cl1 trans-04"
                  >
                    KENANGAN TENTANG AYAH - JUARA FAVORIT 6 LOMBA CERPEN EUREKA
                    BOOKHOUSE
                  </a>
                </h4>
                <span class="stext-102 m-t-20">11 November 2022</span>
              </div>
            </div>
          </div>
          <div class="col-sm-6 col-md-3 p-b-20">
            <div class="blog-item bg0 bor8">
              <div class="blog-pic hov-img0">
                <a
                  href="https://www.eurekabookhouse.co.id/blog/read/segenggam-tanah-merah---juara-favorit-5-lomba-cerpen-eureka-bookhouse"
                >
                  <img
                    class="blog-read-pic"
                    src="https://cdn.eurekabookhouse.co.id/ebh/blog/8-min.jpg"
                  />
                </a>
              </div>
              <div class="p-all-15">
                <div class="stext-112 flex-w p-b-14 cl1">
                  <a
                    href="https://www.eurekabookhouse.co.id/blog/category/sahabat-eureka"
                    >Sahabat Eureka</a
                  >
                </div>
                <h4 class="p-b-32">
                  <a
                    href="https://www.eurekabookhouse.co.id/blog/read/segenggam-tanah-merah---juara-favorit-5-lomba-cerpen-eureka-bookhouse"
                    class="mtext-101 cl2 hov-cl1 trans-04"
                  >
                    SEGENGGAM TANAH MERAH - JUARA FAVORIT 5 LOMBA CERPEN EUREKA
                    BOOKHOUSE
                  </a>
                </h4>
                <span class="stext-102 m-t-20">11 November 2022</span>
              </div>
            </div>
          </div>
          <div class="col-sm-6 col-md-3 p-b-20">
            <div class="blog-item bg0 bor8">
              <div class="blog-pic hov-img0">
                <a
                  href="https://www.eurekabookhouse.co.id/blog/read/jejak-sandal-ayah---juara-favorit-4-lomba-cerpen-eureka-bookhouse"
                >
                  <img
                    class="blog-read-pic"
                    src="https://cdn.eurekabookhouse.co.id/ebh/blog/7-min.jpg"
                  />
                </a>
              </div>
              <div class="p-all-15">
                <div class="stext-112 flex-w p-b-14 cl1">
                  <a
                    href="https://www.eurekabookhouse.co.id/blog/category/sahabat-eureka"
                    >Sahabat Eureka</a
                  >
                </div>
                <h4 class="p-b-32">
                  <a
                    href="https://www.eurekabookhouse.co.id/blog/read/jejak-sandal-ayah---juara-favorit-4-lomba-cerpen-eureka-bookhouse"
                    class="mtext-101 cl2 hov-cl1 trans-04"
                  >
                    JEJAK SANDAL AYAH - JUARA FAVORIT 4 LOMBA CERPEN EUREKA
                    BOOKHOUSE
                  </a>
                </h4>
                <span class="stext-102 m-t-20">12 November 2022</span>
              </div>
            </div>
          </div>
          <div class="col-sm-6 col-md-3 p-b-20">
            <div class="blog-item bg0 bor8">
              <div class="blog-pic hov-img0">
                <a
                  href="https://www.eurekabookhouse.co.id/blog/read/perjuangan-ayahku--untuk-masa-depanku---juara-favorit-3-lomba-cerpen-eureka-bookhouse"
                >
                  <img
                    class="blog-read-pic"
                    src="https://cdn.eurekabookhouse.co.id/ebh/blog/6-min.jpg"
                  />
                </a>
              </div>
              <div class="p-all-15">
                <div class="stext-112 flex-w p-b-14 cl1">
                  <a
                    href="https://www.eurekabookhouse.co.id/blog/category/acara-toko"
                    >Acara Toko</a
                  >
                </div>
                <h4 class="p-b-32">
                  <a
                    href="https://www.eurekabookhouse.co.id/blog/read/perjuangan-ayahku--untuk-masa-depanku---juara-favorit-3-lomba-cerpen-eureka-bookhouse"
                    class="mtext-101 cl2 hov-cl1 trans-04"
                  >
                    PERJUANGAN AYAHKU, UNTUK MASA DEPANKU - JUARA FAVORIT 3
                    LOMBA CERPEN EUREKA BOOKHOUSE
                  </a>
                </h4>
                <span class="stext-102 m-t-20">12 November 2022</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <footer class="bgu1 p-t-15 p-b-15">
      <div class="container">
        <div class="p-t-10">
          <p class="stext-107 cl0 txt-center">
            Copyright &copy;Dikembangkan oleh Eureka IT Developer
          </p>
        </div>
      </div>
    </footer>
  </div>
</template>
<script scoped>
import Navbar from "../Nav.vue";
export default {
  name: "senjaPage",
  components: {
    Navbar,
  },
};
</script>

<template>
  <div class="bg-gray-100 min-h-screen">
    <!-- Navbar Component -->
    <Navbar />

    <!-- Blog Section -->
    <section class="max-w-6xl mx-auto py-10 bg-blog-background">
      <div class="text-center mb-6">
        <p class="text-p mt-2">
          Kumpulan informasi menarik yang telah disajikan oleh tim
        </p>
        <h1 class="text-3xl font-bold mb-2 mt-3">Eureka Bookhouse</h1>
      </div>
      <div class="mb-6 mt-4 flex justify-center">
        <div class="mb-6 mt-4 flex justify-center">
          <select
            class="px-2 py-2 border border-white rounded-lg bg-transparent text-white focus:outline-none focus:ring-2 focus:ring-white"
            @change="handleCategoryChange($event)"
          >
            <option value="">Kategori</option>
            <option value="/Blog/acaratoko">Acara Toko</option>
            <option value="/Blog/rekomondasi">Rekomendasi Buku</option>
            <option value="/Blog/promosi">Promosi</option>
            <option value="/Blog/review">Review Buku</option>
            <option value="/Blog/triva">Trivia</option>
            <option value="/Blog/fiksi">Fiksi</option>
            <option value="/Blog/nonfiksi">Non-Fiksi</option>
            <option value="/Blog/date">Update</option>
            <option value="/Blog/allblog">Sahabat Eureka</option>
          </select>
        </div>
      </div>
    </section>

    <section class="bg0 p-t-20 text-left" style="min-height: 100%">
      <div class="container">
        <h3 class="ltext-101 p-tb-20">Rekomendasi Buku</h3>
        <div class="row">
          <div class="col-sm-6 col-md-3 p-b-20">
            <div class="blog-item bg0 bor8">
              <div class="blog-pic hov-img0">
                <router-link :to="'/Blog/rekomondasibuku/1'">
                  <img
                    class="blog-read-pic"
                    src="https://cdn.eurekabookhouse.co.id/ebh/blog/Apa_Saja_Jenis_Buku_Generasi_Milenial_yang_Paling_Diminati.jpg"
                  />
                </router-link>
              </div>
              <div class="p-all-15">
                <div class="stext-112 flex-w p-b-14 cl1">
                  <router-link :to="'/Blog/rekomondasibuku/1'"
                    >Rekomendasi Buku</router-link
                  >
                </div>
                <h4 class="p-b-32">
                  <router-link
                    :to="'/Blog/rekomondasibuku/1'"
                    class="mtext-101 cl2 hov-cl1 trans-04"
                  >
                    Apa Saja Jenis Buku Generasi Milenial yang Paling Diminati?
                  </router-link>
                </h4>
                <span class="stext-102 m-t-20">11 November 2022</span>
              </div>
            </div>
          </div>
          <div class="col-sm-6 col-md-3 p-b-20">
            <div class="blog-item bg0 bor8">
              <div class="blog-pic hov-img0">
                <router-link :to="'/Blog/rekomondasibuku/2'">
                  <img
                    class="blog-read-pic"
                    src="https://cdn.eurekabookhouse.co.id/ebh/blog/valentines-day.jpg"
                  />
                </router-link>
              </div>
              <div class="p-all-15">
                <div class="stext-112 flex-w p-b-14 cl1">
                  <router-link :to="'/Blog/rekomondasibuku/2'"
                    >Rekomendasi Buku</router-link
                  >
                </div>
                <h4 class="p-b-32">
                  <router-link
                    :to="'/Blog/rekomondasibuku/2'"
                    class="mtext-101 cl2 hov-cl1 trans-04"
                  >
                    Lalui Februari dengan novel cinta nan romantis ini
                  </router-link>
                </h4>
                <span class="stext-102 m-t-20">10 November 2022</span>
              </div>
            </div>
          </div>
          <div class="col-sm-6 col-md-3 p-b-20">
            <div class="blog-item bg0 bor8">
              <div class="blog-pic hov-img0">
                <router-link :to="'/Blog/rekomondasibuku/3'">
                  <img
                    class="blog-read-pic"
                    src="https://cdn.eurekabookhouse.co.id/ebh/blog/siswa-smk-57e49410719373ab26bf8458.jpg"
                  />
                </router-link>
              </div>
              <div class="p-all-15">
                <div class="stext-112 flex-w p-b-14 cl1">
                  <router-link :to="'/Blog/rekomondasibuku/3'"
                    >Rekomendasi Buku</router-link
                  >
                </div>
                <h4 class="p-b-32">
                  <router-link
                    :to="'/Blog/rekomondasibuku/3'"
                    class="mtext-101 cl2 hov-cl1 trans-04"
                  >
                    10 Buku SMK Best Seller Januari 2019
                  </router-link>
                </h4>
                <span class="stext-102 m-t-20">11 November 2022</span>
              </div>
            </div>
          </div>
          <div class="col-sm-6 col-md-3 p-b-20">
            <div class="blog-item bg0 bor8">
              <div class="blog-pic hov-img0">
                <router-link :to="'/Blog/rekomondasibuku/4'">
                  <img
                    class="blog-read-pic"
                    src="https://cdn.eurekabookhouse.co.id/ebh/blog/Siswa-Belajar.png"
                  />
                </router-link>
              </div>
              <div class="p-all-15">
                <div class="stext-112 flex-w p-b-14 cl1">
                  <router-link :to="'/Blog/rekomondasibuku/4'"
                    >Rekomendasi Buku</router-link
                  >
                </div>
                <h4 class="p-b-32">
                  <router-link
                    :to="'/Blog/rekomondasibuku/4'"
                    class="mtext-101 cl2 hov-cl1 trans-04"
                  >
                    Buku SD Terlaris / Best Seller Januari 2019
                  </router-link>
                </h4>
                <span class="stext-102 m-t-20">11 November 2022</span>
              </div>
            </div>
          </div>
          <div class="col-sm-6 col-md-3 p-b-20">
            <div class="blog-item bg0 bor8">
              <div class="blog-pic hov-img0">
                <router-link :to="'/Blog/rekomondasibuku/5'">
                  <img
                    class="blog-read-pic"
                    src="https://cdn.eurekabookhouse.co.id/ebh/blog/1040157-riz--hari-pertama-ujian-nasional--780x390.jpg"
                  />
                </router-link>
              </div>
              <div class="p-all-15">
                <div class="stext-112 flex-w p-b-14 cl1">
                  <router-link :to="'/Blog/rekomondasibuku/5'"
                    >Rekomendasi Buku</router-link
                  >
                </div>
                <h4 class="p-b-32">
                  <router-link
                    :to="'/Blog/rekomondasibuku/5'"
                    class="mtext-101 cl2 hov-cl1 trans-04"
                  >
                    Inilah Best Seller Buku Soal Januari 2019
                  </router-link>
                </h4>
                <span class="stext-102 m-t-20">11 November 2022</span>
              </div>
            </div>
          </div>
          <div class="col-sm-6 col-md-3 p-b-20">
            <div class="blog-item bg0 bor8">
              <div class="blog-pic hov-img0">
                <router-link :to="'/Blog/rekomondasibuku/6'">
                  <img
                    class="blog-read-pic"
                    src="https://cdn.eurekabookhouse.co.id/ebh/blog/2014001631.jpg"
                  />
                </router-link>
              </div>
              <div class="p-all-15">
                <div class="stext-112 flex-w p-b-14 cl1">
                  <router-link :to="'/Blog/rekomondasibuku/6'"
                    >Rekomendasi Buku</router-link
                  >
                </div>
                <h4 class="p-b-32">
                  <router-link
                    :to="'/Blog/rekomondasibuku/6'"
                    class="mtext-101 cl2 hov-cl1 trans-04"
                  >
                    Buku TK Best seller Januari 2019
                  </router-link>
                </h4>
                <span class="stext-102 m-t-20">12 November 2022</span>
              </div>
            </div>
          </div>
          <div class="col-sm-6 col-md-3 p-b-20">
            <div class="blog-item bg0 bor8">
              <div class="blog-pic hov-img0">
                <router-link :to="'/Blog/rekomondasibuku/7'">
                  <img
                    class="blog-read-pic"
                    src="https://cdn.eurekabookhouse.co.id/ebh/blog/0035100331.jpg"
                  />
                </router-link>
              </div>
              <div class="p-all-15">
                <div class="stext-112 flex-w p-b-14 cl1">
                  <router-link :to="'/Blog/rekomondasibuku/7'"
                    >Rekomendasi Buku</router-link
                  >
                </div>
                <h4 class="p-b-32">
                  <router-link
                    :to="'/Blog/rekomondasibuku/7'"
                    class="mtext-101 cl2 hov-cl1 trans-04"
                  >
                    Buku SMP Best seller Januari 2019
                  </router-link>
                </h4>
                <span class="stext-102 m-t-20">10 November 2022</span>
              </div>
            </div>
          </div>
          <div class="col-sm-6 col-md-3 p-b-20">
            <div class="blog-item bg0 bor8">
              <div class="blog-pic hov-img0">
                <router-link :to="'/Blog/rekomondasibuku/8'">
                  <img
                    class="blog-read-pic"
                    src="https://cdn.eurekabookhouse.co.id/ebh/blog/0045400360.jpg"
                  />
                </router-link>
              </div>
              <div class="p-all-15">
                <div class="stext-112 flex-w p-b-14 cl1">
                  <router-link :to="'/Blog/rekomondasibuku/8'"
                    >Rekomendasi Buku</router-link
                  >
                </div>
                <h4 class="p-b-32">
                  <router-link
                    :to="'/Blog/rekomondasibuku/8'"
                    class="mtext-101 cl2 hov-cl1 trans-04"
                  >
                    Buku SMA Best seller Januari 2019
                  </router-link>
                </h4>
                <span class="stext-102 m-t-20">11 November 2022</span>
              </div>
            </div>
          </div>
          <div class="col-sm-6 col-md-3 p-b-20">
            <div class="blog-item bg0 bor8">
              <div class="blog-pic hov-img0">
                <router-link :to="'/Blog/rekomondasibuku/9'">
                  <img
                    class="blog-read-pic"
                    src="https://cdn.eurekabookhouse.co.id/ebh/blog/mengajarkan-anak.jpg"
                  />
                </router-link>
              </div>
              <div class="p-all-15">
                <div class="stext-112 flex-w p-b-14 cl1">
                  <router-link :to="'/Blog/rekomondasibuku/9'"
                    >Rekomendasi Buku</router-link
                  >
                </div>
                <h4 class="p-b-32">
                  <router-link
                    :to="'/Blog/rekomondasibuku/9'"
                    class="mtext-101 cl2 hov-cl1 trans-04"
                  >
                    Sepuluh Buku Anak Terlaris Januari 2019
                  </router-link>
                </h4>
                <span class="stext-102 m-t-20">12 November 2022</span>
              </div>
            </div>
          </div>
          <div class="col-sm-6 col-md-3 p-b-20">
            <div class="blog-item bg0 bor8">
              <div class="blog-pic hov-img0">
                <router-link :to="'/Blog/rekomondasibuku/10'">
                  <img
                    class="blog-read-pic"
                    src="https://cdn.eurekabookhouse.co.id/ebh/blog/1040157-riz--hari-pertama-ujian-nasional--780x390.jpg"
                  />
                </router-link>
              </div>
              <div class="p-all-15">
                <div class="stext-112 flex-w p-b-14 cl1">
                  <router-link :to="'/Blog/rekomondasibuku/10'"
                    >Rekomendasi Buku</router-link
                  >
                </div>
                <h4 class="p-b-32">
                  <router-link
                    :to="'/Blog/rekomondasibuku/10'"
                    class="mtext-101 cl2 hov-cl1 trans-04"
                  >
                    Tryout bersama Erlangga
                  </router-link>
                </h4>
                <span class="stext-102 m-t-20">10 November 2022</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- Footer -->
    <footer class="bgu1 p-t-15 p-b-15">
      <div class="container">
        <div class="p-t-10">
          <p class="stext-107 cl0 txt-center">
            Copyright &copy;Dikembangkan oleh Eureka IT Developer
          </p>
        </div>
      </div>
    </footer>
  </div>
</template>

<script scoped>
import Navbar from "../Nav.vue";
export default {
  name: "rekomondasiPage",
  components: {
    Navbar,
  },

  methods: {
    handleCategoryChange(event) {
      const selectedValue = event.target.value;
      if (selectedValue) {
        this.$router.push(selectedValue);
      }
    },
  },
};
</script>

<style scoped>
.bg-blog-background {
  background-image: url("../../assets/images/bg_blog.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding: 40px 20px;
  color: white;
  font-weight: bold;
}
.text-p {
  color: #acacac;
}

select {
  transition: all 0.3s ease;
}
select:hover {
  background-color: rgba(255, 255, 255, 0.1);
}
select option {
  background-color: #1f2937; /* sama seperti bg-gray-800 */
  color: white;
}
</style>

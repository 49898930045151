<template>
  <div style="position: relative; background: black">
    <img
      src="../assets/images/footer/footer_kiri.png"
      alt="Left Logo"
      style="width: 228px; height: 249px; position: absolute"
      class="d-xl-block d-lg-none d-md-none d-sm-none d-none"
    />

    <img
      src="../assets/images/footer/footer_kanan.png"
      alt="Left Logo"
      style="
        width: 556px;
        height: 557px;
        position: absolute;
        top: 15px;
        right: 10px;
      "
      class="d-xl-block d-lg-none d-md-none d-sm-none d-none"
    />

    <br />
    <br />

    <footer>
      <div class="container">
        <div class="collapse m-b-15" id="collapseExample">
          <div class="card card-body">
            <div class="row">
              <div class="col-md-6">
                <p>
                  Kami memberikan Anda kemudahan untuk membeli segala produk
                  yang berkaitan dengan Buku, ATK, Gaya Hidup, Mainan &amp;
                  Hobi, Olahraga &amp; Outdoor dan Handmade, hanya dengan
                  mengakses ke situs Eurekabookhouse.co.id.
                </p>

                <p>
                  Dengan fasilitas yang kami sediakan, Anda akan memiliki
                  kemudahan mendapatkan barang- barang terbaru yang Anda
                  inginkan. Dapatkan pula berbagai diskon dengan potongan harga
                  termurah dan penawaran-penawaran yang pastinya menarik untuk
                  Anda. Informasi mengenai segala produk yang Anda inginkan juga
                  bisa Anda dapatkan dengan mudah di website kami untuk
                  kenyamanan Anda berbelanja.
                </p>
                <p>
                  Produk pesanan akan kami antar ke rumah Anda dengan pelayanan
                  ekspedisi yang cepat dengan kualitas produk yang terbaru dan
                  terbaik. Dengan pembayaran melalui kartu kredit, transfer
                  antar bank, GoPay dan AkuLaku. kami menawarkan metode
                  pembayaran yang pastinya mudah dan aman demi kenyamanan Anda
                  berbelanja.
                </p>
                <p>
                  Ada Pertanyaan? Temui kami di Jl. H. Baping No.100, Ciracas,
                  Jakarta Timur, DKI Jakarta 087888337555
                </p>
                <p>
                  Kami menyediakan produk original dengan kategori: Buku, ATK,
                  Gaya Hidup, Mainan &amp; Hobi, Olahraga &amp; Outdoor dan
                  Handmade. Eurekabookhouse.co.id menyediakan segala produk yang
                  anda butuhkan di tiap kategorinya. Untuk produk kategori Buku
                  dan ATK, Eurekabookhouse.co.id menyediakan Novel, Buku
                  Pelajaran, Buku Perguruan Tinggi, Buku Umum, Buku anak, dan
                  berbagai macam ATK untuk memenuhi koleksi dan kebutuhan anda.
                </p>
                <p>
                  Lengkapi kebutuhan Gaya hidup Anda sehari-hari, kami
                  menawarkan pilihan Sepatu,Tumbler, Aksesoris gadget dan
                  Voucher terbaik dari Eurekabookhouse.co.id. Jadikan Gaya Hidup
                  Anda sehari-hari senantiasa semakin asik dengan produk- produk
                  terbaru dari Eurekabookhouse.co.id.
                </p>
                <p>
                  Tak hanya memenuhi kebutuhan Gaya Hidup, Eurekabookhouse.co.id
                  juga menjawab kebutuhan aktivitas luar ruangan Anda. Ingin
                  berolahraga atau aktivitas outdoor yang menantang? Temukan
                  produk- produk kesayangan Anda seperti alat-alat olahraga,
                  alat-alat outdoor dan ransel yang dapat menambah serunya
                  pengalaman liburan Anda.
                </p>
                <p>
                  Penuhi kebutuhan buah hati Anda atau koleksi anda dengan
                  berbagai macam produk yang kami tawarkan.
                  Eurekabookhouse.co.id menawarkan segala produk untuk kebutuhan
                  Mainan dan Hobi mulai dari alat menggambar, puzzle, diecast,
                  figure dan modelkit. Jadikan selalu Eurekabookhouse.co.id
                  mitra utama kehidupan Anda.
                </p>
                <p>
                  Kami menawarkan discount hingga 20% dan dengan harga yang
                  menarik. Hanya di Eurekabookhouse.co.id kami menyediakan
                  berbagai produk dengan harga spesial. Kami menawarkan harga
                  yang kompetitif dibandingkan retailer lainnya.
                </p>
                Eurekabookhouse.co.id sebagai pusat belanja online terlengkap
                dan terpercaya di Indonesia dengan kemudahan gratis ongkos
                kirim. Pengiriman barang profesional dan eksklusif kami akan
                mengantar barang pesanan Anda dengan cepat dan aman.
              </div>
              <div class="col-md-6">
                <h5 class="stext-101 p-b-10">Kategori Populer</h5>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-6 col-sm-6 col-lg-3 p-b-30">
            <h4 class="title-fo stext-101 cl1 p-b-15">Informasi</h4>

            <ul class="menu">
              <li>
                <router-link
                  :to="'/footer/tentangEureka'"
                  class="stext-107 cl6 hov-cl1 trans-04"
                >
                  Tentang
                </router-link>
              </li>
              <li>
                <router-link
                  :to="'/footer/syaratKetentuan'"
                  class="stext-107 cl6 hov-cl1 trans-04"
                >
                  Syarat & Ketentuan
                </router-link>
              </li>
              <li>
                <a
                  href="https://midtrans.com/payments/payment-channels"
                  class="stext-107 cl6 hov-cl1 trans-04"
                  target="_blank"
                >
                  Pembayaran
                </a>
              </li>
              <li>
                <router-link
                  :to="'/footer/shippingPage'"
                  class="stext-107 cl6 hov-cl1 trans-04"
                >
                  Pengiriman
                </router-link>
              </li>
              <li>
                <router-link
                  :to="'/footer/kebijakanPrivasi'"
                  class="stext-107 cl6 hov-cl1 trans-04"
                >
                  Kebijakan Privasi
                </router-link>
              </li>
              <li>
                <router-link
                  :to="'/footer/karir'"
                  class="stext-107 cl6 hov-cl1 trans-04"
                >
                  Karir
                </router-link>
              </li>
              <li>
                <router-link
                  :to="'/footer/seoTag'"
                  class="hov-cl1 trans-04 stext-107 cl6"
                >
                  SEO Tag
                </router-link>
              </li>
            </ul>
          </div>

          <div class="col-6 col-sm-6 col-lg-3 p-b-30">
            <h4 class="title-fo stext-101 cl1 p-b-15" style="color: white">
              Layanan
            </h4>

            <ul class="menu">
              <li>
                <router-link
                  :to="'/footer/hubungiKami'"
                  class="stext-107 cl6 hov-cl1 trans-04"
                >
                  Hubungi Kami
                </router-link>
              </li>
              <li>
                <router-link
                  :to="'/blog'"
                  class="stext-107 cl6 hov-cl1 trans-04"
                >
                  Blog
                </router-link>
              </li>
              <li>
                <router-link
                  :to="'/BantuanEBH/bantuan'"
                  class="stext-107 cl6 hov-cl1 trans-04"
                >
                  Bantuan
                </router-link>
              </li>

              <li>
                <router-link
                  :to="'/footer/returnPolicy'"
                  class="stext-107 cl6 hov-cl1 trans-04"
                >
                  Return Policy
                </router-link>
              </li>

              <!-- <li>
                <a
                  href="https://www.eurekabookhouse.co.id/extra/layanan/petasitus"
                  class="stext-107 cl6 hov-cl1 trans-04"
                >
                  Peta Situs
                </a>
              </li> -->
              <li>
                <router-link
                  :to="'/footer/syaratLayanan'"
                  class="stext-107 cl6 hov-cl1 trans-04"
                >
                  Syarat Layanan
                </router-link>
              </li>
              <li>
                <router-link
                  :to="'/footer/daftarOfficial'"
                  class="stext-107 cl6 hov-cl1 trans-04"
                >
                  Daftar Official Store
                </router-link>
              </li>
            </ul>
          </div>

          <div class="col-sm-6 col-lg-3 p-b-30">
            <h4 class="title-fo stext-101 cl1 p-b-15" style="color: white">
              Kontak
            </h4>
            <p class="stext-107 cl6 size-201" id="contact-info">
              Ada Pertanyaan?<br />
              Jl. H. Baping No.100, Ciracas, Jakarta Timur, DKI Jakarta 13740<br />
              <i class="fa fa-whatsapp"></i> 087888337555<br />
              <i class="fa fa-phone"></i> 021 - 8779 6010 (ext. 312)
            </p>
          </div>
          <div class="col-sm-6 col-lg-3 p-b-30">
            <div class="flex flex-col sm:flex-row items-center gap-4">
              <h4 class="text-white text-lg font-semibold title-fo">
                Download Apps
              </h4>
              <a
                target="_blank"
                href="https://play.google.com/store/apps/details?id=com.eureka.online"
              >
                <img
                  src="https://stagingapi.eurekabookhouse.com/assets/front/images/icons/google-play-badge-min.png"
                  class="max-w-[200px]"
                  style="margin-left: -40px"
                />
              </a>
            </div>
            <!-- <h4 class="title-fo stext-101 cl1 p-b-15" style="color: white">
              Download Apps
            </h4>
            <a
              target="_blank"
              href="https://play.google.com/store/apps/details?id=com.eureka.online"
            >
              <img
                src="https://stagingapi.eurekabookhouse.com/assets/front/images/icons/google-play-badge-min.png"
                style="max-width: 200px"
              />
            </a> -->
            <div class="p-t-20" style="margin-left: -30px">
              <a
                href="https://fb.com/ebhcom"
                class="fs-18 cl6 hov-cl1 trans-04 m-r-16"
              >
                <i style="width: 40px" class="fab fa-facebook"></i>
              </a>
              <a
                href="https://plus.google.com/eurekabookhouse"
                class="fs-18 cl6 hov-cl1 trans-04 m-r-16"
              >
                <i style="width: 40px" class="fab fa-google-plus"></i>
              </a>
              <a
                href="https://twitter.com/eurekabookhouse"
                class="fs-18 cl6 hov-cl1 trans-04 m-r-16"
              >
                <i style="width: 40px" class="fab fa-twitter"></i>
              </a>
              <a
                href="https://youtube.com/eurekabookhouse"
                class="fs-18 cl6 hov-cl1 trans-04 m-r-16"
              >
                <i style="width: 40px" class="fab fa-youtube"></i>
              </a>
            </div>
          </div>
        </div>
        <div class="p-t-10">
          <div class="row">
            <div class="col-md-4">
              <h4 class="title-cc stext-101 mb-2" style>Credit Card</h4>
              <div class="flex-w mb-2">
                <img
                  src="../assets/images/footer/a.png"
                  class="card-kartu mr-1"
                />

                <img
                  src="../assets/images/footer/b.png"
                  class="card-kartu mr-1"
                />

                <img
                  src="../assets/images/footer/c.png"
                  class="card-kartu mr-1"
                />
              </div>
              <h4 class="title-cc stext-101 mb-2">Pembayaran</h4>
              <div class="flex-w">
                <div>
                  <img
                    src="../assets/images/footer/j.png"
                    class="card-kartu mr-1"
                  />
                  <img
                    src="../assets/images/footer/l.png"
                    class="card-kartu mr-1"
                  />

                  <img
                    src="../assets/images/footer/k.png"
                    class="card-kartu mr-1"
                  />
                  <img
                    src="../assets/images/footer/i.png"
                    class="card-kartu mr-1"
                  />
                </div>
                <div class="mt-1">
                  <img
                    src="../assets/images/footer/m.png"
                    class="card-kartu mr-1"
                  />
                  <img
                    src="../assets/images/footer/n.png"
                    class="card-kartu mr-1"
                  />
                  <img
                    src="../assets/images/footer/o.png"
                    class="card-kartu mr-1"
                  />
                  <img
                    src="../assets/images/footer/p.png"
                    class="card-kartu mr-1"
                  />
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <h4 class="title-cc stext-101 mb-2">Keamanan Bayar</h4>
              <div class="flex-w">
                <img
                  src="../assets/images/footer/d.png"
                  class="card-kartu mr-1"
                />
                <img
                  src="../assets/images/footer/e.png"
                  class="card-kartu mr-1"
                />
                <img
                  src="../assets/images/footer/f.png"
                  class="card-kartu mr-1"
                />
                <img
                  src="../assets/images/footer/g.png"
                  class="card-kartu mr-1"
                />
              </div>
            </div>
            <div class="col-md-4">
              <h4 class="title-cc stext-101 mb-2">Pengiriman</h4>
              <div class="flex-w">
                <a href="https://rajacepat.com/service-kiriman">
                  <img
                    src="../assets/images/footer/h.png"
                    class="card-kartu mr-1"
                  />
                </a>

                <!-- <div class="m-r-5 bg0 bor4 p-all-8" style="height: 39px">
								<img src="https://cdn.eurekabookhouse.co.id/ebh/icon/pembayaran/rpx-min.png">
							</div> -->
              </div>
            </div>
          </div>
          <br />
          <div class="border-top py-3 text-center">
            <span style="color: white"
              >© 2022 Dikembangkan oleh Eureka IT Developer</span
            >
          </div>
        </div>
      </div>
    </footer>
    <div class="modal fade vertically-modal" id="modal_cart" tabindex="-1">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div id="modal-tampil" class="modal-body"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script scope>
export default {
  name: "footerPage",
};
</script>
<style type="text/css" scoped>
#image1 {
  width: 100px; /* Sesuaikan ukuran sesuai kebutuhan Anda */
  height: 100px; /* Sesuaikan ukuran sesuai kebutuhan Anda */
  z-index: 1; /* Atur sesuai kebutuhan Anda */
}

/* CSS untuk image2.png di pojok kanan bawah footer */
#image2 {
  width: 100px; /* Sesuaikan ukuran sesuai kebutuhan Anda */
  height: 100px; /* Sesuaikan ukuran sesuai kebutuhan Anda */
  z-index: 1; /* Atur sesuai kebutuhan Anda */
}
#contact-info {
  text-align: left; /* Menempatkan teks di pojok kanan */
  color: white;
}
.title-cc {
  text-align: left;
  color: white;
}
.title-fo {
  color: #fff;
  text-align: left;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 30.8px; /* 110% */
  letter-spacing: -0.28px;
}
.regis-left {
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 150px; /* Sesuaikan dengan ukuran gambar Anda */
}

.regis-right {
  position: absolute;
  top: 0px;
  right: 0px;
  width: 150px;
}
.menu {
  list-style: none;
  padding: 0;
}

.menu li {
  text-align: left; /* Menempatkan teks di pojok kanan */
}

.menu a {
  text-decoration: none;
  color: white; /* Mengubah warna teks menjadi putih */
  display: block;
}
.card-kartu {
  width: 80px;
  height: 40px;
  flex-shrink: 0;
}
</style>

<template>
  <div class="bg-gray-100 min-h-screen">
    <!-- Navbar Component -->
    <Navbar />

    <!-- Blog Section -->
    <section class="max-w-6xl mx-auto py-10 bg-blog-background">
      <div class="text-center mb-6">
        <p class="text-p mt-2">
          Kumpulan blog informasi menarik yang telah disajikan oleh tim
        </p>
        <h1 class="text-3xl font-bold mb-2 mt-3">Blog by Eureka Bookhouse</h1>
      </div>
      <div class="mb-6 mt-4 flex justify-center">
        <div class="mb-6 mt-4 flex justify-center">
          <select
            class="px-2 py-2 border border-white rounded-lg bg-transparent text-white focus:outline-none focus:ring-2 focus:ring-white"
            @change="handleCategoryChange($event)"
          >
            <option value="">Kategori</option>
            <option value="/Blog/acaratoko">Acara Toko</option>
            <option value="/Blog/rekomondasi">Rekomendasi Buku</option>
            <option value="/Blog/promosi">Promosi</option>
            <option value="/Blog/review">Review Buku</option>
            <option value="/Blog/triva">Trivia</option>
            <option value="/Blog/fiksi">Fiksi</option>
            <option value="/Blog/nonfiksi">Non-Fiksi</option>
            <option value="/Blog/date">Update</option>
            <option value="/Blog/allblog">Sahabat Eureka</option>
          </select>
        </div>
      </div>
    </section>

    <section>
      <!-- Pagination -->
      <div v-if="posts.length > perPage" class="pagination">
        <button
          v-for="page in totalPages"
          :key="page"
          @click="currentPage = page"
          :class="{ active: currentPage === page }"
        >
          {{ page }}
        </button>
      </div>
    </section>

    <section>
      <!-- Blog Grid -->
      <div class="wrapper">
        <div
          class="blog-grid"
          :style="{ minHeight: posts.length < 3 ? '800px' : 'auto' }"
        >
          <div
            v-for="(post, index) in paginatedPosts"
            :key="index"
            class="card"
          >
            <!-- Gambar -->
            <div class="image-container">
              <img :src="post.image" alt="" />
            </div>

            <!-- Judul -->
            <h2 class="title">{{ post.title }}</h2>

            <!-- Deskripsi -->
            <p class="description">{{ post.description }}</p>

            <!-- Meta & Button -->
            <div class="card-footer">
              <div>
                <div class="meta">{{ post.date }}</div>
                <p>By Eureka | {{ post.author }}</p>
              </div>
              <router-link :to="post.link" class="btn">
                Selengkapnya
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script scope>
import Navbar from "../Nav.vue";

export default {
  name: "blogPage",
  components: {
    Navbar,
  },
  methods: {
    handleCategoryChange(event) {
      const selectedValue = event.target.value;
      if (selectedValue) {
        this.$router.push(selectedValue);
      }
    },
  },
  data() {
    return {
      posts: [
        {
          title: "KISAH KASIH - JUARA FAVORIT 10 LOMBA CERPEN EUREKABOOKHOUSE",
          description:
            "&nbsp; Seorang gadis berambut hitam legam duduk termenung didepan foto mendiang ibunya. Ia terlihat menahan air matanyayang sudah siap jatuh itu. Gadis itu bernama Siti Maysaroh, siswi",
          date: " 30 August 2022",
          author: "Sahabat Eureka",
          image: "https://cdn.eurekabookhouse.co.id/ebh/blog/13-min.jpg",
          link: "/Blog/kisahkasih",
        },
        {
          title:
            "RAHASIA DELIA - JUARA FAVORIT 9 LOMBA CERPEN EUREKA BOOKHOUSE",
          description:
            " Aku sedang mengetik tugas kantor ketika Delia datang. &ldquo;Pa, minggu depan uang buku mau dibayar.&rdquo;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Aku te",
          date: "30 August 2022",
          author: "Sahabat Eureka",
          image: "https://cdn.eurekabookhouse.co.id/ebh/blog/12-min.jpg",
          link: "/Blog/rahasiadelia",
        },
        {
          title:
            "POHON MANGGA ITU - JUARA FAVORIT 8 LOMBA CERPEN EUREKA BOOKHOUSE",
          description:
            " Tiga bulan berturut-turut bapak keluar masuk rumah sakit. Mulai dari pertengahan&nbsp;bulan puasa pada bulan Mei, hingga bulan Juli lalu. Jauh sebelum itu, aku yang kala itu&nbsp;duduk dibangku SMA",
          date: "30 August 2022",
          author: "Sahabat Eureka",
          image: "https://cdn.eurekabookhouse.co.id/ebh/blog/11-min.jpg",
          link: "/Blog/pohonmangga",
        },
        {
          title:
            "MALAIKAT ENJAGAKU - JUARA FAVORIT 7 LOMBA CERPEN EUREKA BOOKHOUSE",
          description:
            "Malam ini hujan deras melanda kota kelahiranku. Petir menyambar seakan Tuhan sedang murka dengan penduduk bumi. Hempasan angin dingin yang tiada henti perlahan menerpa kulit wajahku yang saat ini",
          date: "30 August 2022",
          author: "Sahabat Eureka",
          image: "https://cdn.eurekabookhouse.co.id/ebh/blog/10-min.jpg",
          link: "/Blog/malaikat",
        },
        {
          title:
            "KENANGAN TENTANG AYAH - JUARA FAVORIT 6 LOMBA CERPEN EUREKA BOOKHOUSE",
          description:
            " Aku berjalan di lorong rumah sakit. Beberapa perawat dan dokter menyapaku. Hingga aku tiba di sebuah ruangan bertuliskan &ldquo;Ruangan Dr. Anatasya&rdquo;. Itu ruanganku. Aku beke",
          date: "30 August 2022",
          author: "Sahabat Eureka",
          image: "https://cdn.eurekabookhouse.co.id/ebh/blog/9-min.jpg",
          link: "/Blog/ttgayah",
        },
        {
          title:
            "SEGENGGAM TANAH MERAH - JUARA FAVORIT 5 LOMBA CERPEN EUREKA BOOKHOUSE",
          description:
            "Aku mengusap batu yang salah satu ujungnya menghadap ke arah timur. Permukaan benda berukuran sekepal tangan orang dewasa itu terasa hangat oleh pancaran mentari. Sekali lagi air mataku mengalir de",
          date: "30 August 2022",
          author: "Sahabat Eureka",
          image: "https://cdn.eurekabookhouse.co.id/ebh/blog/8-min.jpg",
          link: "/Blog/merah",
        },
        {
          title:
            "JEJAK SANDAL AYAH - JUARA FAVORIT 4 LOMBA CERPEN EUREKABOOKHOUSE",
          description:
            " Saya&nbsp; bersekolah di salah satu sekolah berasrama yang bergengsi di Kota Malang. Saya&nbsp; menjadi satu dari lima puluh siswa yang beruntung karena dapat sekolah di sana meskipun bukan dari go",
          date: "30 August 2022",
          author: "Sahabat Eureka",
          image: "https://cdn.eurekabookhouse.co.id/ebh/blog/7-min.jpg",
          link: "/Blog/jejak",
        },
        {
          title:
            "PERJUANGAN AYAHKU, UNTUK MASA DEPANKU - JUARA FAVORIT 3 LOMBA CERPEN EUREKA BOOKHOUSE",
          description:
            " Kisah ini berasal dari keluarga sederhana yang hidup disebuah desa yang terdiri dari ayah, ibu dan seorang anak. Ayah yang bernama Budiman biasa dipanggil pak Budi, ibu yang bernama Sriy",
          date: "30 August 2022",
          author: "Sahabat Eureka",
          image: "https://cdn.eurekabookhouse.co.id/ebh/blog/6-min.jpg",
          link: "/Blog/pejuang",
        },
      ],
      currentPage: 1,
      perPage: 6,
    };
  },

  computed: {
    paginatedPosts() {
      const start = (this.currentPage - 1) * this.perPage;
      const end = start + this.perPage;
      return this.posts.slice(start, end);
    },
    totalPages() {
      return Math.ceil(this.posts.length / this.perPage);
    },
  },
};
</script>

<style scoped>
.bg-blog-background {
  background-image: url("../../assets/images/bg_blog.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding: 40px 20px;
  color: white;
  font-weight: bold;
}
.text-p {
  color: #acacac;
}
.wrapper {
  display: flex;
  justify-content: center;
  padding: 40px 20px;
  background: #f3f4f6;
}

.blog-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
  min-height: 800px; /* sesuaikan sesuai kebutuhan */
}

@media (min-width: 768px) {
  .blog-grid {
    min-height: 700px;
  }
}

.card {
  background: #ffffff;
  border: 1px solid #dcdcdc;
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.image-container img {
  width: 100%;
  height: 160px;
  object-fit: fill;
  background: #fafafa;
}

.title {
  font-size: 0.95rem; /* sebelumnya 1.1rem */
  font-weight: bold;
  text-transform: uppercase;
  text-align: start;
  margin: 10px 0 6px;
  padding: 10px;
}

.description {
  font-size: 0.7rem;
  color: #333;
  text-align: start;
  margin-bottom: 16px;
  padding: 0 10px;
}

.card-footer {
  margin-top: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: start;
  font-size: 0.75rem;
  color: #777;
  padding: 10px 8px 12px;
  background: #fafafa;
}

.btn {
  background: #000;
  color: #fff;
  padding: 6px 12px;
  border-radius: 10px;
  font-size: 0.75rem;
  text-decoration: none;
  transition: background 0.3s;
}

.btn:hover {
  background: #333;
}

.pagination {
  display: flex;
  justify-content: end;
  margin-top: 25px;
  gap: 12px;
  width: 82rem;
}

.pagination button {
  background: transparent;
  border: none;
  padding: 6px 12px;
  font-size: 14px;
  color: #333;
  cursor: pointer;
  border-radius: 4px;
  transition: all 0.3s ease;
}
.pagination button.active {
  background: #1e1e1e;
  color: #fff;
}
.pagination button:hover {
  background: #1e1e1e;
  color: #fff;
}

select {
  transition: all 0.3s ease;
}
select:hover {
  background-color: rgba(255, 255, 255, 0.1);
}
select option {
  background-color: #1f2937; /* sama seperti bg-gray-800 */
  color: white;
}
</style>

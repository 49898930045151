<template>
  <div class="dropdown">
    <div
      class="dropdown-item"
      @mouseenter="showSubmenu1 = true"
      @mouseleave="showSubmenu1 = false"
      style="color: white"
    >
      <b-icon icon="list" aria-hidden="true" class="right-icon"></b-icon>
      Kategori
      <b-icon
        icon="chevron-down"
        aria-hidden="true"
        class="right-icon"
      ></b-icon>
      <div class="submenu" v-if="showSubmenu1">
        <div
          class="submenu-item"
          v-for="item in submenuItems1"
          :key="item.category_id"
          @mouseenter="updateSubmenu2(item)"
          @mouseleave="delayedCloseSubmenu2"
        >
          <router-link :to="'/kategori/' + item.slug">
            <div class="text d-flex">
              <div class="bd-highlight mr-2">
                <img
                  :src="
                    item.image
                      ? `https://stagingapi.eurekabookhouse.com/assets/front/images/icons/nerd/${item.image}`
                      : 'https://stagingapi.eurekabookhouse.com/assets/front/images/icons/nerd/010-book.svg'
                  "
                  style="width: 20px"
                  alt="Icon"
                />
              </div>
              <div class="bd-highlight">
                <span>{{ item.name }}</span>
              </div>
              <div class="ml-auto bd-highlight">
                <b-icon
                  v-if="item.subcategories && item.subcategories.length > 0"
                  icon="chevron-right"
                  aria-hidden="true"
                ></b-icon>
              </div>
            </div>
          </router-link>

          <div
            class="submenu submenu-right"
            v-if="showSubmenu2 && currentHoveredItem === item"
          >
            <div
              class="submenu-item"
              v-for="subItem in submenuItems2"
              :key="subItem.category_id"
              @mouseenter="updateSubmenu3(subItem)"
              @mouseleave="delayedCloseSubmenu3"
            >
              <router-link :to="'/kategori/' + subItem.slug">
                <div class="submenu-content text">
                  <span>{{ subItem.name }}</span>
                  <b-icon
                    v-if="
                      subItem.subcategories && subItem.subcategories.length > 0
                    "
                    icon="chevron-right"
                    aria-hidden="true"
                  ></b-icon>
                </div>
              </router-link>

              <div
                class="submenu submenu-right"
                v-if="showSubmenu3 && currentHoveredSubItem === subItem"
              >
                <div
                  v-for="subSubItem in submenuItems3"
                  :key="subSubItem.category_id"
                  @mouseenter="currentHoveredSubSubItem = subSubItem"
                  @mouseleave="currentHoveredSubSubItem = null"
                >
                  <router-link :to="'/kategori/' + subSubItem.slug">
                    <div class="submenu-item text">
                      <span>{{ subSubItem.name }}</span>
                    </div>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from "vue";
import axios from "axios";

const showSubmenu1 = ref(false);
const showSubmenu2 = ref(false);
const showSubmenu3 = ref(false);

const submenuItems1 = ref([]);
const submenuItems2 = ref([]);
const submenuItems3 = ref([]);

const currentHoveredItem = ref(null);
const currentHoveredSubItem = ref(null);
const currentHoveredSubSubItem = ref(null);

let closeTimeout1, closeTimeout2, closeTimeout3;

// Fungsi untuk menutup submenu dengan delay agar tidak langsung hilang
const delayedCloseSubmenu1 = () => {
  closeTimeout1 = setTimeout(() => {
    showSubmenu1.value = false;
    showSubmenu2.value = false;
    showSubmenu3.value = false;
  }, 100);
};

const delayedCloseSubmenu2 = () => {
  closeTimeout2 = setTimeout(() => {
    showSubmenu2.value = false;
    showSubmenu3.value = false;
  }, 100);
};

const delayedCloseSubmenu3 = () => {
  closeTimeout3 = setTimeout(() => {
    showSubmenu3.value = false;
  }, 100);
};

// Fungsi untuk memperbarui submenu kedua
const updateSubmenu2 = (item) => {
  clearTimeout(closeTimeout1);
  clearTimeout(closeTimeout2);
  showSubmenu2.value = true;
  currentHoveredItem.value = item;
  currentHoveredSubItem.value = null;
  currentHoveredSubSubItem.value = null;

  submenuItems2.value = item.subcategories || [];
};

// Fungsi untuk memperbarui submenu ketiga
const updateSubmenu3 = (subItem) => {
  clearTimeout(closeTimeout3);
  showSubmenu3.value = true;
  currentHoveredSubItem.value = subItem;
  currentHoveredSubSubItem.value = null;

  submenuItems3.value = subItem.subcategories || [];
};

onMounted(async () => {
  try {
    const response = await axios.get(
      "https://stagingapi.eurekabookhouse.com/category/sub"
    );
    submenuItems1.value = response.data.data.order;
  } catch (error) {
    console.error("Error fetching data:", error);
  }
});
</script>

<style scoped>
.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-item {
  padding: 10px;
  cursor: pointer;
  background-color: transparent;
  color: white;
}

.submenu {
  position: absolute;
  top: 100%; /* Agar submenu muncul di bawah kategori */
  left: 0; /* Pastikan posisi tetap di bawah menu utama */
  background-color: white;
  z-index: 1000;
  min-width: 200px; /* Atur lebar dropdown */
}

/* Submenu tingkat kedua */
.submenu-right {
  position: absolute;
  top: 0; /* Sesuaikan agar muncul di samping submenu pertama */
  left: 100%; /* Muncul ke kanan submenu sebelumnya */
  min-width: 200px;
}

/* Tambahkan untuk memastikan tidak ada celah antar menu */
.dropdown-item,
.submenu-item {
  position: relative;
}

.dropdown-item {
  padding: 5%;
  cursor: pointer;
  background-color: transparent;
}

.submenu-item {
  padding: 10px;
  cursor: pointer;
}

.submenu-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.submenu-item:hover {
  background-color: #f0f0f0;
}

.text {
  display: flex;
  align-items: center;
  gap: 10px;
  color: black;
}

.text:hover {
  color: #2c497e;
}

.icon img {
  width: 20px;
  height: 20px;
}

.right-icon {
  margin-left: 5px;
}
</style>

<template>
  <div>
    <section v-if="produkMaster.length > 0" class="mt-3 sec-relate-product bg6 mb-5">
      <div
        class="container bgu5 p-tb-15 p-lr-15 p-lr-5-sm bs1"
        style="padding: 15px"
      >
        <div>
          <!-- Enhanced Flash Sale Info -->
          <div v-if="dataFlashsale" class="flashsale-info mb-4">
            <div class="flashsale-header">
              <i class="fa fa-bolt fa-2x text-warning mr-2"></i>
              <h4 class="flashsale-title">Flash Sale</h4>
            </div>
            <div class="flashsale-timer text-sm">
              <p style="font-size: 12px;">
                Flash Sale berlangsung hingga:
                <strong style="font-size: 12px;">{{ formatDate(dataFlashsale.tgl_akhir) }}</strong>
              </p>
              <div v-if="dataFlashsale.sisa_waktu" class="countdown text-danger font-weight-bold">
                <span v-if="dataFlashsale.sisa_waktu.hari > 0">{{ dataFlashsale.sisa_waktu.hari }} Hari </span>
                <span>{{ dataFlashsale.sisa_waktu.jam }} Jam </span>
                <span>{{ dataFlashsale.sisa_waktu.menit }} Menit </span>
                <span>{{ dataFlashsale.sisa_waktu.detik }} Detik</span>
              </div>
            </div>
          </div>

          <div class="mt-3 position-relative">
            <!-- Navigation Buttons -->
            <div
              class="d-none d-lg-block flickity-prev-next-button flickity-prev-button"
              style="
                position: absolute;
                top: 50%;
                left: 5px;
                transform: translateY(-50%);
                z-index: 1;
              "
            >
              <button @click="prevSlide" class="nav-button">
                <i class="fa fa-angle-left" aria-hidden="true"></i>
              </button>
            </div>

            <flickity
              v-if="produkMaster.length > 0"
              ref="flickity"
              class="flickity"
              :options="flickityOptions"
            >
              <div
                class="carousel-cell wd-15 block2 bg0"
                v-for="(item, index) in produkMaster"
                :key="index"
              >
                <div
                  class="block2-pic hov-img0 text-center"
                  :class="{ 'label-new': item.discount > 0 }"
                  :data-label="item.discount + '%'"
                >
                  <img
                    class="img-official"
                    src="https://stagingapi.eurekabookhouse.com/assets/image/mall_badge.png"
                  />
                  <div>
                    <img
                      class="img-responsive"
                      :src="
                        'https://cdn.eurekabookhouse.co.id/ebh/product/all/' +
                        item.productImg
                      "
                      :style="{
                        'max-width': '100%',
                        height: 'auto',
                        cursor: 'pointer',
                        opacity: item.quantity === 0 ? 0.2 : 1,
                      }"
                      alt=""
                      @error="handleImageError"
                      @click="item.quantity > 0 && handleProductClick(item)"
                    />

                    <div v-if="item.quantity === 0" class="sold-out-label">
                      Habis
                    </div>
                  </div>
                </div>
                <div class="block2-txt flex-w flex-t p-all-8">
                  <div class="block2-txt-child1 flex-col-l">
                    <div
                      @click="handleProductClick(item)"
                      class="stext-105 cl2 hov-cl1 trans-04 js-name-b2 p-b-6"
                    >
                      {{ item.productName }}
                    </div>

                    <div class="stext-105">
                      <strong
                        v-if="item.discount > 0"
                        class="cl13 original-price"
                      >
                        Rp {{ formatPrice(item.price) }}
                      </strong>
                    </div>
                    <div class="stext-105">
                      <strong class="cl13 sale-price">
                        Rp
                        {{
                          formatPrice(
                            item.discount > 0
                              ? item.price - item.price * (item.discount / 100)
                              : item.price
                          )
                        }}
                      </strong>
                    </div>
                  </div>
                </div>
              </div>
            </flickity>

            <div
              class="d-none d-lg-block flickity-prev-next-button flickity-next-button"
              style="
                position: absolute;
                top: 50%;
                right: 10px;
                transform: translateY(-50%);
              "
            >
              <button @click="nextSlide" class="nav-button">
                <i class="fa fa-angle-right" aria-hidden="true"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import Flickity from "vue-flickity";
import axios from "axios";
import Swal from "sweetalert2";
import moment from "moment";
import numeral from "numeral";

export default {
  name: "CardFlashSalePage",
  components: {
    Flickity,
  },
  data() {
    return {
      flickityOptions: {
        initialIndex: 0,
        prevNextButtons: false,
        pageDots: false,
        cellAlign: "left",
        wrapAround: false,
        freeScroll: false,
      },
      produkMaster: [], // Variable to store product data
      dataFlashsale: {}, // Variable to store flash sale info
      countdownInterval: null, // Variable to store the countdown interval
    };
  },
  mounted() {
    this.getProdukFlashsale();
  },
  methods: {
    prevSlide() {
      this.$refs.flickity.previous();
    },

    nextSlide() {
      this.$refs.flickity.next();
    },
    handleProductClick(item) {
      // Dispatch an action to set the product_id in the Vuex store
      this.$store.dispatch("setProductId", item.product_id);

      // Navigate to the detail page
      this.$router.push({
        name: "detailprodukPage",
        params: { slug: item.slug },
      });
    },
    handleImageError(event) {
      event.target.src =
        "https://sandbox.eurekabookhouse.co.id/img/ebh.812ea2ce.png";
    },
    async getProdukFlashsale() {
      const apiUrl =
        "https://stagingapi.eurekabookhouse.com/nimda/flashsale/get-product-flashsale";

      try {
        const response = await axios.get(apiUrl);

        if (response.data.status.code === 200) {
          // Ambil data flash sale dan produk
          const flashsaleData = response.data.data[0] || {};
          this.dataFlashsale = flashsaleData;
          this.produkMaster = flashsaleData.products || []; // Akses array products

          // Jika ada sisa waktu, mulai countdown
          if (this.dataFlashsale.sisa_waktu) {
            this.dataFlashsale.sisa_waktu = this.convertSisaWaktu(this.dataFlashsale.sisa_waktu);
            this.startCountdown();
          }
        } else {
          this.dataFlashsale = {};
          this.produkMaster = [];
        }
      } catch (error) {
        if (error.response && error.response.status === 404) {
          // API mengembalikan 404, cukup kosongkan data
          this.dataFlashsale = {};
          this.produkMaster = [];
        } else {
          console.error("Error fetching flash sale data:", error);
        }
      }
    },
    convertSisaWaktu(sisa_waktu) {
      const hari = Math.floor(sisa_waktu.jam / 24);
      const jam = sisa_waktu.jam % 24;
      return {
        hari,
        jam,
        menit: sisa_waktu.menit,
        detik: sisa_waktu.detik,
      };
    },
    startCountdown() {
      this.countdownInterval = setInterval(() => {
        if (this.dataFlashsale.sisa_waktu.detik > 0) {
          this.dataFlashsale.sisa_waktu.detik--;
        } else {
          this.dataFlashsale.sisa_waktu.detik = 59;
          if (this.dataFlashsale.sisa_waktu.menit > 0) {
            this.dataFlashsale.sisa_waktu.menit--;
          } else {
            this.dataFlashsale.sisa_waktu.menit = 59;
            if (this.dataFlashsale.sisa_waktu.jam > 0) {
              this.dataFlashsale.sisa_waktu.jam--;
            } else {
              this.dataFlashsale.sisa_waktu.jam = 23;
              if (this.dataFlashsale.sisa_waktu.hari > 0) {
                this.dataFlashsale.sisa_waktu.hari--;
              } else {
                clearInterval(this.countdownInterval);
              }
            }
          }
        }
      }, 1000);
    },
    formatPrice(value) {
      return numeral(value).format("0,0");
    },
    formatDate(dateString) {
      return moment(dateString).format("DD MMM YYYY HH:mm");
    },
  },
  beforeDestroy() {
    clearInterval(this.countdownInterval);
  },
};
</script>

<style scoped>
.flashsale-info {
  background-color: #f8f9fa;
  padding: 15px;
  border-radius: 8px;
  border: 1px solid #e0e0e0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.flashsale-header {
  display: flex;
  align-items: center;
}

.flashsale-title {
  margin: 0;
  font-size: 18px;
  font-weight: 600;
  color: #ff5722;
}

.flashsale-timer {
  text-align: right;
  font-size: 16px;
  color: #333;
}

.sold-out-label {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 8px 12px;
  font-size: 20px;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.6);
  border-radius: 5px;
}

.nav-button {
  background-color: rgba(255, 255, 255, 0.85);
  border: none;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  font-size: 24px;
  color: #333;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
}

.nav-button:hover {
  background-color: rgba(255, 255, 255, 1);
  transform: scale(1.1);
}

.original-price {
  color: #989898;
  font-size: 12px;
  text-decoration: line-through;
}

.sale-price {
  color: #244786;
  font-size: 16px;
  font-weight: 600;
}

.flashsale-info p {
  margin: 0;
}

@media (max-width: 768px) {
  .flashsale-info {
    flex-direction: column;
    align-items: flex-start;
  }
  .flashsale-timer {
    text-align: left;
    margin-top: 10px;
  }
}
</style>

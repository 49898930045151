import { render, staticRenderFns } from "./rekomondasi.vue?vue&type=template&id=bd75f02e&scoped=true"
import script from "./rekomondasi.vue?vue&type=script&scoped=true&lang=js"
export * from "./rekomondasi.vue?vue&type=script&scoped=true&lang=js"
import style0 from "./rekomondasi.vue?vue&type=style&index=0&id=bd75f02e&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bd75f02e",
  null
  
)

export default component.exports